import type { ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const lastUpdatedByAccessor = <T extends { last_updated_by?: string }>(
  columnHelper: ColumnHelper<T>,
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.accessor((row) => row.last_updated_by, {
    id: 'last_updated_by',
    header: 'Last updated by',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        type: 'dropdown',
        placeholder: 'All users',
        ...filterMeta,
      },
    },
  });
