import type { FC } from 'react';
import { useUserStore } from '@Auth/store';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { crossCheckBlockUserCtx } from '@ContractBuilder/rules/block/cross-check-block-user-ctx';
import { getDocumentContext } from '@ContractBuilder/utils/get-document-context';
import { getLockedBlockPermissionsMessage } from '@ContractBuilder/utils/get-locked-block-permissions-message';
import { useModalDispatch } from '@hooks/useModalProvider';
import { mdiTrashCanOutline } from '@mdi/js';
import { isSuperadmin } from '@root/helpers/permissions';
import Button from '@src/components/Button';
import { MODALS } from '@src/constants';
import { useLanguage } from '@src/language';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';

import { DrawerCollapsibleGroup } from '../components/DrawerCollapsibleGroup';

export interface DrawerPanelActionsProps {
  onCancel?: () => void;
}

export const DrawerPanelActions: FC<DrawerPanelActionsProps> = ({ onCancel }) => {
  const { showModal } = useModalDispatch();
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isClause = isClausesPath();

  const { currentBlock, formValues } = useBlockEditFormStore(({ currentBlock, formValues }) => ({
    currentBlock,
    formValues,
  }));
  const user = useUserStore((state) => state.user);
  const documentContext = getDocumentContext();
  const isSuperAdminUser = isSuperadmin(user);

  const { getContent } = useLanguage({
    prefix: `naming.mrc.rightSidebar.${formValues?.type !== 'clause' ? 'block' : 'clause'}`,
  });

  const onDelete = () =>
    showModal(MODALS.CONFIRM_DELETE, {
      id: formValues?.id,
      title: formValues?.name,
      type: formValues?.type === 'clause' ? 'clause' : 'block',
      callback: onCancel,
    });

  const isAdminView = isClause || isBlock || isTemplate;
  const { canBeDeletedWithCurrentPermissions } = crossCheckBlockUserCtx(currentBlock, documentContext, user);

  const canDeleteBlock =
    canBeDeletedWithCurrentPermissions && (isAdminView || formValues?.canDelete || isSuperAdminUser);

  return (
    <Show when={!isBlock && !isClause}>
      <DrawerCollapsibleGroup title={getContent('groups.actions')} id="actions" className="border-b">
        <>
          {canDeleteBlock ? (
            <Button kind="ghost" className="h-8 w-fit -translate-x-2.5 px-2 shadow-none" onClick={onDelete}>
              <IconMdi path={mdiTrashCanOutline} size={0.7} />
              {getContent('removeButton')}
            </Button>
          ) : (
            <Tooltip content={getLockedBlockPermissionsMessage(true, false)}>
              <span>
                <Button isDisabled kind="ghost" className="h-8 w-fit -translate-x-2.5 px-2 shadow-none">
                  <IconMdi path={mdiTrashCanOutline} size={0.7} />
                  {getContent('removeButton')}
                </Button>
              </span>
            </Tooltip>
          )}
        </>
      </DrawerCollapsibleGroup>
    </Show>
  );
};
