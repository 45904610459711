import dayjs from 'dayjs';

import { isBlockExpired } from './is-block-expired';

export const isBlockInactive = (inceptionDate?: string, expiryDate?: string) => {
  if (inceptionDate && dayjs().isBefore(inceptionDate, 'day')) {
    return true;
  }

  return isBlockExpired(expiryDate);
};
