import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { ApiPatchOperation } from '@root/@types/types';
import type { CanonicalUserRecord } from '@root/helpers/user-management/types';
import axios from 'axios';

type UpdateUserPicks = 'email' | 'firstName' | 'lastName' | 'role' | 'teams' | 'identityProvider';
type UpdateUserAction = Pick<CanonicalUserRecord, UpdateUserPicks> & { operation: ApiPatchOperation.UserUpdate };
type ResentInviteAction = { operation: ApiPatchOperation.UserResendInvite };
type ChangeUserStateAction = Pick<CanonicalUserRecord, 'enabled'> & {
  operation: ApiPatchOperation.UserChangeState;
};

type Data = ResentInviteAction | UpdateUserAction | ChangeUserStateAction;

export const updateUser = async (id: string, data: Data): Promise<CanonicalUserRecord> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `users-spa-al/${id}`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
