import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PDFPreviewDrawer } from '@ContractBuilder/modules/pdf-preview';
import { ERROR_BRANDING_NOT_FOUND } from '@features/branding-edit/constants';
import { useBrandingStore } from '@features/branding-edit/store/branding.store';

import { BrandingDetailsEditor } from './branding-editor/BrandingDetailsEditor';
import { BrandingDetailsLayout } from './BrandingDetailsLayout';

export const BrandingDetailsPage = () => {
  const { id } = useParams();
  const { error } = useBrandingStore((s) => s);

  if ([ERROR_BRANDING_NOT_FOUND, 'Not Found'].includes(error as string)) {
    console.error(`Unable to load branding: ${id}`, error);
    return <Navigate to="/404?code=branding" replace />;
  }

  return (
    <>
      <BrandingDetailsLayout>
        <BrandingDetailsEditor />
      </BrandingDetailsLayout>
      <PDFPreviewDrawer />
    </>
  );
};
