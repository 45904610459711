import type { FC } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { groupByParent } from '@helpers/groupBlocksByParent';
import { useBlocksLibrary } from '@hooks/useBlocksLibrary';
import { getBlocksTableColumns } from '@pages/User/helpers/columns/getBlocksTableColumns';
import { getClausesTableColumns } from '@pages/User/helpers/columns/getClausesTableColumns';
import { fuzzyFilter } from '@pages/User/helpers/filters/fuzzyFilter';
import { fetchAllTeams } from '@queries/fetchTeams';
import type { Team } from '@root/@types/types';
import { useDeepCompareMemo } from '@src/hooks';
import type { ColumnFiltersState, ExpandedState, SortingState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useFilteredBlocks } from '../hooks/use-filtered-blocks';
import { getPaginationRowModel } from '../utils/getPaginationRowModel';
import { BlockLibraryListSuccess, BlocksLibraryLoading } from '../views';

interface BlockLibraryCreatorListControllerProps {
  onInsertBlock: (blockLibraryId: string, block: LibraryBlockTypeForListPage | null) => Promise<void>;
  type: 'block' | 'clause';
}

const initialColumnFilters = [{ id: 'deleted_at', value: ['Active'] }];
const initialPagination = {
  pageIndex: 0,
  pageSize: 20,
};

export const BlockLibraryCreatorListController: FC<BlockLibraryCreatorListControllerProps> = ({
  onInsertBlock,
  type,
}) => {
  const isClause = type === 'clause';
  const [isGroupByParent, setIsGroupByParent] = useState(false);
  const { data: blocks = [], isFetching } = useBlocksLibrary({ type });
  const blocksGroupedByParents = useMemo(() => groupByParent(blocks), [blocks]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const [pagination, setPagination] = useState(initialPagination);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFilters);
  const [globalFilter, setGlobalFilter] = useState('');
  const { data: teams = [] } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
  });

  const columns = useMemo(
    () => (isClause ? getClausesTableColumns({ teams }) : getBlocksTableColumns({ teams })),
    [isClause, teams],
  );
  const data = useDeepCompareMemo(
    () => (isGroupByParent ? blocksGroupedByParents : blocks),
    [blocks, blocksGroupedByParents, isGroupByParent],
  );

  const infiniteLoaderRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableExpanding: isGroupByParent,
    enableMultiSort: false,
    enableSortingRemoval: false,
    filterFromLeafRows: true,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getColumnCanGlobalFilter: (column) => !['created_at', 'updated_at'].includes(column.id),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    globalFilterFn: 'fuzzy',
    initialState: {
      columnVisibility: {
        id: false,
      },
    },
    maxLeafRowFilterDepth: 1,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    paginateExpandedRows: false,
    sortDescFirst: true,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      expanded,
    },
  });

  const filteredBlocks = useFilteredBlocks(
    table.getRowModel().rows.map(({ original }) => original),
    globalFilter,
  );

  const resetPagination = () => {
    setPagination(initialPagination);
  };

  const onSearchApply = (searchInput: string) => {
    resetPagination();
    setGlobalFilter(searchInput);
  };

  const handleLoadMoreItems = () => {
    setPagination((current) => ({ ...current, pageIndex: current.pageIndex + 1 }));
  };

  useEffect(() => {
    resetPagination();
  }, [columnFilters]);

  useEffect(() => {
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.scrollTop = 0;
    }

    resetPagination();
  }, [isGroupByParent]);

  if (isFetching) {
    return <BlocksLibraryLoading />;
  }

  const loadedBlocksCount = filteredBlocks.length ?? 0;

  return (
    <BlockLibraryListSuccess
      blocks={filteredBlocks}
      count={loadedBlocksCount}
      filters={columnFilters}
      listRef={infiniteLoaderRef}
      onInsert={onInsertBlock}
      onSearch={onSearchApply}
      searchText={globalFilter}
      total={table.getRowCount()}
      onLoadMoreItems={handleLoadMoreItems}
      isClause={isClause}
      isFetching={isFetching}
      table={table}
      isGroupByParent={isGroupByParent}
      setIsGroupByParent={setIsGroupByParent}
    />
  );
};
