import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { authProvider } from '@helpers/auth';
import { mdiOpenInNew } from '@mdi/js';
import Nav from '@root/src/routes';

import { DrawerSection } from './DrawerSection';

interface DrawerBlockUsedWithinProps {
  data?: Array<{
    id: string;
    name?: string;
  }>;
  isBlock: boolean;
}

export const DrawerBlockUsedWithin: FC<DrawerBlockUsedWithinProps> = ({ data = [], isBlock }) => {
  if (!data.length) return null;

  const handleLinkClick = async (templateId: string) => {
    const searchParams = await authProvider.getAuthSearchParams();
    const win = window.open(`${Nav.TemplateContract.replace(':id', templateId)}${searchParams}`);
    return win?.focus();
  };

  return (
    <DrawerSection title={`${isBlock ? 'Block' : 'Clause'} usage`}>
      <ul className="flex max-h-[134px] cursor-default flex-col items-start gap-1 overflow-auto text-sm text-info-500">
        {data.map(({ id, name }) => (
          <div key={id} className="w-full py-1 pr-3">
            <button
              className="flex w-full cursor-pointer justify-between text-primary-600"
              onClick={() => handleLinkClick(id)}
            >
              <p className="truncate text-xs no-underline hover:underline">{name}</p>
              <IconMdi
                className="ml-1"
                path={mdiOpenInNew}
                size={0.6}
                data-testid="block-icon-indicator:linked-library-block"
              />
            </button>
          </div>
        ))}
      </ul>
    </DrawerSection>
  );
};
