// Type inferred from `mrc` should drive the shape of any other naming object
export const mrc = {
  attachment: 'Attachment',
  section: 'Part',
  block: {
    name: 'Block',
    title: 'Block Heading',
    inline: 'Subheading',
    layout: {
      clause: 'Clause',
      generic: 'Fullwidth',
      'mrc-heading': 'Heading',
    },
  },
  attachments: {
    title: 'Attachments',
  },
  leftSidebar: {
    title: 'Navigation',
    controls: {
      expand: 'Expand all',
      collapse: 'Collapse all',
    },
  },
  rightSidebar: {
    block: {
      groups: {
        details: 'Block details',
        settings: 'Block settings',
        permissions: 'Block permissions',
        actions: 'Block actions',
      },
      fields: {
        section: { label: 'Change block placement' },
        datapoints: { single: { label: 'Datapoints on block' }, variations: { label: 'Data points on variant' } },
        instructions: { label: 'Add block instructions', placeholder: 'Instructions or helper text for this block' },
        settings: {
          label: 'Block settings',
          variations: { label: 'Add variants of this block' },
          pageBreak: { label: 'Add page break after block' },
          superAdminEdit: { label: 'Only super admin can edit' },
        },
        submissionPermissions: {
          label: 'Block permissions for contracts',
          canEdit: { label: 'Can be edited' },
          canDelete: { label: 'Can be removed' },
        },
        templatePermissions: {
          label: 'Block permissions for templates',
          canDelete: { label: 'Can be removed' },
        },
        actions: { label: 'Additional actions' },
        layout: { label: 'Change block layout' },
        parent: { label: 'Block parent' },
        inceptionDate: { label: 'Block effective from' },
        expiryDate: { label: 'Block effective to' },
        teamFilter: { label: 'Team filter' },
        classOfBusiness: { label: 'Class of business filter' },
      },
      removeButton: 'Remove this block',
      configureVisibilityButton: 'Configure visibility',
    },
    clause: {
      groups: {
        details: 'Clause details',
        settings: 'Clause settings',
        permissions: 'Clause permissions',
        actions: 'Clause actions',
      },
      fields: {
        section: { label: 'Change clause placement' },
        datapoints: { single: { label: 'Datapoints on clause' }, variations: { label: 'Data points on variant' } },
        instructions: { label: 'Add clause instructions', placeholder: 'Instructions or helper text for this clause' },
        settings: {
          label: 'Clause settings',
          variations: { label: 'Add variants of this clause' },
          pageBreak: { label: 'Add page break after clause' },
          superAdminEdit: { label: 'Only super admin can edit' },
        },
        submissionPermissions: {
          label: 'Clause permissions for contracts',
          canEdit: { label: 'Can be edited' },
          canDelete: { label: 'Can be removed' },
        },
        templatePermissions: {
          label: 'Clause permissions for templates',
          canDelete: { label: 'Can be removed' },
        },
        actions: { label: 'Additional actions' },
        layout: { label: 'Change clause layout' },
        parent: { label: 'Clause parent' },
        type: {
          label: 'Clause type',
        },
        reference: {
          label: 'Clause reference',
          placeholder: 'Enter clause reference',
        },
        classification: {
          label: 'Clause classification',
        },
        inceptionDate: { label: 'Clause effective from' },
        expiryDate: { label: 'Clause effective to' },
        teamFilter: { label: 'Team filter' },
        classOfBusiness: { label: 'Class of business filter' },
      },
      removeButton: 'Remove this clause',
      configureVisibilityButton: 'Configure visibility',
    },
  },
  modals: {
    attachment: {
      title: { create: 'Append an attachment', rename: 'Rename attachment' },
      subtitle: 'Append an accompanying document to the end of the contract file',
      fields: {
        name: {
          label: 'Attachment name',
        },
      },
      limit: 'PDF only, up to 10MB',
      uploading: 'Uploading your document...',
    },
    editSection: {
      title: { edit: 'Edit part', create: 'Create part' },
      fields: {
        name: { label: 'Name' },
        customName: { label: 'Custom name' },
        hideTitle: { label: "Hide the part's title in PDF" },
        resetNumbering: {
          label: 'Reset page counter',
          helperText: 'Would you like to reset the contract page counter to 1 from this part onwards?',
        },
      },
      button: {
        edit: 'Save',
        create: 'Create part',
      },
    },
  },
  contextMenus: {
    attachment: {
      rename: 'Rename',
      remove: 'Remove',
    },
    section: {
      edit: 'Edit part',
      remove: 'Remove part',
    },
    variation: {
      rename: 'Rename',
      remove: 'Remove',
      duplicate: 'Duplicate',
    },
  },
  contractForm: {
    sectionComplete: 'Part complete',
    viewInContractButtonLabel: 'View this part in contract',
  },
};

export type LanguageNaming = typeof mrc;
