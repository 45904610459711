import type { FC } from 'react';
import { Suspense, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Spinner } from '@components/Spinner';
import { IconReplacement, toastBodyClassName, toastClassName } from '@ContractBuilder/components/Notifications';
import { sentryErrorFallback } from '@pages/ErrorPage';
import { withErrorBoundary, withProfiler } from '@sentry/react';
import { useFeatureFlags } from '@src/hooks';
import { initStructuredClonePolyfill } from '@utils/structured-clone-polyfill';
import axios from 'axios';

import MarkerIOPlugin from './plugins/marker-io';
import { AxiosErrorInterceptor, AxiosResponseInterceptor } from './utils/axios-err-interceptor';
import { getAppRouter } from './utils/get-app-router';

import 'react-toastify/dist/ReactToastify.min.css';

const queryClient = new QueryClient();

initStructuredClonePolyfill();
axios.interceptors.response.use(AxiosResponseInterceptor, AxiosErrorInterceptor);

// eslint-disable-next-line react-refresh/only-export-components
const App: FC = () => {
  const getFeatureFlagState = useFeatureFlags();
  const isBrandingEnabled = getFeatureFlagState('Branding');
  const isUsersEnabled = getFeatureFlagState('Users');

  const router = useMemo(() => getAppRouter(isUsersEnabled, isBrandingEnabled), [isUsersEnabled, isBrandingEnabled]);

  return (
    <QueryClientProvider client={queryClient}>
      <MarkerIOPlugin />
      <Suspense fallback={<Spinner />}>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          icon={IconReplacement}
          toastClassName={toastClassName}
          bodyClassName={toastBodyClassName}
        />
        <RouterProvider router={router} />
      </Suspense>
    </QueryClientProvider>
  );
};

const profilerOptions = {
  name: 'contract-builder-frontend',
};

// eslint-disable-next-line react-refresh/only-export-components
export default withProfiler(withErrorBoundary(App, { fallback: sentryErrorFallback }), profilerOptions);
