import type { DayPickerProps } from 'react-day-picker';
import dayjs from 'dayjs';

export const getInceptionDatePickerProps = (expiryDate?: string): DayPickerProps => {
  if (!expiryDate) {
    return {};
  }

  return {
    disabled: { after: dayjs(expiryDate).add(-1, 'day').toDate() },
  };
};

export const getExpiryDatePickerProps = (inceptionDate?: string): DayPickerProps => {
  const today = dayjs();
  const tomorrow = today.add(1, 'day').toDate();

  if (!inceptionDate) {
    return { disabled: { before: tomorrow } };
  }
  const isInceptionDateTodayOrInThePast = !today.isBefore(inceptionDate, 'day');
  const inceptionDatePlusOneDay = dayjs(inceptionDate).add(1, 'day').toDate();

  return {
    disabled: {
      before: isInceptionDateTodayOrInThePast ? tomorrow : inceptionDatePlusOneDay,
    },
  };
};
