import type { FC } from 'react';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { EDITING_BLOCK_Z_INDEX } from '@constants/z-indices';
import { BlockCreatorController } from '@ContractBuilder/modules/block-creator';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { ctxIsPDF, ctxIsTemplate } from '@ContractBuilder/rules/block/is-ctx';
import type { EntityData } from '@ContractBuilder/types';
import {
  type DocumentContext,
  getEndorsementMentionValues,
  getMentionValues,
  replaceMention,
} from '@ContractBuilder/utils';
import { useBlockFlagsGenerator } from '@hooks/use-block-flags-generator';
import type { ResourceBlock } from '@root/@types/base';
import type { BlockMentionValues, ResourceName } from '@root/@types/types';
import { getClausesAsMentions, getGroupedClauses, isEndorsement, isNonNullish } from '@root/helpers';
import { isExcludedFromEndorsementPreview } from '@root/helpers/blocks';
import { replaceUnknownMentionVariables } from '@root/helpers/pdf';
import { useDeepCompareMemo } from '@src/hooks';
import { isEndorsementViewPath } from '@utils/app-paths';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useEntityStore, useSchemaStore } from '../../../store';
import { leftBorderClasses } from '../classes';
import { BlockAlert } from '../components/BlockAlert';
import type { BlockFlagsContextType } from '../context/context';
import { BlockFlagsProvider } from '../context/context';
import { BlockView } from '../view/BlockView';
import { FeatureIndicators } from '../view/FeatureIndicators';

export interface BlockControllerProps {
  block: ResourceBlock;
  context: DocumentContext;
  disableControls?: boolean;
  endorsementParent?: EntityData;
  order?: number;
  schemaTreeForPdf?: any;
  sectionId?: string;
  submission?: EntityData;
}

export const BlockController: FC<BlockControllerProps> = ({
  block,
  context,
  disableControls = false,
  endorsementParent,
  order = 0,
  schemaTreeForPdf,
  sectionId,
  /** Pass down the submission as a prop rather than pulling it from the store to make it work with PDF render **/
  submission,
}) => {
  const isPDFRender = ctxIsPDF(context);
  const isTemplate = ctxIsTemplate(context);

  const { id, section_id } = block;

  const isEndorsementView = isEndorsementViewPath();

  const flags = useBlockFlagsGenerator(block, {
    context,
    disableControls,
    isEndorsementView,
  });

  const {
    details: {
      isParentEntityEditable,
      hasVariationsConditionalLogic,
      hasBeenMovedByRenewal,
      isEditable,
      isEditing,
      isVariationActionRequired,
      isVisible,
      renderPageBreak,
      showBlockAlerts,
      showCreator,
      showDataExtractionIndicator,
      showLockedIndicator,
      showPageBreakIndicator,
      showPermissionsSetIndicator,
      showUsedInTemplatesControls,
      showVariationsIndicator,
      showVisibilityReasonsIndicatorIcon,
      variations,
      visibilityDetails,
    },
  } = flags;

  const { schemaTreeList } = useSchemaStore(({ schemaTreeList }) => ({ schemaTreeList }));

  const { isHighlighted, isLoading } = useEntityStore(({ loadingBlockId, highlightedBlockId }) => ({
    isHighlighted: id === highlightedBlockId,
    isLoading: id === loadingBlockId,
  }));

  const currentVariationTabIdx = useVariationsTabsStore(({ getBlockCurrentVariationIdx }) =>
    getBlockCurrentVariationIdx(block.id),
  );

  const mentionValues = useDeepCompareMemo(() => {
    const usedClauses = getGroupedClauses(submission);

    const blockLinkedDatapointIds = (block.linkedDatapoints ?? []).map(({ id }) => id).filter(isNonNullish);
    const variations = block.variations ?? [];
    const variationsLinkedDatapointsCombined = variations
      .map(({ linkedDatapoints }) => linkedDatapoints)
      .flat()
      .filter(isNonNullish);

    const combinedDatapointIdsSet = new Set([
      ...blockLinkedDatapointIds,
      ...variationsLinkedDatapointsCombined.map(({ id }) => id),
    ]);

    const mentions = getMentionValues(
      submission?.data_items,
      [...combinedDatapointIdsSet],
      isPDFRender ? schemaTreeForPdf : schemaTreeList,
    );

    const endorsementMentionValues = getEndorsementMentionValues(
      submission,
      blockLinkedDatapointIds,
      endorsementParent,
    );
    const clausesList = getClausesAsMentions(usedClauses);

    return { ...mentions, ...endorsementMentionValues, ...clausesList } satisfies BlockMentionValues;
    // eslint-disable-next-line -- Skipping schema tree list changes
  }, [block.linkedDatapoints, submission, isEditing, isPDFRender, endorsementParent]);

  const handleReplaceMentionValues = (content?: string): string | undefined => {
    if (!mentionValues) {
      return content;
    }

    const blockLinkedDatapoints = block.linkedDatapoints?.map(({ id }) => id) ?? [];
    const linkedDatapointIds = [...Object.keys(mentionValues), ...blockLinkedDatapoints];

    if (block?.deleted_at) {
      return content;
    }

    linkedDatapointIds.forEach((id) => {
      content = replaceMention(id, mentionValues[id], content);
    });

    return content;
  };

  useDeepCompareEffect(() => {
    if (!isEditing && !isTemplate) {
      handleReplaceMentionValues();
    }
    // eslint-disable-next-line -- Replace mention values upon change
  }, [block.content, currentVariationTabIdx, isEditing, mentionValues, isEndorsementView, isTemplate]);

  if (isPDFRender && block?.content) {
    block.content = handleReplaceMentionValues(block.content) ?? block.content;
    block.content = replaceUnknownMentionVariables(block.content);
  }

  const value: BlockFlagsContextType = useDeepCompareMemo(
    () => ({ ...flags, block, isHighlighted, isLoading, mentionValues, context }),
    [flags, block, isHighlighted, isLoading, mentionValues, context],
  );

  if (isPDFRender && !isVisible && !isEndorsement(submission?.pk as ResourceName)) {
    return null;
  }

  const isHiddenFromPDFPreview = isExcludedFromEndorsementPreview(block, submission);

  return (
    <BlockFlagsProvider value={value}>
      <div
        className={clsx(
          'group relative',
          isEditing && EDITING_BLOCK_Z_INDEX,
          isEndorsementView && 'remove-interactive-mention',
        )}
        data-test-block-id={block.id}
        {...(isParentEntityEditable ? { 'data-editable': true } : {})}
      >
        <Show when={!isPDFRender}>
          <div
            id="left-border"
            className={leftBorderClasses({
              editing: isEditing,
              editable: isParentEntityEditable,
              disableHoverEffect: !isEditable,
              forceHoverEffect: isVariationActionRequired,
            })}
          />
          <LoadingOverlay active={isLoading} size="sm" />
        </Show>
        <BlockView sectionId={sectionId} submission={submission} />

        <FeatureIndicators
          block={block}
          hasVariationsConditionalLogic={hasVariationsConditionalLogic}
          isEnabled={!isPDFRender && !isEditing}
          isHiddenFromPDFPreview={isHiddenFromPDFPreview}
          isVisible={isVisible}
          showDataExtractionIndicator={showDataExtractionIndicator}
          showLockedIndicator={showLockedIndicator}
          showPageBreakIndicator={showPageBreakIndicator}
          showPermissionsSetIndicator={showPermissionsSetIndicator}
          showUsedInTemplatesControls={showUsedInTemplatesControls}
          showVariationsIndicator={showVariationsIndicator}
          showVisibilityReasonsIndicatorIcon={showVisibilityReasonsIndicatorIcon}
          variations={variations}
          visibilityDetails={visibilityDetails}
        />
        <Show when={showBlockAlerts}>
          <BlockAlert block={block} hasBeenMovedByRenewal={hasBeenMovedByRenewal} />
        </Show>
        <Show when={showCreator}>
          <BlockCreatorController id={id} order={order} sectionId={section_id} />
        </Show>
        <Show when={renderPageBreak}>
          <hr />
        </Show>
      </div>
    </BlockFlagsProvider>
  );
};
