import type { ReactNode } from 'react';
import { Fragment, useState } from 'react';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import Button from '@root/src/components/Button';
import { useLanguage } from '@src/language';
import { isEndorsementViewPath } from '@utils/app-paths';
import clsx from 'clsx';

import { useEntityStore } from '../../store';

type Entry = 'attachment' | 'block' | 'section' | 'clause';

interface ConfirmDeleteModalProps {
  handleClose: () => void;
  callback?: () => void;
  title: string;
  id: string;
  helperText?: ReactNode;
  type: Entry;
}

const ConfirmDeleteModal = ({ id, title, handleClose, helperText, type, callback }: ConfirmDeleteModalProps) => {
  const isEndorsementView = isEndorsementViewPath();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAttachment, deleteBlock, deleteSection, deleteSummaryBlock, deleteSummarySection } = useEntityStore(
    ({ deleteAttachment, deleteBlock, deleteSection, deleteSummaryBlock, deleteSummarySection }) => ({
      deleteAttachment,
      deleteBlock,
      deleteSection,
      deleteSummaryBlock,
      deleteSummarySection,
    }),
  );

  const { getContent } = useLanguage({ prefix: 'naming.mrc' });

  const handleDelete = async () => {
    const isBlockDelete = type === 'block' || type === 'clause';
    setIsLoading(true);
    if (type === 'attachment') {
      await deleteAttachment(id);
    }

    if (isBlockDelete && isEndorsementView) {
      await deleteSummaryBlock({ blockId: id });
    } else if (isBlockDelete) {
      await deleteBlock(id);
    }

    const isSectionDelete = type === getContent('section').toLowerCase();

    if (isSectionDelete && isEndorsementView) {
      await deleteSummarySection({ sectionId: id });
    } else if (isSectionDelete) {
      await deleteSection(id);
    }

    setIsLoading(false);
    handleClose();
    callback?.();
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
        onClose={handleClose}
        data-testid="confirm-delete-modal"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={handleClose}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-yellow-50 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    Remove this {type}?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-info-500">
                      Are you sure you would like to remove the {type}
                      {title && <span className="font-bold text-gray-800">: {title}</span>}
                    </p>
                  </div>
                  {helperText && <div className="mt-4 text-sm italic">{helperText}</div>}
                </div>
              </div>
              <div className="mt-8 items-center justify-end gap-x-2.5 sm:mt-8 sm:flex">
                <Button className="focus h-auto px-4 py-2" onClick={handleClose} kind="secondary" size="sm">
                  Cancel
                </Button>
                <Button
                  loading={isLoading}
                  className="h-auto px-4 py-2"
                  onClick={handleDelete}
                  kind="primary"
                  size="sm"
                  data-testid="confirm-delete-button"
                >
                  Remove {type}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmDeleteModal;
