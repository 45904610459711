import { baseBlockBodyClasses } from '@ContractBuilder/modules/block/classes';
import clsx from 'clsx';

export const LoadingSkeleton = () => {
  return (
    <div className="flex h-full w-full flex-col" aria-label="Loading skeleton" role="status" aria-busy="true">
      <div className="flex items-center gap-4 border-b bg-white px-5 py-[13.5px]">
        <div className="h-8 w-8 rounded-full bg-slate-200" />
        <div className="h-5 w-64 rounded-xl bg-slate-200" />
      </div>
      <div className="flex h-full">
        <div className="flex flex-1 flex-col">
          <div className="flex-1 px-10 py-20">
            <div
              className={clsx(
                'relative mx-auto mt-10 flex animate-pulse flex-col gap-3 border border-info-300 bg-white p-4 shadow-md',
                baseBlockBodyClasses,
              )}
            >
              <div className="h-3 w-1/3 rounded-xl bg-slate-200" />
              <div className="h-3 w-1/2 rounded-xl bg-slate-200" />
            </div>
          </div>
          <div className="flex w-full items-center justify-between border-t !border-info-200 bg-white py-2 shadow-2xl">
            <div className="inline-block max-w-[58vw] overflow-hidden text-ellipsis whitespace-nowrap py-2 pl-6 text-sm font-medium text-info-500">
              <div className="h-8 w-[108px] animate-pulse rounded-xl bg-slate-200" />
            </div>
            <div className="mr-4 flex items-center gap-4 whitespace-nowrap">
              <div className="h-8 w-[64px] animate-pulse rounded-xl bg-slate-200" />
              <div className="h-8 w-[114px] animate-pulse rounded-xl bg-slate-200" />
            </div>
          </div>
        </div>
        <div className="w-[340px]">
          <div className="flex h-full w-full flex-col overflow-x-hidden border-l bg-white p-5" />
        </div>
      </div>
    </div>
  );
};
