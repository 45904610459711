import { type FC, useEffect } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import LoadingOverlay from '@root/src/components/LoadingOverlay';
import { cva } from 'class-variance-authority';

import { useEntityStore } from '../../store';
import { getElementId } from '../../utils/get-element-id';

import { StepBlockSetup } from './components/steps/Step.BlockSetup';
import { StepBlockWysiwyg } from './components/steps/Step.BlockWysiwyg';
import { StepLibrary } from './components/steps/Step.Library';
import { StepStart } from './components/steps/Step.Start';
import { useBlockCreator } from './context';
import type { BlockCreatorStepId } from './types';

interface BlockCreatorViewProps {
  id: string;
}

const blockCreatorViewClasses = cva(['flex-col', 'flex', 'gap-10', 'w-full', 'pb-8', 'min-h-[370px]'], {
  variants: {
    step: {
      start: ['not-prose'],
      'library-block': ['not-prose'],
      'library-clause': ['not-prose'],
      block: ['not-prose'],
      wysiwyg: ['!p-0', 'min-h-min'],
    },
  },
});

export const BlockCreatorView: FC<BlockCreatorViewProps> = ({ id }) => {
  const { isLoading } = useEntityStore(({ isLoading }) => ({ isLoading }));
  const { step, blockLibraryId, setBlockLibraryId } = useBlockCreator();

  const isStep = (s: BlockCreatorStepId) => s === step;

  useEffect(() => {
    if (blockLibraryId && !isStep('library-block') && !isStep('library-clause')) {
      setBlockLibraryId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, blockLibraryId]);

  return (
    <div id={getElementId('block-creator', id)} className={blockCreatorViewClasses({ step })}>
      <LoadingOverlay active={isLoading} />
      <ShowFirstMatching>
        <Show when={isStep('start')}>
          <StepStart />
        </Show>
        <Show when={isStep('library-block')}>
          <StepLibrary stepId="library-block" />
        </Show>
        <Show when={isStep('library-clause')}>
          <StepLibrary stepId="library-clause" />
        </Show>
        <Show when={isStep('block')}>
          <StepBlockSetup />
        </Show>
        <Show when={isStep('wysiwyg')}>
          <StepBlockWysiwyg />
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
