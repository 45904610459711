import { type FC, Fragment, useRef, useState } from 'react';
import Button from '@components/Button';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { ApiPatchOperation } from '@root/@types/types';
import type { CanonicalUserRecordWithCapabilities } from '@root/helpers/user-management/types';
import { deleteUser, updateUser } from '@src/mutations';
import clsx from 'clsx';

interface UserResendInviteModalProps {
  user: CanonicalUserRecordWithCapabilities;
  handleClose: () => void;
  onRemoveUser?: () => Promise<void>;
}

const UserResendInviteModal: FC<UserResendInviteModalProps> = ({ user, handleClose, onRemoveUser }) => {
  const cancelButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleResendInvite = async () => {
    setIsLoading(true);
    try {
      await updateUser(user.username, { operation: ApiPatchOperation.UserResendInvite });
      handleClose();
    } catch {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserRemove = async () => {
    setIsLoading(true);

    try {
      await deleteUser(user.username);
      await onRemoveUser?.();
      handleClose();
    } catch {
      /* empty */
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
        static
        initialFocus={cancelButtonRef}
        open
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-[600px] sm:p-6 sm:align-middle">
              <div className="flex items-start gap-4">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-warning-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-warning-600" aria-hidden="true" />
                </div>
                <div className="flex flex-col">
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Update Pending User
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Would you like to{' '}
                        {user?.capabilitiesManager?.canBeResentInvite && (
                          <>
                            resend the invitation email to <b>{user?.email}</b>
                          </>
                        )}
                        {user?.capabilitiesManager?.canBeResentInvite && user?.capabilitiesManager?.canBeRemoved && (
                          <>
                            <br />
                            or{' '}
                          </>
                        )}
                        {user?.capabilitiesManager?.canBeResentInvite &&
                          !user?.capabilitiesManager?.canBeRemoved &&
                          '?'}
                        {user?.capabilitiesManager?.canBeRemoved && <>remove this user account?</>}
                      </p>
                    </div>
                  </div>

                  <div className="mt-12 flex items-center gap-2">
                    {user?.capabilitiesManager?.canBeRemoved && (
                      <Button kind="danger" className="h-10" onClick={handleUserRemove} loading={isLoading}>
                        Remove User
                      </Button>
                    )}
                    <div className="ml-auto flex items-center gap-2">
                      <Button className="h-10" onClick={() => handleClose()} loading={isLoading}>
                        Cancel
                      </Button>
                      {user?.capabilitiesManager?.canBeResentInvite && (
                        <Button kind="primary" className="h-10" onClick={handleResendInvite} loading={isLoading}>
                          Resend Invite
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserResendInviteModal;
