import type { FC, MouseEvent } from 'react';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from '@components/Button';
import { SaveAsNewChildBlockModal } from '@ContractBuilder/contract-builder-footer/SaveAsNewChildBlockModal';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { INLINE_BLOCK_EDITOR_NAME, useEditorsMap } from '@ContractBuilder/modules/editors-map';
import { useOnBlockEditSubmit } from '@ContractBuilder/modules/inline-block-edit/hooks/use-on-block-edit-submit';
import Nav from '@root/src/routes';
import { isBlocksPath } from '@root/src/utils/app-paths';
import { isString } from 'lodash-es';
interface LibraryBlockEditFooterProps {
  onUpdate: () => Promise<any> | void;
}

export const LibraryBlockEditFooter: FC<LibraryBlockEditFooterProps> = ({ onUpdate }) => {
  const state = useBlockEditFormStore(({ formValues }) => formValues);
  const { onSubmit, onSubmitAsNew } = useOnBlockEditSubmit();
  const { getEditor } = useEditorsMap();
  const [isSaveAsNewModalOpen, setSaveAsNewModalOpen] = useState<boolean>(false);
  const [isSavingAsNew, setIsSavingAsNew] = useState<boolean>(false);
  const navigate = useNavigate();
  const isBlocks = isBlocksPath();

  const editor = getEditor(INLINE_BLOCK_EDITOR_NAME);

  const isSaveButtonDisabled = !editor;

  const handlePropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  const handleOpenSaveAsNewModal = () => setSaveAsNewModalOpen(true);
  const handleCloseSaveAsNewModal = () => setSaveAsNewModalOpen(false);
  const handleConfirmSaveAsNew = async (name: string) => {
    handleCloseSaveAsNewModal();

    try {
      setIsSavingAsNew(true);

      if (state) {
        const id = await onSubmitAsNew({ ...state, name });

        if (isString(id)) {
          return navigate(generatePath(isBlocks ? Nav.Block : Nav.Clause, { id }), {
            replace: true,
          });
        }
      }
    } catch (_error) {
      /**/
    } finally {
      setIsSavingAsNew(false);
      setSaveAsNewModalOpen(false);
    }
  };

  const handleSubmit = async () => {
    if (state) {
      const hasCompleted = await onSubmit(state);

      if (hasCompleted) {
        await onUpdate();
      }
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <>
      <div
        className="flex w-full items-center justify-between border-t !border-info-200 py-2 shadow-2xl"
        onClick={handlePropagation}
      >
        <div className="ml-auto flex justify-end">
          <Button
            className="mr-4 h-[34px]"
            kind="primary"
            size="sm"
            isDisabled={isSaveButtonDisabled || isSavingAsNew}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            className="mr-4 h-[34px]"
            kind="primary"
            size="sm"
            isDisabled={isSaveButtonDisabled}
            loading={isSavingAsNew}
            onClick={handleOpenSaveAsNewModal}
          >
            Save as new
          </Button>
        </div>
      </div>
      <SaveAsNewChildBlockModal
        isLoading={isSavingAsNew}
        isOpen={isSaveAsNewModalOpen}
        onClose={handleCloseSaveAsNewModal}
        onConfirm={handleConfirmSaveAsNew}
      />
    </>
  );
};
