import type { FC, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface ToggleProps {
  value?: boolean;
  className?: string;
  isDisabled?: boolean;
  labelText: string;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  'data-testid'?: string;
}

const Toggle: FC<ToggleProps> = ({ isDisabled, value, onClick, className, labelText, ...props }) => {
  return (
    <div
      aria-checked={value}
      aria-label={labelText}
      className={clsx('flex cursor-pointer items-center gap-3', className)}
      onClick={isDisabled ? undefined : onClick}
      role="switch"
      {...props}
    >
      <div
        className={clsx(
          'relative inline-flex h-6 w-11 shrink-0 items-center rounded-full transition-colors duration-200',
          value ? 'bg-primary-600' : 'bg-info-200',
        )}
      >
        <motion.div
          className={clsx('h-5 w-5 transform rounded-full bg-white shadow-lg')}
          animate={{ x: value ? 21 : 3 }}
          initial={false}
          layout
          transition={{ type: 'spring', duration: 0.2, bounce: 0.2 }}
        />
      </div>

      {labelText && (
        <p className="truncate text-sm font-normal leading-5" title={labelText}>
          {labelText}
        </p>
      )}
    </div>
  );
};

export default Toggle;
