import type { FC } from 'react';
import Form from '@components/Form';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { WysiwygEditor } from '@ContractBuilder/components/Wysiwyg';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFieldProps } from '@ContractBuilder/modules/block-edit/hooks/use-block-edit-field-props';
import { INLINE_BLOCK_EDITOR_NAME } from '@ContractBuilder/modules/editors-map';
import type { ResourceBlock } from '@root/@types/base';
import { ReactRenderer } from '@tiptap/react';
import { isEndorsementViewPath } from '@utils/app-paths';
import { getContractDatapointControls } from '@WysiwygEditor/components/Controls/utils/datapoint-controls';
import { getSelectMenuItemsForContracts } from '@WysiwygEditor/components/Controls/utils/datapoint-options';
import { getCurrentContentId } from '@WysiwygEditor/components/Editor/extensions/helpers';
import type { DatapointInserterRenderFactory } from '@WysiwygEditor/components/Editor/types';
import { get } from 'lodash-es';

type BlockState = Pick<
  ResourceBlock,
  'content' | 'name' | 'section_id' | 'title' | 'type' | 'shouldInsertPageBreak' | 'variations' | 'visibility'
> & {
  id?: string;
  order?: number;
};

interface InlineBlockEditProps {
  className?: string;
  currentVariationIdx: number;
  hasVariations: boolean;
  onSubmit: () => Promise<void>;
}

export const InlineBlockEdit: FC<InlineBlockEditProps> = ({
  className,
  currentVariationIdx,
  hasVariations,
  onSubmit,
}) => {
  const isEndorsementView = isEndorsementViewPath();
  const { formValues } = useBlockEditFormStore(({ formValues }) => ({
    formValues,
  }));

  const { name: contentFieldAccessor, onChange: contentFieldChangeHandler } = useBlockEditFieldProps<BlockState>(
    hasVariations,
    currentVariationIdx,
    'content',
  );
  const { name: titleFieldAccessor, onChange: titleFieldChangeHandler } = useBlockEditFieldProps<BlockState>(
    hasVariations,
    currentVariationIdx,
    'title',
  );

  const datapointInserterItems = getSelectMenuItemsForContracts();
  const getDatapointInserterRenderer: DatapointInserterRenderFactory = (
    props,
    items,
    handleInsertDatapoint,
    handleCancel,
  ) =>
    new ReactRenderer(SelectMenu, {
      props: {
        className: 'max-h-[364px]',
        items,
        range: props.range,
        filterText: props.query,
        expandFirstLevel: false,
        isSearchable: false,
        maxTextWidth: 350,
        formValues,
        onClose: handleCancel,
        onItemClick: handleInsertDatapoint,
        disableEntries: props.disableEntries,
      },
      editor: props.editor,
    });

  return (
    <Form className="flex w-full flex-col bg-white pb-3" onSubmit={onSubmit}>
      <WysiwygEditor
        blockType={formValues?.type}
        className={className}
        content={get(formValues, contentFieldAccessor, '') as string}
        currentContentId={getCurrentContentId(formValues, currentVariationIdx)}
        handleTitleChange={titleFieldChangeHandler}
        name={INLINE_BLOCK_EDITOR_NAME}
        onUpdate={contentFieldChangeHandler}
        shouldShowControls={false}
        shouldShowHelperText={false}
        title={get(formValues, titleFieldAccessor, '') as string}
        titleFieldName={titleFieldAccessor}
        {...(isEndorsementView
          ? {}
          : {
              getDatapointControls: getContractDatapointControls,
              getDatapointInserterRenderer,
              datapointInserterItems,
            })}
      />
    </Form>
  );
};
