import type { FC } from 'react';
import { Popover } from '@components/Popover';
import { Show, ShowFirstMatching } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { getTemplatesUrl } from '@ContractBuilder/modules/block-edit/views/BlockUsedInTemplatesModal';
import { useEntityStore } from '@ContractBuilder/store';
import { authProvider } from '@helpers/auth';
import { LinkIcon } from '@heroicons/react/outline';
import { mdiOpenInNew } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import IconMdi from '@root/src/components/IconMdi';
import clsx from 'clsx';

interface BlockUsedWithinProps {
  block: ResourceBlock;
}

const templatesUrl = getTemplatesUrl();

export const BlockUsedWithinDetails = ({
  isLoading = false,
  label,
  usedInTemplates,
  usedInTemplatesCount = 0,
}: {
  isLoading?: boolean;
  label: string;
  usedInTemplates?: { id: string; name?: string }[];
  usedInTemplatesCount?: number;
}) => {
  const handleLinkClick = async (templateId: string) => {
    const searchParams = await authProvider.getAuthSearchParams();
    const win = window.open(`${templatesUrl}/${templateId}/contract${searchParams}`);
    return win?.focus();
  };

  return (
    <div className="w-60 overflow-x-hidden pl-4">
      <p className="py-2 pr-4 text-xs text-info-600">{label}</p>
      <div className="max-h-96 overflow-y-auto py-1 pr-2">
        <ShowFirstMatching>
          <Show when={isLoading}>
            {Array.from({ length: usedInTemplatesCount }).map((_, index) => (
              <div key={`skeleton-${index}`} className="py-1">
                <div className="h-3 w-3/4 animate-pulse rounded-md bg-gray-200" />
              </div>
            ))}
          </Show>
          <Show when={!isLoading}>
            {usedInTemplates?.map(({ id, name }) => (
              <div key={id} className="group py-1">
                <span
                  className="flex cursor-pointer justify-between gap-1 text-xs text-primary-500 group-hover:text-primary-600"
                  onClick={() => handleLinkClick(id)}
                >
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap no-underline hover:underline">
                    {name}
                  </span>
                  &nbsp;
                  <IconMdi
                    className="opacity-75 group-hover:opacity-100"
                    path={mdiOpenInNew}
                    size={0.6}
                    data-testid="block-icon-indicator:linked-library-block"
                  />
                </span>
              </div>
            ))}
          </Show>
        </ShowFirstMatching>
      </div>
    </div>
  );
};

export const BlockUsedWithin: FC<BlockUsedWithinProps> = ({ block }) => {
  const { usedInTemplates } = block;
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const blockUsedInOtherTemplates = usedInTemplates?.filter(({ id }) => id !== submission?.id);

  if (!blockUsedInOtherTemplates?.length) {
    return null;
  }

  return (
    <div className={clsx(['transform', 'transition-all', 'duration-200', 'ease-in-out', 'text-xs'])}>
      <Popover
        id="contract-view-content"
        shouldRenderInsidePortal
        renderTrigger={({ onClick }) => (
          <Tooltip content="Other templates use this block">
            <LinkIcon
              className="h-3.5 w-3.5 text-info-400"
              data-testid="block-icon-indicator:block-used-in-templates"
              onClick={onClick}
            />
          </Tooltip>
        )}
      >
        <BlockUsedWithinDetails label="BLOCK USED IN OTHER TEMPLATES" usedInTemplates={usedInTemplates} />
      </Popover>
    </div>
  );
};
