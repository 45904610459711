import type { ElementType, FC } from 'react';
import React from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import type { ElementSize } from '../../@types/types';
import sharedClasses from '../utils/shared-classes';

import Icon from './Icon';
import LoadingOverlay from './LoadingOverlay';
import type { SvgIconName } from './Svg';

type ButtonKindColor = 'green' | 'primary' | 'secondary' | 'tertiary' | 'danger-secondary' | 'danger' | 'ghost';

const buttonClasses = cva(
  'inline-flex overflow-clip items-center justify-center relative transition duration-75 ease-in font-medium text-sm rounded-md shadow-sm disabled:cursor-not-allowed focus:outline-none focus:shadow-outline gap-2.5',
  {
    variants: {
      kind: {
        green:
          'text-white bg-success-600 enabled:hover:bg-success-800 enabled:active:bg-success-1000 disabled:bg-success-300',
        primary:
          'text-white bg-primary-600 enabled:hover:bg-primary-800 enabled:active:bg-primary-1000 disabled:bg-primary-300',
        secondary:
          'enabled:active:bg-info-200 bg-white border border-info-300 disabled:text-info-600 shadow-sm enabled:hover:bg-info-100 text-info-700 font-normal',
        tertiary:
          'enabled:active:bg-info-200 bg-white border border-info-500 disabled:text-info-600 enabled:hover:bg-info-100 text-primary-800',
        'danger-secondary':
          'enabled:active:bg-info-200 bg-white border border-info-500 disabled:text-info-600 enabled:hover:bg-info-100 text-error-800',
        danger: 'text-white bg-error-600 enabled:hover:bg-error-500 enabled:active:bg-error-700 disabled:bg-error-500',
        ghost: 'text-info-700 bg-white font-normal disabled:bg-white/30 enabled:hover:bg-info-100 shadow-lg',
      },
    },
  },
);

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  iconName?: SvgIconName;
  isDisabled?: boolean;
  kind?: ButtonKindColor;
  size?: ElementSize;
  type?: string;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  as: Component = 'button',
  children,
  className,
  iconName,
  isDisabled,
  kind = 'secondary',
  type = 'button',
  loading,
  ...props
}) => {
  return (
    <Component
      className={twMerge(
        clsx(
          buttonClasses({ kind }),
          !clsx(className).match(/(p[xy]?)-(\d+)/) && 'px-4',
          !clsx(className).match(/(h)-(\d+)/) && 'h-12',
          (loading || isDisabled) && sharedClasses.disabled,
          className,
        ),
      )}
      disabled={isDisabled}
      type={type}
      {...props}
    >
      {iconName && <Icon name={iconName} className="mr-2 w-4" />}
      {children}
      <LoadingOverlay active={loading} size="xs" transparent />
    </Component>
  );
};

export default Button;
