import type { JSONSchemaType } from 'ajv';

import { ApiPatchOperation, IdentityProvider, UserRole } from '../types';

interface CreateUserPayload {
  firstName: string;
  lastName: string;
  role: UserRole;
  email: string;
  teams: number[];
  identityProvider: IdentityProvider;
}

interface UpdateUserPayload {
  operation: ApiPatchOperation.UserUpdate;
  firstName: string;
  lastName: string;
  role: UserRole;
  email: string;
  identityProvider: IdentityProvider;
  teams: number[];
}

interface ChangeUserStatePayload {
  operation: ApiPatchOperation.UserChangeState;
  enabled: boolean;
}

interface UserResendInvitePayload {
  operation: ApiPatchOperation.UserResendInvite;
}

export const createUserPayloadSchema: JSONSchemaType<CreateUserPayload> = {
  type: 'object',
  properties: {
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    email: { type: 'string' },
    role: { type: 'string', enum: [UserRole.Admin, UserRole.Superadmin, UserRole.User] },
    teams: { type: 'array', items: { type: 'number' } },
    identityProvider: { type: 'string', enum: [IdentityProvider.External, IdentityProvider.Internal] },
  },
  required: ['firstName', 'lastName', 'email', 'role'],
  additionalProperties: false,
};

export const updateUserPayloadSchema: JSONSchemaType<UpdateUserPayload> = {
  type: 'object',
  properties: {
    operation: { type: 'string', const: ApiPatchOperation.UserUpdate },
    firstName: { type: 'string' },
    lastName: { type: 'string' },
    email: { type: 'string' },
    role: { type: 'string', enum: [UserRole.Admin, UserRole.Superadmin, UserRole.User] },
    teams: { type: 'array', items: { type: 'number' } },
    identityProvider: { type: 'string', enum: [IdentityProvider.External, IdentityProvider.Internal] },
  },
  required: ['operation', 'firstName', 'lastName', 'role'],
  additionalProperties: false,
};

export const changeUserStatePayloadSchema: JSONSchemaType<ChangeUserStatePayload> = {
  type: 'object',
  properties: {
    operation: { type: 'string', const: ApiPatchOperation.UserChangeState },
    enabled: { type: 'boolean' },
  },
  required: ['operation', 'enabled'],
  additionalProperties: false,
};

export const userResendInvitePayloadSchema: JSONSchemaType<UserResendInvitePayload> = {
  type: 'object',
  properties: {
    operation: { type: 'string', const: ApiPatchOperation.UserResendInvite },
  },
  required: ['operation'],
  additionalProperties: false,
};
