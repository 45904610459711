import type { FC } from 'react';
import Icon from '@components/Icon';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useNavigation } from '@ContractBuilder/modules/navigation';
import type { EntityData } from '@ContractBuilder/types';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { isNonNullish } from '@root/helpers';
import { isEndorsementViewPath } from '@root/src/utils/app-paths';
import clsx from 'clsx';

interface AddNewPartButtonProps {
  submission?: EntityData;
}

export const AddNewPartButton: FC<AddNewPartButtonProps> = ({ submission }) => {
  const isEditing = useBlockEditFormStore((state) => isNonNullish(state.formValues?.id));
  const isViewingRevisionHistory = useIsRevisionHistory();
  const { openSectionModal } = useNavigation();

  const isEndorsementView = isEndorsementViewPath();
  const isAddNewPartDisabled = isEditing || isViewingRevisionHistory;

  const canAddNewPart = canChange(submission?.status);

  return (
    <Show when={!isEndorsementView && canAddNewPart}>
      <Tooltip content="Add new part">
        <div
          className={clsx(
            'flex h-5 w-5 cursor-pointer items-center justify-center rounded border border-info-600 transition-all hover:opacity-75',
            isAddNewPartDisabled && 'pointer-events-none opacity-50',
          )}
        >
          <Icon
            aria-label="Add new part"
            name="plus"
            onClick={() => openSectionModal()}
            className={clsx('flex h-3 w-3 items-center hover:opacity-75')}
          />
        </div>
      </Tooltip>
    </Show>
  );
};
