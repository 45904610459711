import type { FC } from 'react';
import { Fragment } from 'react';
import { generatePath } from 'react-router-dom';
import type { Column } from 'react-table';
import { Table } from '@components/Table';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { formatDate, sortByDate } from '@root/helpers';
import Nav from '@src/routes';
import { get } from 'lodash-es';

import { CreateEndorsementButton } from './CreateEndorsementButton';

interface ListEndorsementsButtonProps {
  data?: EndorsementData[];
  isLoading: boolean;
  submission: EntityData;
}

const columns: Array<Column<EndorsementData>> = [
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: ({ row }) => {
      const value = get(row, 'values.reference', '');
      return (
        <p className="w-[132px] max-w-[132px] truncate whitespace-nowrap text-info-500" title={value}>
          {value}
        </p>
      );
    },
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    Cell: ({ row }) => {
      const value = get(row, 'values.updated_at', '');
      return <p className="whitespace-nowrap text-info-500">{formatDate(value, 'DD MMM YYYY HH:mm')}</p>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => {
      const value = get(row, 'values.status', '');
      return <PillBadge content={value} />;
    },
  },
  {
    Header: 'Effective',
    accessor: 'effective_date',
    Cell: ({ row }) => {
      const value = get(row, 'values.effective_date', '');
      return <p className="whitespace-nowrap text-info-500">{formatDate(value, 'DD MMM YYYY')}</p>;
    },
  },
];

export const ListEndorsementsButton: FC<ListEndorsementsButtonProps> = ({ data = [], isLoading, submission }) => {
  const handleItemClick = (item: EntityData) => {
    const endorsementContractPath = generatePath(Nav.EndorsementView, {
      id: submission.id,
      endorsement_id: item.id,
    });

    return window.open(endorsementContractPath, '_self');
  };

  return (
    <>
      <h2 className="px-4 pb-2 pt-4 text-lg leading-normal text-info-900">Endorsements</h2>
      <div className="max-h-[414px] overflow-y-auto shadow-inner">
        <Table<EndorsementData>
          data={sortByDate(data, 'desc', 'updated_at')}
          emptyRowsLabel="No endorsements available"
          columns={columns}
          isLoading={isLoading}
          onRowClick={handleItemClick}
          rowsSkeletonLength={submission.endorsementsCount}
        />
      </div>
      <CreateEndorsementButton
        className="align-start w-full items-center rounded-tl-none rounded-tr-none border-b-0 border-l-0 border-r-0 border-t border-info-200"
        isDisabled={isLoading}
      />
    </>
  );
};
