import React from 'react';
import { useQuery } from 'react-query';
import Button from '@components/Button';
import { Chip } from '@components/Chip';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import { useBlockCreator } from '@ContractBuilder/modules/block-creator/context';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { mdiAccountGroup, mdiCalendar, mdiMapMarker, mdiShieldCheck } from '@mdi/js';
import { fetchAllTeams } from '@queries/fetchTeams';
import type { Team } from '@root/@types/types';
import { useLanguage } from '@src/language';
import type { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import dayjs from 'dayjs';

import type { LibraryBlockTypeForListPage } from '../../../types';
import { BlockPreview } from '../views/BlockPreview';

interface LibraryBlockListItemProps {
  block: LibraryBlockTypeForListPage;
  onBlockInsert: () => void;
  onSummary?: (block: LibraryBlockTypeForListPage) => unknown;
  tableRow: Row<LibraryBlockTypeForListPage>;
  isClause: boolean;
}

const createMarkup = (html: string) => ({ __html: sanitizeHtml(html) });

const footerEntryClasses = 'flex items-center gap-1';

const Teams = ({ teamIds, allTeams }: { teamIds: number[]; allTeams: Team[] }) => {
  const teamNames = teamIds.map((teamId) => allTeams.find((team) => team.id === teamId)?.name).filter((i) => !!i);
  const teamsLength = teamIds.length;
  if (teamsLength === 1) {
    return <span>{teamNames[0]}</span>;
  }

  const teamsList = (
    <ul>
      {teamNames.map((teamName) => (
        <li key={teamName}>{teamName}</li>
      ))}
    </ul>
  );

  return (
    <Tooltip content={teamsList} allowHTML={true}>
      <div>{`${teamsLength} teams`}</div>
    </Tooltip>
  );
};

const ClassesOfBusiness = ({ classesOfBusiness }: { classesOfBusiness: string[] }) => {
  const classesOfBusinessLength = classesOfBusiness.length;
  if (classesOfBusinessLength === 1) {
    return <span dangerouslySetInnerHTML={createMarkup(classesOfBusiness[0])} />;
  }

  const classesOfBusinessList = (
    <ul>
      {classesOfBusiness.map((classOfBusiness) => (
        <li key={classOfBusiness} dangerouslySetInnerHTML={createMarkup(classOfBusiness)} />
      ))}
    </ul>
  );

  return (
    <Tooltip content={classesOfBusinessList} allowHTML>
      <div>{`${classesOfBusinessLength} classes of business`}</div>
    </Tooltip>
  );
};

export const LibraryBlock = ({ isClause, block, tableRow, onBlockInsert, onSummary }: LibraryBlockListItemProps) => {
  const { data: allTeams } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
  });
  const { blockLibraryId, setBlockLibraryId } = useBlockCreator();
  const { clause_type, created_at, name, title, type, updated_at } = block;

  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const isInPreview = blockLibraryId === block.id;

  const handlePreviewClick = () => setBlockLibraryId(isInPreview ? undefined : block.id);
  const typeName = getContent(type);

  return (
    <div className="flex h-fit max-h-full flex-col">
      <div
        className={clsx(
          'relative flex h-full max-h-[100px] w-full cursor-default flex-col justify-evenly py-4 hover:bg-primary-50',
          DEFAULT_CREATOR_PADDING,
          tableRow.depth > 0 && `!pl-12`,
        )}
        id={block.id}
      >
        <div className="flex w-full items-start justify-between gap-3">
          <Show when={tableRow.getCanExpand()}>
            <div className="flex-shrink-0 cursor-pointer">
              <Show when={tableRow.getIsExpanded()}>
                <ChevronDownIcon
                  onClick={tableRow.getToggleExpandedHandler()}
                  className="h-4 w-4 text-info-600 transition-colors focus:outline-none"
                />
              </Show>
              <Show when={!tableRow.getIsExpanded()}>
                <ChevronRightIcon
                  onClick={tableRow.getToggleExpandedHandler()}
                  className="h-4 w-4 text-info-600 transition-colors focus:outline-none"
                />
              </Show>
            </div>
          </Show>

          <Show when={isClause && !!block.clause_reference}>
            <div className="flex-shrink truncate text-sm font-semibold">{`${block.clause_reference} - `}</div>
          </Show>
          <div
            className="flex-shrink truncate text-sm font-semibold"
            dangerouslySetInnerHTML={createMarkup(name ?? title ?? block.id.split('-')[0])}
          />

          <div className="flex flex-grow items-center gap-2 whitespace-nowrap">
            <Show when={isClause && !!clause_type}>
              <Chip data-testid={clause_type} dot>
                {clause_type}
              </Chip>
            </Show>
            <Show when={!isClause && !!typeName}>
              <Chip data-testid={typeName} dot>
                {typeName}
              </Chip>
            </Show>
          </div>

          <div className="flex items-center gap-2">
            <Show when={!!onSummary}>
              <Button className="h-8 px-2" onClick={() => onSummary?.(block)}>
                Summary
              </Button>
            </Show>
            <Button className="h-8 px-2" onClick={handlePreviewClick}>
              {isInPreview ? 'Close' : 'Preview'}
            </Button>
            <Button className="h-8 px-2" kind="primary" onClick={onBlockInsert}>
              Insert
            </Button>
          </div>
        </div>

        <Show when={(block?.class_of_business?.length ?? 0) > 0}>
          <div id="block-library-middle" className="relative mb-2">
            <div className="absolute top-[-15px] pb-1 text-xs font-semibold">
              <div className="flex pt-1">
                <IconMdi path={mdiMapMarker} size={0.6} />
                <ClassesOfBusiness classesOfBusiness={block?.class_of_business ?? []} />
              </div>
            </div>
          </div>
        </Show>

        <div id="block-library-footer" className="flex items-center gap-3 pt-2 text-xs text-info-400">
          <div className={footerEntryClasses}>
            <IconMdi path={mdiCalendar} size={0.6} />
            Updated {dayjs(updated_at ?? created_at).format('DD MMM YYYY')}
          </div>
          <Show when={isClause && !!block.classification}>
            <div className={footerEntryClasses}>
              <IconMdi path={mdiShieldCheck} size={0.6} />
              {block.classification}
            </div>
          </Show>
          <Show when={(block?.teams?.length ?? 0) > 0}>
            <div className={footerEntryClasses}>
              <IconMdi path={mdiAccountGroup} size={0.6} />
              <Teams teamIds={block?.teams ?? []} allTeams={allTeams ?? []} />
            </div>
          </Show>
        </div>
        <div className="absolute bottom-0 left-5 right-5 h-[1px] bg-info-200" />
      </div>
      <Show when={isInPreview}>
        <div className="h-full overflow-y-auto">
          <BlockPreview id={blockLibraryId!} />
        </div>
      </Show>
    </div>
  );
};
