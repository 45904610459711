import { useEntityStore } from '@ContractBuilder/store';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useModalDispatch } from '@hooks/useModalProvider';

import { RevisionBanner } from '../views/RevisionBanner';

export const RevisionBannerController = () => {
  const { closeModal } = useModalDispatch();

  const { closeDrawer } = useDrawerStore(({ closeDrawer }) => ({ closeDrawer }));
  const { restoreRevision, submission } = useEntityStore(({ restoreRevision, submission }) => ({
    restoreRevision,
    submission,
  }));

  if (!submission) {
    return null;
  }

  const handleConfirmRestoreRevision = async () => {
    if (submission?.revision_id) {
      closeModal();
      await restoreRevision(submission.revision_id);
      closeDrawer();
    }
  };

  const canSaveOrRestoreRevision = !['FINAL', 'PUBLISHED'].includes(submission?.status ?? '');

  return (
    <RevisionBanner
      canSaveOrRestoreRevision={canSaveOrRestoreRevision}
      onConfirmRestoreRevision={handleConfirmRestoreRevision}
    />
  );
};
