import type { TenantConfig, TenantName, TenantPdfConfig } from '../@types/types';

import { EdLogo } from './assets';

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

const client: TenantName = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;
if (!client) {
  throw new Error('Missing env var: CLIENT');
}

const appName: string = import.meta.env?.VITE_APP_NAME ?? process?.env?.APP_NAME;
if (!appName) {
  throw new Error('Missing env var: APP_NAME');
}

export const pdfTenantsConfig = (client: TenantName): TenantPdfConfig => {
  const defaultPdfConfig: TenantPdfConfig = {
    format: 'A4',
    documents: {
      templates: ['template-document'],
      submissions: ['submission-document'],
      endorsements: ['endorsement-summary'],
      brandings: ['branding-document'],
    },
  };

  switch (client) {
    // temp - testing
    case 'ct-ppl': {
      return {
        ...defaultPdfConfig,
        watermark: {
          innerHTML: 'Specimen',
          classNames: ['text-info-200', 'font-bold', 'uppercase', 'fixed', 'z-[-1]'],
          css: 'left: -16px; top: 50%; transform: translateY(-50%) rotate(-45deg); font-size: 160px; line-height: 194px; font-family: "Gotham Book"',
        },
      };
    }
    default:
      return defaultPdfConfig;
  }
};

const artificialConfig = (): TenantConfig => {
  return {
    name: 'Contract builder',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    headerLogoClasses: 'h-12',
    pdf: pdfTenantsConfig('artificial'),
    isAttachmentsEnabled: true,
    isDataExtractionEnabled: true,
    isEndorsementEnabled: true,
    isCommentsEnabled: true,
    isRevisionsEnabled: true,
    isSSOEnabled: true,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctAonConfig = (): TenantConfig => {
  return {
    name: 'Contract builder',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    headerLogoClasses: 'h-12',
    pdf: pdfTenantsConfig('artificial'),
    isAttachmentsEnabled: true,
    isDataExtractionEnabled: true,
    isEndorsementEnabled: true,
    isCommentsEnabled: true,
    isRevisionsEnabled: true,
    isSSOEnabled: true,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctEdbroking = (): TenantConfig => {
  return {
    name: 'Ed Broking',
    logo: EdLogo, // red
    headerLogo: '/assets/Ed_Logo_White.png',
    headerLogoClasses: 'h-8',
    headerLogoHeight: '32',
    headerLogoWidth: '60',
    pdf: pdfTenantsConfig('ct-edbroking'),
    isAttachmentsEnabled: true,
    isEndorsementEnabled: true,
    cognito: {
      linkedLogo:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY0IiBoZWlnaHQ9Ijg4IiB2aWV3Qm94PSIwIDAgMTY0IDg4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTE1LjA2NSA2OC40ODY5QzExNS4wNjUgNjguNDg2OSAxMDQuOTk5IDcwLjA2MDcgOTkuNjY4MiA2OS45OTg0QzkwLjU5OTMgNjkuOTk4NCA4My41MTA0IDYzLjM4MjIgODMuNTEwNCA1My4zOTkzQzgzLjUxMDQgNDYuMzcyMyA4OC4xOTU2IDM4LjIwMjIgOTguMTU0NiAzNy4zNDMxQzk4LjE1NDYgMzcuMzQzMSAxMDQuNTE4IDM2LjY1MzQgMTE1LjA2NSAzOS4xMDM2VjY4LjQ4NjlaTTExOS43OTEgMC4wMDE0NjQzMUMxMTcuMTM4IC0wLjAxMDk4NjIgMTE1LjEyNSAyLjA4MzIxIDExNS4xMjggNC43ODc0NlYyMS4xMzVDMTA0LjI0NiAxOS4xNjI4IDk2LjE4MjIgMTkuMjcyNCA5Ni4xODIyIDE5LjI3MjRDNzkuNzkyNSAxOS4yNzI0IDY0LjIxNTcgMzEuNjkzMSA2NC4yMTU3IDUzLjUxMzlDNjQuMjE1NyA3Mi45MDE5IDc2Ljk5OTkgODcuOTkxOSA5Ni41Mjg5IDg3Ljk5MTlDOTYuNTI4OSA4Ny45OTE5IDEwNC41MTMgODguMjQ1OSAxMzQuNDIyIDg1LjkwMjdWMC4wMDE0NjQzMUgxMTkuNzkxWiIgZmlsbD0iI0VEMUMyNCIvPgo8cGF0aCBkPSJNMTQ3LjMzNyA2Ni4xNzZDMTQ0LjY1MSA2Ni4xNzYgMTQyLjQ0NCA2OC4zNTI0IDE0Mi40NDQgNzEuMDMxN1Y4NS45MDI2SDE2Mi41NTRWNjYuMTc2SDE0Ny4zMzdaIiBmaWxsPSIjRUQxQzI0Ii8+CjxwYXRoIGQ9Ik01NS41OTIzIDY2LjYzMjhIMjEuMDM3NlY1MS44OTEzSDUzLjk0NDFDNTQuMTU2IDQ0LjU3MDQgNTUuNzc0MyAzOC4xMjg1IDU4LjQyNzQgMzIuNjIwNEgyMS4wMzc2VjE5LjI3MDlINjEuMTY1MkM2My4xMDI3IDE5LjI1ODUgNjQuNzcwOCAxOC4wNjMyIDY1LjQ2OSAxNi4zNzc0TDcyLjI3MzcgLTUuNjE3MTJlLTA2SDQuNjQyOTNDMi4wODQ2MSAtNS42MTcxMmUtMDYgLTAuMDEyNDEyMSAyLjAwNDUzIDUuNTMwNjFlLTA1IDQuMzkyNTRWODEuNTE2MkMwLjAwNTA0MjI4IDgzLjg4MTggMi4wOTQ1NSA4NS44OTg4IDQuNTMwNjkgODUuOTAxMkg2Ni4zMjY3Vjg1Ljg4ODhDNjYuMDIyNSA4NS41NzUgNjUuNzE1OSA4NS4yNjg4IDY1LjQyMTcgODQuOTQ1QzYwLjc4MTMgNzkuODQ1MyA1Ny40NDI1IDczLjU3NTIgNTUuNTkyMyA2Ni42MzI4WiIgZmlsbD0iI0VEMUMyNCIvPgo8L3N2Zz4K', // /assets/edbroking_logo.svg
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctLockton = (): TenantConfig => {
  return {
    name: 'Lockton',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoClasses: 'h-12',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    pdf: pdfTenantsConfig('ct-lockton'),
    isAttachmentsEnabled: true,
    isDataExtractionEnabled: true,
    isEndorsementEnabled: true,
    isSSOEnabled: true,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctBms = (): TenantConfig => {
  return {
    name: 'BMS',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoClasses: 'h-12',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    pdf: pdfTenantsConfig('ct-bms'),
    isAttachmentsEnabled: true,
    isEndorsementEnabled: true,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctAjg = (): TenantConfig => {
  return {
    name: 'AJG',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoClasses: 'h-12',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    pdf: pdfTenantsConfig('ct-ajg'),
    isAttachmentsEnabled: true,
    isEndorsementEnabled: true,
    isDataExtractionEnabled: true,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctHowden = (): TenantConfig => {
  return {
    name: 'Howden',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoClasses: 'h-12',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    pdf: pdfTenantsConfig('ct-howden'),
    isAttachmentsEnabled: true,
    isDataExtractionEnabled: false,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const ctPpl = (): TenantConfig => {
  return {
    name: 'PPL',
    logo: '/assets/Artificial_Logo_Black.svg',
    headerLogo: '/assets/Artificial_Logo_White.png',
    headerLogoClasses: 'h-12',
    headerLogoHeight: '48',
    headerLogoWidth: '130',
    pdf: pdfTenantsConfig('ct-ppl'),
    isAttachmentsEnabled: false,
    isBrandingEnabled: true,
    isDataExtractionEnabled: false,
    isEndorsementEnabled: false,
    cognito: {
      linkedLogo: 'https://assets.artificial.io/mailing/artificial-full.png',
      emailSender: 'noreply@mail.contract-builder.artificialos.com',
      appName,
      appDescription: `${appName} is a contract management platform. If you believe you've received this email in error, please contact your administrator.`,
      client: capitalize(client),
      footerAddress: '',
    },
    teams: [],
  };
};

const getTenantConfig = (): TenantConfig => {
  if (client === 'artificial') {
    return artificialConfig();
  }

  if (client === 'ct-aon') {
    return ctAonConfig();
  }

  if (client === 'ct-edbroking') {
    return ctEdbroking();
  }

  if (client === 'ct-lockton') {
    return ctLockton();
  }

  if (client === 'ct-bms') {
    return ctBms();
  }

  if (client === 'ct-ajg') {
    return ctAjg();
  }

  if (client === 'ct-howden') {
    return ctHowden();
  }

  if (client === 'ct-ppl') {
    return ctPpl();
  }

  return artificialConfig();
};

export const tenantConfig: TenantConfig = getTenantConfig();
