import React, { useRef } from 'react';
import InputSelect from '@components/InputSelect';
import { Show } from '@components/Show';
import LayoutOptions from '@components/Sidebar/BlockLayoutSelector/LayoutSelector';
import { CLAUSE_TYPES } from '@root/helpers';
import InputText from '@root/src/components/InputText';
import Label from '@root/src/components/Label';
import { useEffectOnce } from '@src/hooks';

import { useBlockEditFormStore } from '../../../block-edit';
import { useBlockCreator } from '../../context';
import { areNotEmpty } from '../../utils';
import { StepView } from '../StepView';

export const StepBlockSetup = () => {
  const { formValues: state, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prev) => ({ ...prev, name: e.target.value, title: e.target.value }));
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffectOnce(() => {
    inputRef.current?.focus();
  });

  const { onPrevStep, setStep } = useBlockCreator();
  const isValid = areNotEmpty({ name: state?.name?.trim(), type: state?.type });
  return (
    <StepView
      isValid={isValid}
      nextButtonLabel={'Create block'}
      onNext={() => setStep('wysiwyg')}
      onPrev={onPrevStep}
      secondHeaderTitle="Create Block"
      stepId="block"
    >
      <div className="mx-auto flex w-2/3 flex-col gap-7">
        <InputText
          name="create-block-name-input"
          labelText="Block name"
          value={state?.name}
          onChange={handleNameChange}
          placeholder="Block name"
          className="w-full"
          ref={inputRef}
        />
        <div>
          <div className="mb-2 flex justify-between text-sm font-medium text-info-700">
            <Label>Block Layout</Label>
          </div>
          <LayoutOptions
            onChange={(type) => setFormValues((prev) => ({ ...prev, type }))}
            showAllOptions
            type={state?.type ?? 'generic'}
          />
        </div>
        <Show when={state?.type === 'clause'}>
          <div className="flex items-center justify-between gap-5">
            <InputSelect
              labelText="Clause Type"
              placeholder="Select clause type"
              options={CLAUSE_TYPES.map((label) => ({ name: label, value: label }))}
              name="clause_type"
              value={state?.clause_type}
              onChange={(value) => setFormValues((prev) => ({ ...prev, clause_type: value }))}
              inputClassName="bg-white"
            />
            <InputText
              labelText="Clause Reference"
              placeholder="Clause reference"
              name="clause_reference"
              value={state?.clause_reference}
              onChange={(event) => setFormValues((prev) => ({ ...prev, clause_reference: event.target.value }))}
            />
          </div>
        </Show>
      </div>
    </StepView>
  );
};
