import { replaceMatchedFragments } from '@ContractBuilder/functions/replace-matched-fragments';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { useDeepCompareMemo } from '@src/hooks';
import { isEmpty } from 'lodash-es';

const highlightClassesOfBusiness = (class_of_business: string[], search: string) => {
  return class_of_business?.map((class_of_business) => class_of_business.replace(search, replaceMatchedFragments));
};

const parseNameAndClassOfBusiness = (item: LibraryBlockTypeForListPage, search: string) => {
  let newName = item.name ? item.name?.replace(search, replaceMatchedFragments) : '';
  let newClassOfBusiness =
    item.class_of_business && !isEmpty(item.class_of_business)
      ? highlightClassesOfBusiness(
          Array.isArray(item.class_of_business) ? item.class_of_business : [item.class_of_business],
          search,
        )
      : undefined;

  return {
    ...item,
    name: newName,
    class_of_business: newClassOfBusiness,
  };
};

export const useFilteredBlocks = (blocks: LibraryBlockTypeForListPage[] = [], search: string = '') => {
  return useDeepCompareMemo(() => blocks.map((item) => parseNameAndClassOfBusiness(item, search)), [search, blocks]);
};
