import type { FC } from 'react';
import { useQuery } from 'react-query';
import Icon from '@components/Icon';
import { fetchBlock } from '@queries/fetchBlock';

import { DrawerSection } from './DrawerSection';

interface DrawerParentBlockNameProps {
  parentBlockId?: string;
}

export const DrawerParentBlockName: FC<DrawerParentBlockNameProps> = ({ parentBlockId }) => {
  const { data: parentBlock, isLoading } = useQuery({
    queryKey: ['block', parentBlockId],
    queryFn: () => fetchBlock(parentBlockId as string),
    enabled: !!parentBlockId,
  });

  if (!parentBlockId) return null;

  return (
    <DrawerSection title="Block parent">
      <div className="flex cursor-default items-start gap-1 text-sm text-info-500">
        <Icon name="block-parent" />
        {isLoading ? <div className="mt-1 h-3 w-32 animate-pulse rounded-lg bg-slate-200" /> : parentBlock?.data?.name}
      </div>
    </DrawerSection>
  );
};
