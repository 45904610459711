import type { CellContext, ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const nameAccessor = <T extends { name?: string }>(
  columnHelper: ColumnHelper<T>,
  header = 'Name',
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.accessor((row) => row.name ?? '', {
    id: 'name',
    header,
    sortingFn: 'text',
    sortUndefined: 1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
      filter: { type: 'dropdown', placeholder: 'All blocks', ...filterMeta },
      dataCellClassName: 'text-sm leading-5 font-medium text-info-900',
      headingCellClassName: 'w-full',
    },
    cell: (cellContext: CellContext<T, string>) => (
      <span className={`pl-${cellContext.row.depth * 4}`}>{cellContext.getValue()}</span>
    ),
  });
