import type { FC } from 'react';
import { baseBlockBodyClasses } from '@ContractBuilder/modules/block/classes';
import { BlockConfigurationPanelController } from '@ContractBuilder/modules/block-configuration-panel';
import type { DynamoDBLibraryBlock } from '@root/@types/database';
import { clsx } from 'clsx';

import { LibraryBlockEditFooter } from './LibraryBlockEditFooter';
import { LibraryBlockEditHeader } from './LibraryBlockEditHeader';
import { LibraryBlockView } from './LibraryBlockView';

interface LibraryBlockEditLayoutProps {
  canNavigate: boolean;
  data?: DynamoDBLibraryBlock;
  onUpdate: () => Promise<any> | void;
}

export const LibraryBlockEditLayout: FC<LibraryBlockEditLayoutProps> = ({ canNavigate, data, onUpdate }) => {
  if (!data) {
    return null;
  }

  return (
    <div className="relative flex w-full flex-col bg-info-50">
      <div className="relative flex flex-col">
        <LibraryBlockEditHeader canNavigate={canNavigate} data={data} />
        <div className="flex" style={{ height: 'calc(100vh - 60px)' }}>
          <div className="flex flex-1 flex-col justify-between">
            <div className={clsx('mt-20 flex w-full justify-center !px-0 pb-16', baseBlockBodyClasses)}>
              <LibraryBlockView block={data} onUpdate={onUpdate} />
            </div>
            <LibraryBlockEditFooter onUpdate={onUpdate} />
          </div>
          <div className="w-[340px]">
            <BlockConfigurationPanelController />
          </div>
        </div>
      </div>
    </div>
  );
};
