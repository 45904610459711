import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';
import { MODALS } from '@constants/index';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { useDeepCompareCallback } from '@hooks/use-deep-compare-callback';
import { useModalDispatch } from '@hooks/useModalProvider';
import { isTemplatePath } from '@root/src/utils/app-paths';

interface UseBlockViewEditProps {
  id: string;
  isEditable: boolean;
  name: string;
  sectionId?: string;
  type: 'block' | 'clause';
}

export const useBlockViewEdit = ({ id, isEditable, name, sectionId, type }: UseBlockViewEditProps) => {
  const isTemplate = isTemplatePath();
  const { showModal } = useModalDispatch();
  const { onCancel, setEditingBlock, setOnCancelCallback } = useBlockEdit();
  const { closeDrawer, openDrawer } = useDrawerStore(({ closeDrawer, openDrawer }) => ({
    closeDrawer,
    openDrawer,
  }));

  const onEdit: MouseEventHandler<HTMLDivElement> = useDeepCompareCallback(
    (event) => {
      event.stopPropagation();
      const isCurrentlyEditing = useBlockEditFormStore.getState().formValues?.id === id;

      if (!isEditable) {
        return;
      }

      const { target } = event;

      // If the click originated from a mention we want to show the datapoint selector instead of entering edit mode
      if (!isTemplate && target instanceof HTMLSpanElement && target.dataset.type === 'mention') {
        return;
      }

      if (!isEditable) {
        return;
      }

      if (!isCurrentlyEditing) {
        setEditingBlock(id);

        setOnCancelCallback({ fn: closeDrawer });
        openDrawer({
          type: DrawerLayoutComponent.BlockConfigurationPanel,
          props: {
            onCancel: () => onCancel(true),
          },
        });
      }
    },
    // eslint-disable-next-line -- We don't care about any of the functions changing
    [id, isTemplate, isEditable, sectionId],
  );

  const onDelete: MouseEventHandler = useCallback(
    () =>
      showModal(MODALS.CONFIRM_DELETE, {
        id,
        title: name,
        type,
        callback: () => onCancel(true),
      }),
    [id, name, type, onCancel, showModal],
  );

  return { onDelete, onEdit };
};
