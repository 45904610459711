import React from 'react';
import { useQuery } from 'react-query';
import { useModalDispatch } from '@hooks/useModalProvider';
import { getTagsTableColumns } from '@pages/User/helpers/columns/getTagsTableColumns';
import type { Action, TagShort } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { fetchTags } from '@src/queries';

import { MODALS } from '../../constants';

import { ReactTable } from './components';
import { useDataIsEmpty } from './hooks';

export const Tags: React.FC = () => {
  const { showModal } = useModalDispatch();
  const { data = [], status } = useQuery(['tags'], fetchTags, { refetchOnWindowFocus: false });

  const handleEditTagClick = (tag: TagShort) => () => showModal(MODALS.ADMIN_TAG, { tag, isAdd: false });

  const columns = getTagsTableColumns({ handleEditTagClick });
  const isEmpty = useDataIsEmpty(data, status);

  const actions: Action[] = [
    {
      key: 'create',
      label: 'New Tag',
      onClick: () => {
        showModal(MODALS.ADMIN_TAG, { isAdd: true });
      },
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
      }}
      enableColumnFilters={false}
      entityName="tag"
      filteredData={data}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      isEmpty={isEmpty}
    />
  );
};

export const UserTagsPage = withMenuLayout(Tags);
