import type { KeyboardEvent, MouseEvent } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { selectTriggerSizes } from '@components/classes/select';
import Icon from '@components/Icon';
import type { InputMultiSelectProps } from '@components/InputMultiSelect/InputMultiSelect';
import { InputSelectMenuContainer, inputSelectStyles } from '@components/InputSelect';
import { MAX_DROPDOWN_HEIGHT_PX } from '@components/shared';
import { Show, ShowFirstMatching } from '@components/Show';
import { VirtualizedList } from '@components/virtualization';
import type { UISelectionType } from '@root/@types/types';
import { useDidUpdateEffect } from '@src/hooks';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { useCombobox, useMultipleSelection } from 'downshift';
import { isEqual, isString } from 'lodash-es';

const SELECT_LABEL_MAX_LENGTH = 20;

const inputClasses = cva(
  ['multi-select', 'flex', 'cursor-pointer ', 'justify-between', 'rounded-md', 'border', 'border-info-300'],
  {
    variants: {
      size: selectTriggerSizes,
      isDisabled: { true: '!cursor-not-allowed opacity-50' },
      isIncomplete: { true: 'field-incomplete' },
      isOther: { true: '!cursor-default' },
    },
  },
);

const selectedItemStyle = 'bg-info-200 rounded-md bg-rounded py-1 -my-1 pl-3 pr-2 mr-1 space-x-1 text-sm font-normal';
const formatItemLabel = <T = string,>(
  item: T,
  selectLabelMaxLength: number,
  options: readonly string[] | readonly UISelectionType<T>[],
): string => {
  if (isArrayOfStrings(options) && isString(item)) {
    return item?.length > selectLabelMaxLength ? `${item.slice(0, selectLabelMaxLength)}...` : item;
  }

  const found = (options as UISelectionType<T>[]).find((option) => option.value === item);
  const name = found?.name ?? '';

  return name.length > selectLabelMaxLength ? `${name.slice(0, selectLabelMaxLength)}...` : name;
};

type InputMultiSelectInnerProps<T = string> = Pick<
  InputMultiSelectProps<T>,
  | 'data-testid'
  | 'dropdownInnerWrapperClassName'
  | 'dropdownWrapperClassName'
  | 'id'
  | 'isDisabled'
  | 'isIncomplete'
  | 'isSearchable'
  | 'menuOuterClassName'
  | 'multiSelectDropdownClasses'
  | 'name'
  | 'onChange'
  | 'options'
  | 'placeholder'
  | 'selectLabelMaxLength'
  | 'selectedItemClassName'
  | 'selectedItemLabelClassName'
  | 'shouldRenderInsidePortal'
  | 'size'
  | 'value'
>;

const getDefaultInitialSelectedItems = (value: unknown) => (typeof value === 'string' && !!value ? [value] : []);

const isEnterKey = (event: KeyboardEvent) => event.key === 'Enter';

const isArrayOfStrings = (array: readonly unknown[]): array is string[] => array.every(isString);

export const InputMultiSelectInner = <T = string,>({
  'data-testid': dataTestId = 'multiselect-dropdown',
  dropdownInnerWrapperClassName = '',
  dropdownWrapperClassName = '',
  isDisabled,
  isIncomplete,
  isSearchable,
  menuOuterClassName = '',
  multiSelectDropdownClasses = '',
  name = 'multiselect',
  onChange,
  options,
  placeholder,
  selectLabelMaxLength = SELECT_LABEL_MAX_LENGTH,
  selectedItemClassName = '',
  selectedItemLabelClassName = '',
  shouldRenderInsidePortal,
  size = 'md',
  value = [],
}: InputMultiSelectInnerProps<T>) => {
  const [inputValue, setInputValue] = useState<string | undefined>('');
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection<T>({
    initialSelectedItems: Array.isArray(value) ? value : (getDefaultInitialSelectedItems(value) as T[]),
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(selectedItems, name);
    },
  });

  const getFilteredItems = (items: readonly string[] | readonly UISelectionType<T>[]): UISelectionType<T>[] => {
    const result = items.filter((item) => {
      if (isArrayOfStrings(selectedItems) && isString(item)) {
        return (
          selectedItems.indexOf(item) < 0 &&
          String(item ?? '')
            .toLowerCase()
            .includes(String(inputValue ?? '').toLowerCase())
        );
      }

      if (!isString(item)) {
        const isNotFound = !selectedItems.some((val) => val === item.value);

        return (
          isNotFound &&
          String(item.name ?? '')
            .toLowerCase()
            .includes(String(inputValue ?? '').toLowerCase())
        );
      }

      return true;
    });

    return result.map((item) => (isString(item) ? { name: item, value: item } : item)) as UISelectionType<T>[];
  };

  const anchorRef = useRef(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    toggleMenu,
  } = useCombobox<UISelectionType<T> | null>({
    items: getFilteredItems(options),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem?.value) {
            setInputValue('');
            addSelectedItem(selectedItem.value);
            selectItem(null);
          }

          break;
        default:
          break;
      }
    },
  });

  const isOther = useMemo(() => isArrayOfStrings(selectedItems) && selectedItems?.includes('Other'), [selectedItems]);

  const handleRemoveSelectedItem = (selectedItem: T) => {
    removeSelectedItem(selectedItem);

    if (Array.isArray(selectedItems) && selectedItems.length === 1 && selectedItems.indexOf(selectedItem) !== -1) {
      onChange(undefined, name);
    }
  };

  const isFull = getFilteredItems(options).length === 0;
  const isDropdownOpen = isOpen && !isOther;

  useDidUpdateEffect(() => {
    if (value === '' && selectedItems.length === 0) {
      return;
    }

    if (!isEqual(value, selectedItems)) {
      if (isOther && isArrayOfStrings(selectedItems)) {
        // @ts-expect-error
        setSelectedItems(['Other']);
      }
    }
  }, [selectedItems, value]);

  const handleRemoveItem = (event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>, selectedItem: T) => {
    if (!isDisabled) {
      event.stopPropagation();
      handleRemoveSelectedItem(selectedItem);
    }
  };

  const shouldVirtualize = options.length > 100;

  return (
    <div className={clsx('relative', dropdownWrapperClassName)}>
      <div
        data-testid={dataTestId}
        className={clsx(
          inputClasses({
            isDisabled,
            isIncomplete,
            isOther,
            size,
          }),
          multiSelectDropdownClasses,
        )}
        {...getToggleButtonProps({ disabled: isDisabled })}
        tabIndex={0}
        ref={anchorRef}
      >
        <div className={clsx('multi-select-wrapper flex', dropdownInnerWrapperClassName)}>
          {selectedItems.map((selectedItem, index) => (
            <div className={clsx(selectedItemStyle, selectedItemClassName)} key={`selected-item-${selectedItem}`}>
              <span
                {...getSelectedItemProps({ selectedItem, index })}
                title={selectedItem}
                className={clsx(selectedItemLabelClassName)}
              >
                {formatItemLabel(selectedItem, selectLabelMaxLength, options)}
              </span>
              <span
                data-testid={`Remove-${selectedItem}`}
                className={clsx(
                  `rounded-full px-1`,
                  isDisabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-info-400',
                )}
                onClick={(ev) => handleRemoveItem(ev, selectedItem)}
                onKeyDown={(ev) => (isEnterKey(ev) ? handleRemoveItem(ev, selectedItem) : undefined)}
              >
                &#10005;
              </span>
            </div>
          ))}
          {selectedItems.length === 0 && (
            <label
              className="cursor-pointer text-sm font-normal text-info-400"
              onClick={toggleMenu}
              onKeyDown={(ev) => (isEnterKey(ev) ? toggleMenu() : undefined)}
              {...getLabelProps()}
            >
              {placeholder}
            </label>
          )}
        </div>
        <div className={clsx('flex', isOther && 'cursor-not-allowed')}>
          <input className="hidden" {...getInputProps(getDropdownProps({ preventKeyAction: isDropdownOpen }))} />
          {!isDropdownOpen && <Icon name="chevron-down" className="ml-2 self-center fill-current text-info-800" />}
          {isDropdownOpen && <Icon name="chevron-up" className="ml-2 self-center fill-current text-primary-700" />}
        </div>
      </div>
      <div {...getMenuProps()}>
        {isDropdownOpen && (
          <InputSelectMenuContainer
            anchorRef={anchorRef}
            className={clsx(inputSelectStyles.menuOuter, '!mt-0', menuOuterClassName)}
            offsetY={4}
            shouldRenderInsidePortal={shouldRenderInsidePortal}
          >
            <div>
              {isSearchable && (
                <input autoFocus className={inputSelectStyles.input} placeholder="Search..." {...getInputProps()} />
              )}
              <ShowFirstMatching>
                <Show when={shouldVirtualize}>
                  <VirtualizedList<UISelectionType<T>>
                    data={getFilteredItems(options)}
                    itemHeight={36}
                    height={MAX_DROPDOWN_HEIGHT_PX}
                    renderItem={(item) => (
                      <li
                        {...getItemProps({ item, index: item.index })}
                        data-testid={item.value}
                        className={clsx(inputSelectStyles.menuItem, item.index === highlightedIndex && 'bg-info-100')}
                        key={item.value}
                      >
                        <span className="truncate">{item.name}</span>
                      </li>
                    )}
                  />
                  <Show when={isFull}>
                    <li className={clsx(inputSelectStyles.menuItem, 'italic opacity-60')}>No options left</li>
                  </Show>
                </Show>
                <Show when={!shouldVirtualize}>
                  <ul
                    className={inputSelectStyles.menuInner}
                    style={{
                      maxHeight: MAX_DROPDOWN_HEIGHT_PX,
                    }}
                  >
                    {getFilteredItems(options).map((item, index) => (
                      <li
                        data-testid={item.value}
                        className={clsx(inputSelectStyles.menuItem, index === highlightedIndex && 'bg-info-100')}
                        {...getItemProps({ item, index })}
                        key={`${item.value}`}
                      >
                        <span className="truncate">{item.name}</span>
                      </li>
                    ))}
                    <Show when={isFull}>
                      <li className={clsx(inputSelectStyles.menuItem, 'italic opacity-60')}>No options left</li>
                    </Show>
                  </ul>
                </Show>
              </ShowFirstMatching>
            </div>
          </InputSelectMenuContainer>
        )}
      </div>
    </div>
  );
};
