import { CognitoUserStatus } from '@root/@types/cognito';

import type { CanonicalUserRecord } from './types';
import { UserLifeCycleStatus } from './types';

export function getUserLifeCycleStatus(
  user: Pick<CanonicalUserRecord, 'status' | 'enabled' | 'isDraftUserRecord'>,
): UserLifeCycleStatus {
  if (
    user.enabled &&
    [CognitoUserStatus.Confirmed, CognitoUserStatus.ExternalProvider].includes(user.status as CognitoUserStatus) &&
    !user.isDraftUserRecord
  ) {
    return UserLifeCycleStatus.Active;
  }

  if (user.status === CognitoUserStatus.ForceChangePassword || user.isDraftUserRecord) {
    return UserLifeCycleStatus.Pending;
  }

  if (user.enabled === false) {
    return UserLifeCycleStatus.Disabled;
  }

  if (user.status === CognitoUserStatus.ResetRequired) {
    return UserLifeCycleStatus.ResetRequired;
  }

  return UserLifeCycleStatus.Disabled;
}
