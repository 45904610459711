import type { FC } from 'react';
import Form from '@components/Form';
import { Show } from '@components/Show';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit/store';
import { isBlocksPath, isClausesPath, isEndorsementPath, isTemplatePath } from '@utils/app-paths';

import { DrawerBlockName } from '../components/DrawerBlockName';

import { DrawerPanelActions } from './DrawerPanelActions';
import { DrawerPanelDetails } from './DrawerPanelDetails';
import { DrawerPanelPermissions } from './DrawerPanelPermissions';
import { DrawerPanelSettings } from './DrawerPanelSettings';

export interface BlockConfigurationPanelProps {
  onCancel?: () => void;
}

export const BlockConfigurationPanel: FC<BlockConfigurationPanelProps> = ({ onCancel }) => {
  const isEndorsement = isEndorsementPath();
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isClause = isClausesPath();

  const isAdminView = isTemplate || isBlock || isClause;
  const editingBlockId = useBlockEditFormStore(({ formValues }) => formValues?.id);

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden border-l bg-white p-5">
      <Form key={editingBlockId}>
        <DrawerBlockName className="-mx-7" />
        <Show when={!isEndorsement}>
          <DrawerPanelDetails />
        </Show>
        <DrawerPanelSettings />
        <Show when={isAdminView}>
          <DrawerPanelPermissions />
        </Show>
        <DrawerPanelActions onCancel={onCancel} />
      </Form>
    </div>
  );
};
