import { StatusPill } from '@pages/User/components/StatusPill';
import { isBlockInactive } from '@root/helpers/blocks';
import type { CellContext, ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const statusAccessor = <T extends { deleted_at?: string; inception_date?: string; expiry_date?: string }>(
  columnHelper: ColumnHelper<T>,
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.accessor((row) => row.deleted_at, {
    header: 'Status',
    id: 'deleted_at',
    filterFn: (row, _, allowedStatuses) => {
      const { deleted_at, inception_date, expiry_date } = row.original;

      if (!allowedStatuses.length) {
        return true;
      }

      const isArchived = !!deleted_at;
      const isInactive = isBlockInactive(inception_date, expiry_date);

      return allowedStatuses.includes(isArchived ? 'Archived' : isInactive ? 'Inactive' : 'Active');
    },
    cell: (cellContext: CellContext<T, string>) => {
      const { deleted_at, inception_date, expiry_date } = cellContext.row.original;
      const isInactive = isBlockInactive(inception_date, expiry_date);
      return (
        <StatusPill
          content={deleted_at ? 'Archived' : isInactive ? 'Inactive' : 'Active'}
          variant={deleted_at ? 'archived' : isInactive ? 'inactive' : 'active'}
        />
      );
    },
    sortingFn: (a, b) => {
      const aDeletedAt = a.original.deleted_at;
      const bDeletedAt = b.original.deleted_at;

      if (aDeletedAt && !bDeletedAt) {
        return -1;
      }

      if (!aDeletedAt && bDeletedAt) {
        return 1;
      }

      return 0;
    },
    meta: {
      filter: {
        type: 'dropdown',
        label: 'Status',
        options: ['Active', 'Inactive', 'Archived'],
        ...filterMeta,
      },
      headingCellClassName: 'w-32',
    },
  });
