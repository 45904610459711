import dayjs from 'dayjs';

export const isBlockExpired = (expiryDate?: string) => {
  if (!expiryDate) {
    return false;
  }

  const today = dayjs();
  return today.isAfter(expiryDate, 'day') || today.isSame(expiryDate, 'day');
};
