import { type ChangeEvent, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import InputText from '@components/InputText';
import Modal from '@components/Modal';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useBlockLibrary } from '@hooks/useBlockLibrary';
import { twMerge } from 'tailwind-merge';

interface SaveAsNewChildBlockModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: (name: string) => void;
}

export const SaveAsNewChildBlockModal = ({ isOpen, isLoading, onClose, onConfirm }: SaveAsNewChildBlockModalProps) => {
  const { formValues, currentBlock } = useBlockEditFormStore(({ formValues, currentBlock }) => ({
    formValues,
    currentBlock,
  }));

  const [name, setName] = useState(formValues?.name ?? '');

  const { data: parentBlock, isFetching } = useBlockLibrary(
    currentBlock?.parentBlockId ?? '',
    currentBlock?.parentBlockId !== undefined && isOpen,
  );

  if (
    currentBlock === undefined ||
    (currentBlock?.parentBlockId !== undefined && parentBlock === undefined) ||
    isFetching
  ) {
    return null;
  }

  const handleChangeChildBlockName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);

  return (
    <Modal
      title="Save as new child block"
      description={
        <p className="mx-auto max-w-[348px] text-left">
          This will create a new child version of the original parent block, grouped together in the block library
        </p>
      }
      onClose={onClose}
      open={isOpen}
      className={twMerge('w-full max-w-[512px] !py-10', MODAL_Z_INDEX)}
      titleClassName="px-6 !block"
      descriptionClassName="text-center p-2"
      data-testid="save-as-child-block-modal"
      shouldShowCloseIcon={false}
    >
      <div className="mt-8 flex flex-col gap-10 px-7">
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-1">
            <Icon name="block-parent" aria-hidden="true" className="h-5 shrink-0 text-gray-400" />
            <span className="text-sm font-medium text-gray-500 hover:text-gray-700">
              {parentBlock?.name ?? currentBlock?.name}
            </span>
          </div>
          <div className="flex items-center">
            <InputText
              labelText="Name child block"
              name="child-block-name"
              onChange={handleChangeChildBlockName}
              value={name}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Button size="sm" kind="secondary" className="mr-4 h-9 w-full" isDisabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" kind="primary" loading={isLoading} className="h-9 w-full" onClick={() => onConfirm(name)}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
