import type { FC } from 'react';
import { FeatureIndicatorIcon } from '@components/FeatureIndicatorIcon';
import { getBlockExpiryDateTooltip } from '@ContractBuilder/modules/block-configuration-panel/utils/block-expiry-date';
import { mdiAlert } from '@mdi/js';

interface BlockExpiryDateIndicatorProps {
  expiryDate?: string;
  isBlock: boolean;
}

export const BlockExpiryDateIndicator: FC<BlockExpiryDateIndicatorProps> = ({ expiryDate, isBlock }) => {
  if (!expiryDate) return null;

  const { isExpired, tooltip } = getBlockExpiryDateTooltip(expiryDate, isBlock);

  return (
    <FeatureIndicatorIcon
      placement="top"
      path={mdiAlert}
      content={tooltip}
      className={isExpired ? 'text-error-900' : ''}
      data-testid="block-icon-indicator:expiry-date"
    />
  );
};
