import type { PartitionKey } from '@backend/types';
import { z } from 'zod';

export const userSchema = z.object({
  email: z.string(),
  id: z.string(),
  name: z.string(),
  role: z.enum(['admin', 'user', 'superadmin', 'template_manager']),
  tenant_id: z.string().uuid(),
  teams: z.string().optional(),
});

export const baseAuthorizerSchema = z.object({
  sub: z.string(),
  email: z.string().email(),
  given_name: z.string(),
  family_name: z.string(),
  'custom:role': z.enum(['admin', 'user', 'superadmin', 'template_manager']),
  'custom:tenant_id': z.string().uuid(),
  'custom:tenant_ids': z.string().optional(), // TODO: confirm if this is needed for PRO clients
});

export const pplAuthorizerSchema = baseAuthorizerSchema.extend({
  session_id: z.string(),
  alias_id: z.string(),
  company_name: z.string(),
  organisation_name: z.string(),
  organisation_id: z.string(),
  access_token: z.string().optional(), // TODO: should be mandatory with min length
  correlation_id: z.string().optional(),
});

export const partitionKeySchema = z.union([
  z.literal('submissions'),
  z.literal('templates'),
  z.literal('endorsements'),
  z.literal('brandings'),
  z.literal('blocks'),
  z.literal('users'),
  z.literal('tags'),
  z.string().regex(/^submissions#.+$/),
  z.string().regex(/^templates#.+$/),
  z.string().regex(/^endorsements#.+$/),
]);

export const baseDatabaseSchema = z.object({
  created_at: z.string(),
  created_by: userSchema.partial({ tenant_id: true }),
  deleted_at: z.nullable(z.string()).optional(),
  deleted_by: userSchema.partial({ tenant_id: true }).optional(),
  id: z.string(),
  last_updated_by: userSchema.partial({ tenant_id: true }).optional(),
  pk: z.string(),
  sk: z.string(),
  updated_at: z.string().optional(),
  update_type: z.string().optional(),
});

export const tenantNameSchema = z.enum(['ct-ppl', 'ct-edbroking', 'ct-lockton', 'ct-bms', 'ct-ajg', 'ct-howden']);

export type PK = { pk: PartitionKey };
export type SK = { sk: string };
export type TenantName = z.infer<typeof tenantNameSchema>;
export type User = z.infer<typeof userSchema>;
export type DbResource = z.infer<typeof baseDatabaseSchema>;
export type BaseAuthorizer = z.infer<typeof baseAuthorizerSchema>;
export type PPLAuthorizer = z.infer<typeof pplAuthorizerSchema>;
