import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { useCurrentContractSections } from '@hooks/useCurrentContractSections';

import type { StateValue } from '../types';
import { getInitialState } from '../utils/getInitialState';

import { ActiveSectionContext, SectionsVisibilityContext } from './context';

export const ContractViewContentProvider: FC<PropsWithChildren> = ({ children }) => {
  const submission = useEntityStore((state) => state.submission);
  const [sections, setSections] = useState<StateValue>(() => getInitialState(submission));

  const submissionSections = useCurrentContractSections();
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);

  const activeSectionId = useDeepCompareMemo(() => {
    const defaultSectionId = submissionSections[0]?.id;

    if (editingBlockId) {
      return (
        submissionSections.find(({ blocks }) => blocks.some(({ id }) => id === editingBlockId))?.id ?? defaultSectionId
      );
    }

    return submissionSections.find(({ id }) => sections[id])?.id ?? defaultSectionId;
  }, [editingBlockId, sections, submissionSections]);

  const onViewChange = useCallback((inView: boolean, _entry: IntersectionObserverEntry, sectionId: string) => {
    setSections((prev) => ({ ...prev, [sectionId]: inView }));
  }, []);

  const sectionsVisibilityValue = useMemo(
    () => ({
      onViewChange,
    }),
    [onViewChange],
  );

  const activeSectionValue = useMemo(
    () => ({
      activeSectionId,
      sections,
    }),
    [activeSectionId, sections],
  );

  return (
    <SectionsVisibilityContext.Provider value={sectionsVisibilityValue}>
      <ActiveSectionContext.Provider value={activeSectionValue}>{children}</ActiveSectionContext.Provider>
    </SectionsVisibilityContext.Provider>
  );
};
