import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiAlert } from '@mdi/js';

import { DrawerSection } from '../components/DrawerSection';

interface DrawerBlockStatusProps {
  isBlock: boolean;
  isExpired: boolean;
}

export const DrawerBlockStatus: FC<DrawerBlockStatusProps> = ({ isBlock, isExpired }) => {
  if (!isExpired) {
    return null;
  }

  return (
    <DrawerSection title={`${isBlock ? 'Block' : 'Clause'} status`}>
      <div className="flex cursor-default items-start gap-1 text-sm text-error-900">
        <IconMdi centerVertical={false} path={mdiAlert} />
        {isBlock ? 'Block is archived' : 'Clause is archived'}
      </div>
    </DrawerSection>
  );
};
