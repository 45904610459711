import type { FC } from 'react';
import { Fragment, memo } from 'react';
import Button from '@components/Button';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { Dialog, Transition } from '@headlessui/react';
import { authProvider } from '@helpers/auth';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import { mdiOpenInNew } from '@mdi/js';
import { Icon } from '@mdi/react';
import clsx from 'clsx';
import { isEqual } from 'lodash-es';

interface BlockUsedInTemplatesModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const getTemplatesUrl = () => {
  const href = typeof window === 'undefined' ? '' : window?.location?.href;
  return href.substring(0, href.indexOf('/templates/')) + '/templates';
};

interface BlockUsedWithinProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  templateId?: string;
  message: string;
}

export const BlockUsedWithin: FC<BlockUsedWithinProps> = memo(function BlockUsedWithin({
  isOpen,
  onCancel,
  onSubmit,
  templateId = '',
  message,
}) {
  const templatesUrl = getTemplatesUrl();
  const { usedInTemplates } = useBlockEditFormStore(({ formValues }) => ({
    usedInTemplates: formValues?.usedInTemplates ?? [],
  }));
  const { isLoading } = useEntityStore(({ isLoading }) => ({ isLoading }));

  const handleLinkClick = async (templateId: string) => {
    const searchParams = await authProvider.getAuthSearchParams();
    const win = window.open(`${templatesUrl}/${templateId}/contract${searchParams}`);
    return win?.focus();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className={clsx('fixed inset-0', MODAL_Z_INDEX)} onClose={onCancel}>
        <div
          className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
          data-testid="block-used-in-templates-modal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={onCancel}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-error-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-error-600" aria-hidden="true" />
                </div>
                <div className="mt-3 flex flex-col gap-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    {message}
                  </Dialog.Title>
                  <ul role="list" className="flex h-96 flex-col gap-5 overflow-y-auto">
                    {usedInTemplates
                      ?.filter(({ id }) => id !== templateId)
                      ?.map(({ id, name }) => (
                        <li key={id} className="group relative flex justify-between bg-white pl-0 pr-8">
                          <span
                            className="mr-1 inline-block max-w-[220px] cursor-pointer truncate text-sm font-medium leading-[17px] text-primary-500 hover:underline group-hover:text-primary-600"
                            onClick={() => handleLinkClick(id)}
                          >
                            {name}
                          </span>
                          <span className="relative">
                            <Icon
                              className="absolute top-[-1px] inline-block text-primary-500 group-hover:text-primary-600"
                              path={mdiOpenInNew}
                              size={0.7}
                            />
                          </span>
                        </li>
                      ))}
                  </ul>
                  <p className="text-sm text-info-500">Are you sure you want to continue?</p>
                </div>
              </div>
              <div className="mt-5 items-center justify-end gap-x-2.5 sm:mt-4 sm:flex">
                <Button
                  className="h-auto px-4 py-2"
                  onClick={onCancel}
                  kind="secondary"
                  size="sm"
                  isDisabled={isLoading}
                >
                  Cancel
                </Button>
                <Button className="h-auto px-4 py-2" onClick={onSubmit} kind="danger" size="sm" loading={isLoading}>
                  Continue
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}, isEqual);

export const BlockUsedInTemplatesModal: FC<BlockUsedInTemplatesModalProps> = ({ isOpen, onCancel }) => {
  const { submissionId } = useEntityStore(({ submission }) => ({ submissionId: submission?.id ?? '' }));
  const { onSubmit } = useBlockEdit();
  const isClause = useBlockEditFormStore(({ formValues }) => formValues?.type === 'clause');

  return (
    <BlockUsedWithin
      message={`This ${isClause ? 'clause' : 'block'} will also be updated on these templates`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      templateId={submissionId}
    />
  );
};
