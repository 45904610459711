import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdate } from 'react-use';
import {
  mdiFormatAlignCenter,
  mdiFormatAlignJustify,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatBold,
  mdiFormatColorText,
  mdiFormatHeader1,
  mdiFormatIndentDecrease,
  mdiFormatIndentIncrease,
  mdiFormatItalic,
  mdiFormatLetterCaseLower,
  mdiFormatLetterCaseUpper,
  mdiFormatListBulleted,
  mdiFormatSize,
  mdiFormatStrikethroughVariant,
  mdiFormatSubscript,
  mdiFormatSuperscript,
  mdiFormatUnderline,
  mdiRedo,
  mdiTableCog,
  mdiUndo,
} from '@mdi/js';
import type { Editor } from '@tiptap/react';
import { isLowerCaseMarkActive } from '@WysiwygEditor/components/Controls/utils/isLowerCaseMarkActive';
import clsx from 'clsx';
import { debounce } from 'lodash-es';

import { controlRenderer } from './components/Control';
import { ControlRow } from './components/control-row/ControlRow';
import { ControlRowOptionParent } from './components/control-row/ControlRowOptionParent';
import { ControlBar } from './components/ControlBar';
import { TableControls } from './components/TableControls';
import { getControl } from './utils/control';
import { getFontSizeCtrlOptions } from './utils/font-size-controls';
import { getListControls } from './utils/lists-controls';
import { getSeparator } from './utils/separator';
import { getTableControls } from './utils/table-controls';
import { getHierarchyControlOptions } from './utils/text-hierarchy-controls';
import type { ControlOptions, CustomOptionProps, Option } from './types';

export interface WysiwygControlsProps {
  editor?: any;
  isDisabled?: boolean;
  className?: string;
  datapointsControl?: (editor: Editor) => CustomOptionProps;
}

type ControlRowKey = 'text_decoration' | 'text_alignment' | 'table_controls' | 'text_hierarchy' | 'lists' | 'font_size';

export const WysiwygControls: React.FC<WysiwygControlsProps> = ({
  editor,
  isDisabled = false,
  className,
  datapointsControl,
}) => {
  const [controlRowKey, setControlRowKey] = useState<ControlRowKey | null>(null);

  const rerender = useUpdate();
  const canUndo = editor?.can().undo();
  const canRedo = editor?.can().redo();
  const editorExists = editor && !editor.isDestroyed;

  // eslint-disable-next-line -- Inline function is fine here
  const debouncedRerender = useCallback(debounce(rerender, 200), []);

  useEffect(() => {
    if (editorExists) {
      /** Force `Controls` re-render to avoid stale state when calling `editor.isActive('X')`. **/
      editor.on('transaction', () => {
        debouncedRerender();
      });
    }
  }, [debouncedRerender, editor, editorExists]);

  const isTableActive = editor?.isActive('table');
  const isListActive = editor?.isActive('orderedList') || editor?.isActive('bulletList');

  const toggleControlRowKey = (key: ControlRowKey) => () => {
    setControlRowKey((prev) => (prev === key ? null : key));
  };
  const isControlOpen = useCallback((key: ControlRowKey) => controlRowKey === key, [controlRowKey]);

  useEffect(() => {
    if (editorExists) {
      if (isTableActive && !isControlOpen('table_controls')) {
        setControlRowKey('table_controls');
        return;
      }

      if (isListActive && !isControlOpen('lists')) {
        setControlRowKey('lists');
      }

      if (!isTableActive && !isListActive && isControlOpen('lists')) {
        setControlRowKey(null);
      }

      if (!isTableActive && !isListActive && isControlOpen('table_controls')) {
        setControlRowKey(null);
      }
    }
    // eslint-disable-next-line -- adding isControlOpen breaks features
  }, [isTableActive, isListActive, editorExists]);

  const getEditorControl = useMemo(() => getControl(editor), [editor]);

  const controlRowOptionsMap: Record<ControlRowKey, Option[]> = {
    text_decoration: [
      getEditorControl('toggleStrike', 'strike', mdiFormatStrikethroughVariant, 'Strike-through'),
      getEditorControl('toggleSuperscript', 'superscript', mdiFormatSuperscript, 'Superscript'),
      getEditorControl('toggleSubscript', 'subscript', mdiFormatSubscript, 'Subscript'),
      getEditorControl('toggleUppercase', 'uppercase', mdiFormatLetterCaseUpper, 'Uppercase'),
      getEditorControl('toggleLowercase', isLowerCaseMarkActive, mdiFormatLetterCaseLower, 'Lowercase'),
    ],
    text_alignment: [
      getEditorControl('setTextAlign', { textAlign: 'left' }, mdiFormatAlignLeft, 'Left align', 'left'),
      getEditorControl('setTextAlign', { textAlign: 'center' }, mdiFormatAlignCenter, 'Center align', 'center'),
      getEditorControl('setTextAlign', { textAlign: 'right' }, mdiFormatAlignRight, 'Right align', 'right'),
      getEditorControl('setTextAlign', { textAlign: 'justify' }, mdiFormatAlignJustify, 'Justify', 'justify'),
    ],
    table_controls: getTableControls(editor),
    text_hierarchy: getHierarchyControlOptions(editor, debouncedRerender),
    font_size: getFontSizeCtrlOptions(editor, debouncedRerender),
    lists: getListControls(editor),
  };

  const controls: ControlOptions = [
    getEditorControl('toggleBold', 'bold', mdiFormatBold, 'Bold'),
    getEditorControl('toggleItalic', 'italic', mdiFormatItalic, 'Italic'),
    getEditorControl('toggleUnderline', 'underline', mdiFormatUnderline, 'Underline'),
    {
      key: 'text_decoration',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('text_decoration')}
          isOpen={isControlOpen('text_decoration')}
          isDisabled={isDisabled}
          key="text_decoration"
          icon={mdiFormatColorText}
          title="Text Decorations"
        />
      ),
    },
    {
      key: 'font_size',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('font_size')}
          isOpen={isControlOpen('font_size')}
          isDisabled={isDisabled}
          key="font_size"
          icon={mdiFormatSize}
          title="Font Size"
        />
      ),
    },
    getSeparator('separator-1'),
    {
      key: 'text_hierarchy',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('text_hierarchy')}
          isOpen={isControlOpen('text_hierarchy')}
          isDisabled={isDisabled}
          key="text_hierarchy"
          icon={mdiFormatHeader1}
          title="Text Hierarchy"
        />
      ),
    },

    {
      key: 'text_alignment',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('text_alignment')}
          isOpen={isControlOpen('text_alignment')}
          isDisabled={isDisabled}
          key="text_alignment"
          icon={mdiFormatAlignLeft}
          title="Text Alignment"
        />
      ),
    },

    getSeparator('separator-2'),
    {
      key: 'lists',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('lists')}
          isOpen={isControlOpen('lists')}
          isDisabled={isDisabled}
          key="lists"
          icon={mdiFormatListBulleted}
          title="Lists"
        />
      ),
    },
    getSeparator('separator-3'),
    getEditorControl('indentLeft', false, mdiFormatIndentDecrease, 'Indent left'),
    getEditorControl('indentRight', false, mdiFormatIndentIncrease, 'Indent right'),
    getSeparator('separator-4'),
    {
      key: 'create-table',
      custom: () => <TableControls editor={editor} isActive={isTableActive} isDisabled={isDisabled} key="table" />,
    },
    {
      key: 'table-controls',
      custom: () => (
        <ControlRowOptionParent
          onClick={toggleControlRowKey('table_controls')}
          isOpen={isControlOpen('table_controls')}
          isDisabled={!isTableActive || isDisabled}
          key="table_controls"
          icon={mdiTableCog}
          title="Table Controls"
        />
      ),
    },
    getSeparator('separator-5'),
    getEditorControl('undo', canUndo, mdiUndo, 'Undo'),
    getEditorControl('redo', canRedo, mdiRedo, 'Redo'),
    getSeparator('separator-6'),
    datapointsControl ? datapointsControl(editor) : false,
  ];

  const controlRowOptions = controlRowKey ? controlRowOptionsMap[controlRowKey] : [];

  return (
    <ControlBar showTableControls={isTableActive} className={clsx(className)} isDisabled={isDisabled}>
      {controls.filter(Boolean).map(controlsMapper(isDisabled))}
      <ControlRow options={controlRowOptions} isDisabled={isDisabled} />
    </ControlBar>
  );
};

const controlsMapper = (isDisabled: boolean) => (item: any) => {
  if (isDisabled) {
    item.isDisabled = isDisabled;
  }

  switch (true) {
    case item.custom !== undefined:
      return item.custom();

    default:
      return controlRenderer({ ...item });
  }
};
