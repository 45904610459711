import type { FC } from 'react';
import { Fragment, useEffect, useMemo } from 'react';
import { useSchemaStore } from '@ContractBuilder/store';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import type { JSONSchema, JSONSchemaProperties } from '@root/@types/schema';
import { resolveSchema, SPLIT_BY_SECTIONS_KEY } from '@root/helpers/schema';
import mapChildren from '@root/src/mapChildren';
import { isSkeletonEmpty } from '@root/src/utils/is-skeleton-empty';
import { cloneDeep, get, set } from 'lodash-es';

import ErrorText from '../ErrorText';
import Toggle from '../Toggle';

import CollapsibleValidatedItem from './CollapsibleValidatedItem/CollapsibleValidatedItem';
import type { RepeaterProps } from './Repeater';

interface ToggleableSectionRepeaterProps extends RepeaterProps {
  parentSchema?: JSONSchemaProperties;
  startCollapsed?: boolean;
  toggleDependsOn?: string;
}

function padArrayEnd<ArrayItem>(arr: ArrayItem[], length: number) {
  if (arr.length >= length) {
    return arr.slice(0, length);
  }

  return arr.concat(Array.from({ length: length - arr.length }, () => ({}) as ArrayItem));
}
const ToggleableSectionRepeater: FC<ToggleableSectionRepeaterProps> = ({
  className,
  id,
  isDisabled,
  isReadOnly = false,
  item,
  onChange,
  ownKey,
  parentSchema,
  showQuestionKey,
  toggleDependsOn = '',
  validationErrors,
  startCollapsed = false,
  hideErrors,
}) => {
  const { numberOfSections, setFormValues, toggledItems, value } = useDatapointsStore(
    ({ formValues, setFormValues }) => ({
      numberOfSections: (get(formValues, toggleDependsOn)?.length as any) ?? 0,
      setFormValues,
      toggledItems: get(formValues, SPLIT_BY_SECTIONS_KEY, []) as string[],
      value: get(formValues, ownKey, item.value ?? ''),
    }),
  );
  const { resolvedSchemas } = useSchemaStore(({ resolvedSchemas }) => ({
    resolvedSchemas,
  }));

  const hasBeenToggled = !!toggledItems.find((item) => item === ownKey);
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const hasSections = numberOfSections > 0;
  const isRequired = parentSchema?.required?.includes(item.key);

  const initialItems = hasBeenToggled
    ? padArrayEnd(hasInitialValue ? value : [{}], numberOfSections)
    : hasInitialValue
    ? value
    : [{}];

  const total100Error = validationErrors?.find(
    (error) => error.instancePath === ownKey && error.keyword === 'total100',
  );
  const schemaItem = item.items?.type === 'object' ? item.items : ({ properties: { [id]: item.items } } as any);

  useEffect(() => {
    if (!isReadOnly && numberOfSections && Array.isArray(value) && value.length) {
      setFormValues((prev: any) => {
        const cloned = cloneDeep(prev);
        set(cloned, ownKey, initialItems.slice(0, numberOfSections));
        return cloned;
      });
    }
    // eslint-disable-next-line -- We're only concerned with `numberOfSections` to adjust the corresponding length of each section-based object
  }, [isReadOnly, numberOfSections]);

  const handleSplitBySectionsClick = () => {
    setFormValues((prev: any) => {
      const cloned = cloneDeep(prev);
      const newArrayValue = padArrayEnd(get(cloned, ownKey, []), hasBeenToggled ? 1 : Math.max(numberOfSections, 1));

      set(
        cloned,
        SPLIT_BY_SECTIONS_KEY,
        hasBeenToggled ? toggledItems.filter((item) => item !== ownKey) : [...toggledItems, ownKey],
      );
      set(cloned, ownKey, newArrayValue);

      return cloned;
    });
  };

  const hasChildrenWithErrors = useMemo(() => {
    const isStarted = !isSkeletonEmpty(value);
    return isStarted && !!validationErrors?.find((i) => i.ownKey === ownKey);
  }, [value, validationErrors, ownKey]);

  return (
    <CollapsibleValidatedItem
      className={className}
      id={id}
      isRequired={isRequired || hasChildrenWithErrors}
      ownKey={ownKey}
      startCollapsed={startCollapsed}
      title={item.title}
      helperText={item.helperText}
    >
      <div className="flex w-full justify-end">
        {!isReadOnly && hasSections && (
          <Toggle
            className="mb-4 text-xs font-semibold text-info-400"
            value={hasBeenToggled}
            onClick={handleSplitBySectionsClick}
            labelText="Split by sections"
            isDisabled={isDisabled}
            data-testid={`${ownKey}-split-by-sections-toggle`}
          />
        )}
      </div>
      {initialItems.map((_lineItem, index) => {
        const key = `${ownKey}[${index}]`;
        const pathToSubSchema = ownKey.replace(/(\w+)\.(\w+)/, 'properties.$1.properties.$2.items');
        const resolvedSchemaForSection = resolvedSchemas && resolvedSchemas[index];
        const subSchema = cloneDeep(get(resolvedSchemaForSection, pathToSubSchema));
        resolveSchema(subSchema, get(value, `[${index}]`));

        return (
          <Fragment key={key}>
            {hasBeenToggled && (
              <div className="py-4 font-medium" key={`title_${index}`}>
                {schemaItem?.title} #{index + 1 + ' '}
              </div>
            )}
            <div key={index}>
              {mapChildren({
                onChange,
                parentKey: key,
                parentSchema: subSchema as JSONSchema,
                validationErrors,
                showQuestionKey,
                isReadOnly,
                isDisabled,
                hideErrors,
              })}
            </div>
          </Fragment>
        );
      })}
      {total100Error && <ErrorText className="mb-4">{total100Error.message}</ErrorText>}
    </CollapsibleValidatedItem>
  );
};

export default ToggleableSectionRepeater;
