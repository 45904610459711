import { useMemo } from 'react';
import { VisibilityReasons } from '@ContractBuilder/modules/block/components/VisibilityReasons';
import { useEntityStore } from '@ContractBuilder/store';
import { canChange } from '@helpers/canChange';
import { mdiCheckCircle, mdiCheckCircleOutline } from '@mdi/js';
import type { BlockVariation } from '@root/@types/database';
import { useDeepCompareCallback } from '@src/hooks';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';

import { useVariationsTabsLite } from '../context/context-lite';

import { TabViewLite } from './TabViewLite';

export interface VariationTabLiteProps {
  variation: BlockVariation;
}

export const VariationTabLite = ({ variation: { id, name = '' } }: VariationTabLiteProps) => {
  const { selectedVariationId, variationInView, visibilityDetails, hasLogic } = useVariationsTabsLite();
  const submission = useEntityStore((store) => store.submission);
  const canEdit = canChange(submission?.status);

  const variationDetails = useMemo(() => visibilityDetails?.find((v) => v.id === id), [visibilityDetails, id]);

  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath() || isClausesPath();
  const isTabOpen = variationInView ? id === variationInView : false;
  const isSelected = id === selectedVariationId;
  const showSelectIcon = !hasLogic && !isTemplate && !isBlock && (isTabOpen || isSelected) && canEdit;
  const icon = isSelected ? mdiCheckCircle : mdiCheckCircleOutline;
  const tabAutoSelected = isSelected && hasLogic;

  const tab = useDeepCompareCallback(
    ({ onClick }: { onClick?: any }) => (
      <TabViewLite
        id={id}
        name={name}
        isTabOpen={isTabOpen}
        isSelected={isSelected}
        showSelectIcon={showSelectIcon}
        icon={icon}
        tabAutoSelected={tabAutoSelected}
        onClick={onClick}
        tabHasLogicDefined={!!variationDetails}
      />
    ),
    [id, name, isTabOpen, isSelected, showSelectIcon, icon, tabAutoSelected, variationDetails],
  );

  return <VisibilityReasons visibilityDetails={variationDetails} trigger={tab} mode="variation" />;
};
