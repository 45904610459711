import { Show } from '@components/Show';
import { useBlockEdit, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { RestoreEndorsementTemplateBannerController } from '@features/restore-endorsement-template-banner';
import { useCurrentContractSections } from '@hooks/useCurrentContractSections';
import { useDocumentLocation } from '@hooks/useDocumentLocation';
import { isNonNullish } from '@root/helpers';

import { contractViewClasses, contractViewEditingClasses, contractViewEditingOverlayClasses } from '../classes';
import { EditorFooter } from '../components/EditorFooter';
import { EditorToolbar } from '../components/EditorToolbar';
import { EmptyTemplatePlaceholder } from '../components/EmptyTemplatePlaceholder';

import { Attachments } from './Attachments';
import { ContractViewSections } from './ContractViewSections';

export const ContractViewContent = () => {
  const { onCancel } = useBlockEdit();

  const isEditing = useBlockEditFormStore((state) => isNonNullish(state.formValues?.id));
  const { isEndorsementView } = useDocumentLocation();

  const showHiddenFields = useUIStore(({ showHiddenFields }) => showHiddenFields);
  const submissionSections = useCurrentContractSections();

  return (
    <>
      <EditorToolbar isEditing={isEditing} isEndorsementView={isEndorsementView} />
      <div
        className={contractViewClasses({ isEditing, showHiddenFields })}
        id="contract-view-content"
        onClick={() => onCancel()}
      >
        <EmptyTemplatePlaceholder submissionSections={submissionSections} />
        <Show when={isEditing}>
          <div id="contract-view-edit-mode-overlay" className={contractViewEditingOverlayClasses()} />
        </Show>
        <div className={contractViewEditingClasses({ isEditing })}>
          <Show when={isEndorsementView}>
            <RestoreEndorsementTemplateBannerController />
          </Show>
          <ContractViewSections sections={submissionSections} />
          <Attachments />
        </div>
      </div>
      <EditorFooter isEditing={isEditing} />
    </>
  );
};
