import { generatePath, Link } from 'react-router-dom';
import Tooltip from '@components/Tooltip';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import type { BaseUser } from '@root/@types/types';
import { isSuperadmin } from '@root/helpers/permissions';
import type Nav from '@root/src/routes';
import sharedClasses from '@root/src/utils/shared-classes';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';

export const editDisplay = <T extends LibraryBlockTypeForListPage>(
  columnHelper: ColumnHelper<T>,
  path: Nav.Block | Nav.Clause,
  user?: BaseUser | null,
) =>
  columnHelper.display({
    id: 'edit',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<T, unknown>) => {
      const { canEditOnTemplate, id } = cellContext.row.original;
      const isUserSuperAdmin = user && isSuperadmin(user);

      const isDisabled = !canEditOnTemplate && !isUserSuperAdmin;
      return (
        <Tooltip content="This block can only by edited by a superadmin" disabled={!isDisabled}>
          <div className="flex items-center justify-center pt-4">
            <Link
              className={clsx(
                'cursor-pointer truncate whitespace-nowrap px-2 pl-3 text-sm font-medium text-primary-600 shadow-none transition-colors hover:text-primary-900 disabled:cursor-not-allowed disabled:opacity-50',
                isDisabled && sharedClasses.disabled,
              )}
              aria-disabled={isDisabled}
              to={generatePath(path, { id })}
            >
              Edit
            </Link>
          </div>
        </Tooltip>
      );
    },
    meta: {
      dataCellClassName:
        'relative !w-20 truncate whitespace-nowrap pl-3 pr-4 !py-0 text-right align-top text-sm font-medium',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  });
