import { type FC } from 'react';
import { Show } from '@components/Show';
import type { BlockVariation } from '@root/@types/database';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';

import { useBlockEditFormStore } from '../../block-edit';
import { NewTabButton } from '../components/NewTabButton';
import { VariationTab } from '../components/VariationTab';

interface VariationsTabsViewProps {
  className?: string;
}

export const VariationsTabsView: FC<VariationsTabsViewProps> = ({ className }) => {
  const block = useBlockEditFormStore((state) => state.formValues);
  const { variations = [] } = block || {};

  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath() || isClausesPath();
  const dataTestId = getDataTestId('variations.tabsRow', block?.name);

  if (!variations?.length) {
    return null;
  }

  return (
    <div className={clsx('shadow', className)} data-testid={dataTestId}>
      <div className="flex bg-info-200">
        <div className="min-w-0">
          <div className="flex overflow-x-auto">
            {variations.map((variation: BlockVariation) => (
              <VariationTab key={variation.id} variation={variation} />
            ))}
          </div>
        </div>
        <Show when={isTemplate || isBlock}>
          <NewTabButton />
        </Show>
      </div>
    </div>
  );
};
