import { createBrowserRouter, createRoutesFromChildren, Navigate, Route } from 'react-router-dom';
import { AdminRoutes } from '@Auth/AdminRoutes';
import ForgotPassword from '@Auth/ForgotPassword';
import Login from '@Auth/Login';
import Logout from '@Auth/Logout';
import PasswordForm from '@Auth/PasswordForm';
import { PrivateRoutes } from '@Auth/PrivateRoutes';
import { PublicRoutes } from '@Auth/PublicRoutes';
import { RedirectSubmissionsLegacy } from '@components/RedirectSubmissionsLegacy';
import { ErrorController } from '@features/error';
import ContractBuilderPage from '@pages/ContractBuilder';
import { SentryErrorElement } from '@pages/ErrorPage';
import {
  UserBlocksPage,
  UserBrandingPage,
  UserClausesPage,
  UserMrcExtractionPage,
  UserSubmissionsPage,
  UserTagsPage,
  UserTemplatesPage,
  UserUsersPage,
} from '@pages/index';
import { UserSingleBlockPage } from '@pages/User/BlockEdit';
import { wrapCreateBrowserRouterV6 } from '@sentry/react';

import Nav from '../routes';

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter);

export const getAppRouter = (isUsersEnabled?: boolean, isBrandingEnabled?: boolean) =>
  sentryCreateBrowserRouter(
    createRoutesFromChildren(
      <Route errorElement={<SentryErrorElement />}>
        {/* PUBLIC */}
        <Route element={<PublicRoutes />}>
          <Route path={Nav.Register} element={<PasswordForm />} />
          <Route path={Nav.Login} element={<Login />} />
          <Route path={Nav.Auth} element={<Login />} />
          <Route path={Nav.Logout} element={<Logout />} />
          <Route path={Nav.ForgotPassword} element={<ForgotPassword />} />
          <Route path={Nav.ResetPassword} element={<PasswordForm />} />
          <Route path="/404" element={<ErrorController type="404" />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        {/* PRIVATE */}
        <Route element={<PrivateRoutes />}>
          {/* WITH MENU */}
          <Route path={Nav.SubmissionsLegacy} element={<RedirectSubmissionsLegacy />} />
          <Route path={Nav.Submissions} element={<UserSubmissionsPage />} />
          <Route path={Nav.MrcExtraction} element={<UserMrcExtractionPage />} />
          <Route element={<AdminRoutes />}>
            <Route path={Nav.Block} element={<UserSingleBlockPage />} />
            <Route path={Nav.Templates} element={<UserTemplatesPage />} />
            <Route path={Nav.Blocks} element={<UserBlocksPage />} />
            <Route path={Nav.Clause} element={<UserSingleBlockPage />} />
            <Route path={Nav.Clauses} element={<UserClausesPage />} />
            <Route path={Nav.Tags} element={<UserTagsPage />} />
            {isUsersEnabled && <Route path={Nav.Users} element={<UserUsersPage />} />}
            {isBrandingEnabled && <Route path={Nav.Branding + '/*'} element={<UserBrandingPage />} />}
          </Route>
          {/* WITH MENU END */}
          {/* CONTRACT BUILDER */}
          <Route path={Nav.Submission} element={<ContractBuilderPage />} />
          <Route path={Nav.Template} element={<ContractBuilderPage />} />
          <Route path={Nav.Endorsement} element={<ContractBuilderPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        {/* Handlers */}
        <Route path="/" element={<Navigate to={Nav.Submissions} />} />
      </Route>,
    ),
  );
