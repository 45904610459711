import type { MouseEventHandler } from 'react';
import React from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import { teamsAccessor } from '@pages/User/helpers/columns/shared/teamsAccessor';
import type { Team, UserRole } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import type { CanonicalUserRecordWithCapabilities } from '@root/helpers/user-management/types';
import { UserLifeCycleStatus } from '@root/helpers/user-management/types';
import language from '@src/language/language';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<CanonicalUserRecordWithCapabilities>();

const USER_LIFE_CYCLE_STATUSES = [
  {
    value: UserLifeCycleStatus.Active,
    label: 'Active',
    labelClassName: 'inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800',
    predicate: (user: CanonicalUserRecordWithCapabilities) =>
      user.capabilitiesManager.userLifeCycleStatus === UserLifeCycleStatus.Active,
  },
  {
    value: UserLifeCycleStatus.Pending,
    label: 'Pending',
    labelClassName: 'inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800',
    predicate: (user: CanonicalUserRecordWithCapabilities) =>
      user.capabilitiesManager.userLifeCycleStatus === UserLifeCycleStatus.Pending,
  },
  {
    value: UserLifeCycleStatus.Disabled,
    label: 'Disabled',
    labelClassName: 'inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800',
    predicate: (user: CanonicalUserRecordWithCapabilities) =>
      user.capabilitiesManager.userLifeCycleStatus === UserLifeCycleStatus.Disabled,
  },
  {
    value: UserLifeCycleStatus.ResetRequired,
    label: 'Reset required',
    labelClassName: 'inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800',
    predicate: (user: CanonicalUserRecordWithCapabilities) =>
      user.capabilitiesManager.userLifeCycleStatus === UserLifeCycleStatus.ResetRequired,
  },
];

const getStatusLabel = (user: CanonicalUserRecordWithCapabilities) =>
  USER_LIFE_CYCLE_STATUSES.find(({ predicate }) => predicate(user))?.label ??
  user.capabilitiesManager.userLifeCycleStatus ??
  '';

const Status = ({ user }: { user: CanonicalUserRecordWithCapabilities }) => {
  const statusConfig = USER_LIFE_CYCLE_STATUSES.find(({ predicate }) => predicate(user));

  if (!statusConfig) {
    return (
      <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
        Unknown
      </span>
    );
  }

  return <span className={statusConfig.labelClassName}>{statusConfig.label}</span>;
};

export const getUsersTableColumns = ({
  handleEditUserClick,
  handleResendButtonClick,
  teams,
}: {
  handleEditUserClick: (user: CanonicalUserRecordWithCapabilities) => MouseEventHandler<HTMLAnchorElement> | undefined;
  handleResendButtonClick: (
    user: CanonicalUserRecordWithCapabilities,
  ) => MouseEventHandler<HTMLAnchorElement> | undefined;
  teams: Team[];
}) => [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('firstName', {
    id: 'firstName',
    header: 'First Name',
    enableColumnFilter: false,
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
    },
  }),
  columnHelper.accessor('lastName', {
    id: 'lastName',
    header: 'Last Name',
    enableColumnFilter: false,
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
    },
  }),
  columnHelper.accessor((user) => (user.role ? language.user.role[user.role as UserRole] : user.role), {
    id: 'role',
    header: 'Role',
    filterFn: (row, _, filteredRoleLabels) =>
      filteredRoleLabels?.includes(language.user.role[row.original.role as UserRole]),
    meta: {
      filter: {
        placeholder: 'All roles',
      },
      tooltip: true,
    },
  }),
  teamsAccessor<CanonicalUserRecordWithCapabilities>(columnHelper, teams),
  columnHelper.accessor('email', {
    id: 'email',
    header: 'Email',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All emails',
      },
      tooltip: true,
    },
  }),
  columnHelper.accessor((user) => getStatusLabel(user), {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<CanonicalUserRecordWithCapabilities, string>) => (
      <Status user={cellContext.row.original} />
    ),
    meta: {
      filter: {
        placeholder: 'All statuses',
      },
      dataCellClassName: '!w-24 whitespace-nowrap py-4 pl-0 align-top',
      headingCellClassName: '!w-24',
      headingLinkClassName: '!pl-0',
    },
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: 'Created at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<CanonicalUserRecordWithCapabilities, string>) =>
      formatDate(cellContext.row.original.created_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
      headingLinkClassName: '!pl-0',
      dataCellClassName: '!pl-0 w-40',
    },
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<CanonicalUserRecordWithCapabilities, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
      dataCellClassName: 'w-40',
    },
  }),
  columnHelper.display({
    id: 'edit',
    enableColumnFilter: false,
    cell: (cellContext) => (
      <ShowFirstMatching>
        <Show when={cellContext.row.original.capabilitiesManager.canBeUpdatedAsPended}>
          <a
            href="#"
            onClick={handleResendButtonClick(cellContext.row.original)}
            className="text-primary-600 hover:text-primary-900"
          >
            Update
          </a>
        </Show>
        <Show when={cellContext.row.original.capabilitiesManager.canBeEdited}>
          <a
            href="#"
            onClick={handleEditUserClick(cellContext.row.original)}
            className={'text-primary-600 hover:text-primary-900'}
          >
            Edit
          </a>
        </Show>
      </ShowFirstMatching>
    ),
    meta: {
      dataCellClassName: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative py-3.5 pl-3 pr-4 sm:pr-6 w-24',
    },
  }),
];
