import { type FC, useCallback } from 'react';
import { FeatureIndicatorIcon } from '@components/FeatureIndicatorIcon';
import { Show } from '@components/Show';
import { BlockExpiryDateIndicator } from '@ContractBuilder/modules/block/components/BlockExpiryDateIndicator';
import { getLockedBlockPermissionsMessage } from '@ContractBuilder/utils';
import {
  mdiArrowDecisionAuto,
  mdiAutoFix,
  mdiEye,
  mdiEyeOff,
  mdiFormatPageBreak,
  mdiLock,
  mdiPaletteSwatchVariant,
  mdiTuneVertical,
} from '@mdi/js';
import { useSetFocussedCandidate } from '@MrcExtraction/modules/mrc-document-view';
import type { ResourceBlock } from '@root/@types/base';
import type { BlockVisibilityOutput } from '@root/@types/types';
import { isEndorsementViewPath, isTemplatePath } from '@root/src/utils/app-paths';
import clsx from 'clsx';

import { indicatorIconsClasses } from '../classes';
import { BlockPermissionsSet } from '../components/BlockPermissionsSet';
import { BlockUsedWithin } from '../components/BlockUsedWithin';
import { VisibilityReasons } from '../components/VisibilityReasons';

interface FeatureIndicatorsProps {
  block: ResourceBlock;
  hasVariationsConditionalLogic: boolean;
  isEnabled: boolean;
  isHiddenFromPDFPreview: boolean;
  isVisible: boolean;
  showDataExtractionIndicator: boolean;
  showLockedIndicator: boolean;
  showPageBreakIndicator: boolean;
  showPermissionsSetIndicator: boolean;
  showUsedInTemplatesControls: boolean;
  showVariationsIndicator: boolean;
  showVisibilityReasonsIndicatorIcon: boolean;
  variations: {
    count: number;
    selected: boolean;
    visibilityDetails: BlockVisibilityOutput[] | undefined;
  };
  visibilityDetails: BlockVisibilityOutput | undefined;
}

export const FeatureIndicators: FC<FeatureIndicatorsProps> = ({
  block,
  hasVariationsConditionalLogic,
  isEnabled,
  isHiddenFromPDFPreview,
  isVisible,
  showDataExtractionIndicator,
  showLockedIndicator,
  showPageBreakIndicator,
  showPermissionsSetIndicator,
  showUsedInTemplatesControls,
  showVariationsIndicator,
  showVisibilityReasonsIndicatorIcon,
  variations,
  visibilityDetails,
}) => {
  const onDocumentPreviewOpen = useSetFocussedCandidate();
  const isEndorsementView = isEndorsementViewPath();
  const isTemplate = isTemplatePath();

  const handleExtractionCandidateClick = () => {
    onDocumentPreviewOpen(block.extractionCandidateId);
  };

  const visibilityReasonsInner = useCallback(
    ({ onClick }: any) => (
      <FeatureIndicatorIcon
        path={isVisible ? mdiEye : mdiEyeOff}
        content={visibilityDetails?.info}
        data-testid="block-icon-indicator:visibility"
        isInteractive
        onClick={onClick}
        className={'text-info-400'}
      />
    ),
    [isVisible, visibilityDetails?.info],
  );

  if (!isEnabled) {
    return null;
  }

  return (
    <div className={clsx(indicatorIconsClasses)}>
      <Show when={isTemplate}>
        <BlockExpiryDateIndicator expiryDate={block.expiry_date} isBlock={block.type !== 'clause'} />
      </Show>
      <Show when={isEndorsementView && isHiddenFromPDFPreview}>
        <FeatureIndicatorIcon
          placement="top"
          path={mdiEyeOff}
          content="This block will be hidden in the PDF"
          data-testid="block-icon-indicator:hidden-in-pdf"
        />
      </Show>
      <Show when={!isEndorsementView}>
        <Show when={showVisibilityReasonsIndicatorIcon}>
          <VisibilityReasons visibilityDetails={visibilityDetails} trigger={visibilityReasonsInner} />
        </Show>
        <Show when={showDataExtractionIndicator}>
          <FeatureIndicatorIcon
            isInteractive
            path={mdiAutoFix}
            content={'This block was created automatically, based on data extraction document'}
            data-testid="block-icon-indicator:extraction-candidate"
            onClick={handleExtractionCandidateClick}
          />
        </Show>
        <Show when={showVariationsIndicator}>
          <FeatureIndicatorIcon
            path={hasVariationsConditionalLogic ? mdiArrowDecisionAuto : mdiPaletteSwatchVariant}
            content={`${variations.count} variations`}
            data-testid="block-icon-indicator:variations"
          />
        </Show>
        <Show when={showPageBreakIndicator}>
          <FeatureIndicatorIcon
            path={mdiFormatPageBreak}
            content={`Page break after block`}
            data-testid="block-icon-indicator:page-break"
          />
        </Show>
        <Show when={showUsedInTemplatesControls}>
          <BlockUsedWithin block={block} />
        </Show>
        <Show when={showPermissionsSetIndicator}>
          <FeatureIndicatorIcon
            path={mdiTuneVertical}
            content={<BlockPermissionsSet block={block} />}
            data-testid="block-icon-indicator:permissions-set"
          />
        </Show>
        <Show when={showLockedIndicator}>
          <FeatureIndicatorIcon
            path={mdiLock}
            content={getLockedBlockPermissionsMessage(
              block.canEdit,
              block.canDelete,
              block.canEditOnTemplate,
              block.canDeleteOnTemplate,
              isTemplate,
            )}
            data-testid={`block-icon-indicator:${block.canDelete ? 'lock-edit' : 'lock-delete'}`}
          />
        </Show>
      </Show>
    </div>
  );
};
