import { Show } from '@components/Show';
import { mdiCreationOutline } from '@mdi/js';
import { getDataTestId } from '@root/src/utils/element-testid-selectors';
import { useDocumentLocation } from '@src/hooks';
import clsx from 'clsx';

import { useBlockCreator } from '../../context';
import { StepperCard } from '../Card';
import { StepView } from '../StepView';

export const StepStart = () => {
  const { isEndorsementView } = useDocumentLocation();
  const { setStep } = useBlockCreator();
  const createBlockDescription = isEndorsementView ? 'Create a new summary block' : 'Create a new block or clause';

  return (
    <StepView isValid stepId="start">
      <div className={'m-auto flex h-full w-fit items-center gap-10'}>
        <Show when={!isEndorsementView}>
          <StepperCard
            cta="Select block"
            svgName="blocks"
            description="Select a block from the library"
            onClick={() => setStep('library-block')}
            data-testid={getDataTestId('block.addBlockFromLibrary').toString()}
          />
          <StepperCard
            cta="Select clause"
            svgName="clauses"
            description="Select a clause from the library"
            onClick={() => setStep('library-clause')}
            data-testid={getDataTestId('block.addClauseFromLibrary').toString()}
          />
        </Show>
        <StepperCard
          cta="Create block"
          icon={mdiCreationOutline}
          description={createBlockDescription}
          onClick={() => setStep('block')}
          data-testid={getDataTestId('block.addCustom').toString()}
          className={clsx(isEndorsementView && 'w-full [&>div]:items-center')}
        />
      </div>
    </StepView>
  );
};
