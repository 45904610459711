import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import type { ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const classOfBusinessAccessor = <T extends { class_of_business?: string[] }>(
  columnHelper: ColumnHelper<T>,
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.accessor((row) => row.class_of_business ?? [], {
    id: 'class_of_business',
    header: 'Class of business',
    meta: {
      filter: {
        type: 'dropdown',
        placeholder: 'All classes',
        label: 'Class of business',
        options: ALL_CLASSES_OF_BUSINESS,
        ...filterMeta,
      },
    },
    filterFn: 'arrIncludesSome',
  });
