import { type FC } from 'react';
import { useQuery } from 'react-query';
import InputCalendar from '@components/InputCalendar';
import InputMultiSelect from '@components/InputMultiSelect';
import { Show } from '@components/Show';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { fetchUserTeams } from '@queries/fetchTeams';
import type { Team } from '@root/@types/types';
import { formatDate, isNonNullish } from '@root/helpers';
import { isBlockExpired } from '@root/helpers/blocks';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { useLanguage } from '@src/language';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';
import { isEmpty, pick, values } from 'lodash-es';

import { ClauseDetails } from '../components/ClauseDetails';
import { DatapointDisplay } from '../components/DatapointDisplay';
import { DrawerBlockUsedWithin } from '../components/DrawerBlockUsedWithin';
import { DrawerCollapsibleGroup } from '../components/DrawerCollapsibleGroup';
import { DrawerParentBlockName } from '../components/DrawerParentBlockName';
import { getExpiryDatePickerProps, getInceptionDatePickerProps } from '../utils/get-date-picker-props';

import { DrawerBlockStatus } from './DrawerBlockStatus';

const getHideSection = (isAdminView: boolean, formValues: any) => {
  if (isAdminView) return false;
  if (formValues?.type === 'clause') return false;

  return isEmpty(
    values(
      pick(formValues, [
        'class_of_business',
        'classification',
        'clause_reference',
        'clause_type',
        'parentBlockId',
        'teams',
      ]),
    ).filter(isNonNullish),
  );
};

export const DrawerPanelDetails: FC = () => {
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isClause = isClausesPath();

  const { formValues, currentBlock } = useBlockEditFormStore(({ formValues, currentBlock }) => ({
    formValues,
    currentBlock,
  }));
  const { data: teams = [] } = useQuery<Team[]>(['userTeams'], fetchUserTeams, {
    refetchOnWindowFocus: false,
  });

  const isBlockType = formValues?.type !== 'clause';
  const isClauseType = formValues?.type === 'clause';

  const { getContent } = useLanguage({
    prefix: `naming.mrc.rightSidebar.${isBlockType ? 'block' : 'clause'}`,
  });

  const isAdminView = isBlock || isTemplate || isClause;
  const hideSection = getHideSection(isAdminView, formValues);

  if (hideSection || !formValues) return null;

  return (
    <DrawerCollapsibleGroup title={getContent('groups.details')} id="details">
      <Show when={isAdminView}>
        <DrawerBlockStatus isBlock={isBlockType} isExpired={isBlockExpired(currentBlock?.expiry_date)} />
        <DrawerParentBlockName parentBlockId={formValues?.parentBlockId} />
        <DrawerBlockUsedWithin data={formValues?.usedInTemplates} isBlock={isBlockType} />
      </Show>
      <Show when={isClauseType}>
        <ClauseDetails isAdminView={isAdminView} isBlockType={isBlockType} />
      </Show>
      <DatapointDisplay
        inputKey="teams"
        title={getContent('fields.teamFilter.label')}
        isAdminView={isAdminView}
        getContractValuePillsLabel={(value) => teams?.find((t) => t.id === value)?.name ?? ''}
        renderInput={(props) => (
          <InputMultiSelect<number>
            isSearchable
            isReadOnly={!isAdminView}
            labelText=""
            options={teams?.map((team) => ({ name: team.name, value: team.id })) ?? []}
            {...props}
          />
        )}
      />
      <DatapointDisplay
        inputKey="class_of_business"
        title={getContent('fields.classOfBusiness.label')}
        isAdminView={isAdminView}
        renderInput={(props) => (
          <InputMultiSelect
            isSearchable
            labelText=""
            options={ALL_CLASSES_OF_BUSINESS.map((label) => ({ name: label, value: label }))}
            {...props}
          />
        )}
      />
      <DatapointDisplay
        getContractValuePillsLabel={(value) => formatDate(value, 'DD MMMM YYYY') ?? ''}
        inputKey="inception_date"
        isAdminView={isAdminView}
        renderInput={(props) => (
          <InputCalendar
            wrapperClassName="ml-0"
            dayPickerProps={getInceptionDatePickerProps(formValues?.expiry_date)}
            {...props}
          />
        )}
        title={getContent('fields.inceptionDate.label')}
      />
      <DatapointDisplay
        getContractValuePillsLabel={(value) => formatDate(value, 'DD MMMM YYYY') ?? ''}
        inputKey="expiry_date"
        isAdminView={isAdminView}
        renderInput={(props) => (
          <InputCalendar
            wrapperClassName="ml-0"
            dayPickerProps={getExpiryDatePickerProps(formValues?.inception_date)}
            {...props}
          />
        )}
        title={getContent('fields.expiryDate.label')}
      />
    </DrawerCollapsibleGroup>
  );
};
