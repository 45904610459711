import { useUserStore } from '@Auth/store';
import { Show } from '@components/Show';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { isSuperadmin } from '@root/helpers/permissions';
import Toggle from '@src/components/Toggle';
import { useLanguage } from '@src/language';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';
import { get } from 'lodash-es';

import { DrawerCollapsibleGroup } from '../components/DrawerCollapsibleGroup';
import { DrawerSection } from '../components/DrawerSection';

export const DrawerPanelPermissions = () => {
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isClause = isClausesPath();

  const { formValues, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));
  const user = useUserStore((state) => state.user);
  const isSuperAdminUser = isSuperadmin(user);

  const { getContent } = useLanguage({
    prefix: `naming.mrc.rightSidebar.${formValues?.type !== 'clause' ? 'block' : 'clause'}`,
  });

  const toggleField = (field: keyof BlockEditFormState, fallback?: boolean) => () => {
    setFormValues((prev) => {
      const currentValue = get(prev, field, fallback);
      return { ...prev, [field]: !currentValue };
    });
  };

  const isAdminView = isBlock || isTemplate || isClause;
  const canDeleteOnTemplate = formValues?.canDeleteOnTemplate ?? true;
  const canEditOnTemplate = formValues?.canEditOnTemplate ?? true;
  const canEdit = formValues?.canEdit ?? true;
  const canDelete = formValues?.canDelete ?? true;

  return (
    <DrawerCollapsibleGroup title={getContent('groups.permissions')} id="permissions">
      <Show when={isAdminView && isSuperAdminUser}>
        <Toggle
          labelText={getContent('fields.settings.superAdminEdit.label')}
          value={!canEditOnTemplate}
          onClick={toggleField('canEditOnTemplate', canEditOnTemplate)}
          data-testid="toggle-can-edit-by-superadmin"
        />
      </Show>
      <Show when={isTemplate && isSuperAdminUser}>
        <DrawerSection title={getContent('fields.templatePermissions.label')}>
          <Toggle
            labelText={getContent('fields.templatePermissions.canDelete.label')}
            value={canDeleteOnTemplate}
            onClick={toggleField('canDeleteOnTemplate', canDeleteOnTemplate)}
            data-testid="toggle-can-delete-from-template"
          />
        </DrawerSection>
      </Show>
      <Show when={isAdminView}>
        <DrawerSection title={getContent('fields.submissionPermissions.label')}>
          <Toggle
            labelText={getContent('fields.submissionPermissions.canEdit.label')}
            value={canEdit}
            onClick={toggleField('canEdit', canEdit)}
            data-testid="toggle-can-edit"
          />
          <Toggle
            labelText={getContent('fields.submissionPermissions.canDelete.label')}
            value={canDelete}
            onClick={toggleField('canDelete', canDelete)}
            data-testid="toggle-can-delete"
          />
        </DrawerSection>
      </Show>
    </DrawerCollapsibleGroup>
  );
};
