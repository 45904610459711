import { Show } from '@components/Show';
import { AttachmentSection } from '@ContractBuilder/modules/attachment/views/AttachmentSection';
import { useEntityStore } from '@ContractBuilder/store';
import { useDocumentLocation } from '@hooks/useDocumentLocation';
import { useFeatureFlags } from '@hooks/useFeatureFlags';

export const Attachments = () => {
  const attachments = useEntityStore((state) => state.attachments);
  const hasAttachmentsFeature = useFeatureFlags()('Attachments');
  const { isEndorsement } = useDocumentLocation();

  return (
    <Show when={attachments?.length > 0 && !isEndorsement && hasAttachmentsFeature}>
      <AttachmentSection attachments={attachments} />
    </Show>
  );
};
