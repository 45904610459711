import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, useRouteError } from 'react-router-dom';
import { ErrorController } from '@features/error';
import { type FallbackRender, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.1,
  release: import.meta.env.VITE_SENTRY_RELEASE,
});

// eslint-disable-next-line react-refresh/only-export-components
export const sentryErrorFallback: FallbackRender = ({ error: _error, componentStack: _componentStack, resetError }) => {
  return <ErrorController type="unknown" resetError={resetError} />;
};

export const SentryErrorElement = () => {
  const error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return <ErrorController type="unknown" />;
};
