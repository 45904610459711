import type { FC } from 'react';
import { useState } from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { ArtificialCustomEvent, useDispatchCustomEvent } from '@ContractBuilder/modules/events';
import { NavigationController } from '@ContractBuilder/modules/navigation';
import { useEntityStore } from '@ContractBuilder/store';
import { mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal } from '@mdi/js';
import { useLanguage } from '@root/src/language';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

import Divider from '../../../components/Divider';

import { AddNewPartButton } from './AddNewPartButton';

const listControlClasses = cva('cursor-pointer transition-colors', {
  variants: {
    isHighlighted: {
      false: null,
      true: 'text-primary-600',
    },
  },
});

interface LeftSidebarProps {
  activeSectionId?: string;
}

const LeftSidebar: FC<LeftSidebarProps> = ({ activeSectionId }) => {
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));

  const { getContent } = useLanguage({ prefix: 'naming.mrc.leftSidebar' });

  const [highlightedOption, setHighlightedOption] = useState<'expand' | 'collapse' | 'none'>('none');
  const dispatchCustomEvent = useDispatchCustomEvent();

  const handleExpandSectionsClick = () => {
    setHighlightedOption('expand');
    return dispatchCustomEvent(ArtificialCustomEvent.ToggleExpandSections, true);
  };

  const handleCollapseAllSectionsClick = () => {
    setHighlightedOption('collapse');
    return dispatchCustomEvent(ArtificialCustomEvent.ToggleExpandSections, false);
  };

  return (
    <div className={clsx(`flex w-1/3 max-w-drawer flex-col border-r bg-white`)}>
      <div className={clsx('flex h-[50px] shrink-0 items-center justify-between px-4 py-3', 'justify-center gap-4')}>
        <div className="flex flex-col">
          <p className="text-base font-normal leading-6 text-black">{getContent('title')}</p>
        </div>
        <AddNewPartButton submission={submission} />
      </div>
      <Divider />
      <NavigationController activeSectionId={activeSectionId} />

      <div className="flex h-[53px] shrink-0 items-center gap-x-1.5 border-t px-4 text-sm font-normal leading-[18px] text-info-600">
        <Button kind="secondary" className="flex h-8 items-center gap-2 px-3">
          <IconMdi
            path={mdiUnfoldMoreHorizontal}
            className={listControlClasses({ isHighlighted: highlightedOption === 'expand' })}
          />
          <p onClick={handleExpandSectionsClick}>{getContent('controls.expand')}</p>
        </Button>
        <Button kind="secondary" className="flex h-8 items-center gap-2 px-3">
          <IconMdi
            path={mdiUnfoldLessHorizontal}
            className={listControlClasses({ isHighlighted: highlightedOption === 'collapse' })}
          />
          <p onClick={handleCollapseAllSectionsClick}>{getContent('controls.collapse')}</p>
        </Button>
      </div>
    </div>
  );
};

export default LeftSidebar;
