import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import { UserCapabilityManager } from '@root/helpers/user-management';
import type { CanonicalUserRecordWithCapabilities } from '@root/helpers/user-management/types';
import axios from 'axios';

export const fetchUsers = async (): Promise<CanonicalUserRecordWithCapabilities[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/users-spa-al',
  });

  const response = await axios(networkPayload);

  const data = (Array.isArray(response.data) ? response.data : []).map((user) => ({
    ...user,
    capabilitiesManager: new UserCapabilityManager(user),
  }));

  return data;
};
