import type { FC } from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useUserStore } from '@Auth/store';
import { AnimateHeight } from '@components/AnimateHeight';
import Avatar from '@components/Avatar';
import DropdownChevron from '@components/DropdownChevron';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { getUserDisplayName } from '@ContractBuilder/modules/comments/utils';
import { getInitials } from '@helpers/getInitials';
import { LogoutIcon } from '@heroicons/react/outline';
import { UserGroupIcon } from '@heroicons/react/solid';
import { mdiDomain, mdiWeb } from '@mdi/js';
import type { BaseUser, Team } from '@root/@types/types';
import { getUserRole } from '@root/helpers/permissions/utils';
import { useLanguage } from '@root/src/language';
import { fetchUserTeams } from '@src/queries';

import { buttonClasses, containerClasses, nameClasses } from './classes';

interface MenuSectionUserProps {
  user: BaseUser;
  isCollapsible?: boolean;
  displayAvatar?: boolean;
  variant?: 'dark' | 'light';
  containerClassName?: string;
}

export const MenuSectionUser: FC<MenuSectionUserProps> = ({
  containerClassName,
  user,
  isCollapsible,
  variant = 'dark',
  displayAvatar,
}) => {
  const [isOpen, toggleOpen] = useToggle(!isCollapsible);
  const { getContent } = useLanguage({ prefix: 'user.role' });
  const { updatedAt } = useUserStore(({ updatedAt }) => ({ updatedAt }));
  const { data: userTeams = [] } = useQuery<Team[]>(['teams'], fetchUserTeams, {
    staleTime: 60000,
  });

  const teamNames = user.tenantIds.map((teamId) => (userTeams ?? []).find((team) => team.id === teamId)?.name ?? '');

  const { initials, teams, name, role, organisation, company } = useMemo(() => {
    const role = getUserRole(user);
    return {
      name: getUserDisplayName(user),
      role: role ? getContent(role) : role,
      teams: teamNames,
      initials: getInitials(user),
      organisation: user.organisationName,
      company: user.companyName,
    };
    // eslint-disable-next-line -- Observing only user.username is enough
  }, [user.username, updatedAt]);

  const hasTeams = teams.length > 0;

  return (
    <div
      className={containerClasses({
        isOpen,
        variant,
        className: containerClassName,
      })}
    >
      <div className="flex items-center gap-3">
        <Show when={displayAvatar}>
          <Avatar initials={initials} />
        </Show>
        <div>
          <p className={nameClasses({ variant })}>{name}</p>
          <div className="flex items-center gap-1 break-words">
            <p className={'text-xs font-medium'}>{role}</p>
            <Show when={isCollapsible}>
              <DropdownChevron
                open={isOpen}
                onClick={toggleOpen}
                className="cursor-pointer"
                title={isOpen ? 'Collapse' : 'Show teams'}
                data-testid="toggle-teams-and-logout"
              />
            </Show>
          </div>
        </div>
      </div>
      <AnimateHeight isVisible={isOpen}>
        <div className="flex flex-col gap-2">
          <ul className="flex flex-col gap-2">
            <Show when={!!organisation}>
              <li className="flex cursor-default items-center gap-2 text-xs" title={organisation}>
                <IconMdi className="float-left h-3 w-auto flex-shrink-0" path={mdiWeb} />
                <span className="max-w-full truncate"> {organisation}</span>
              </li>
            </Show>
            <Show when={!!company}>
              <li className="flex cursor-default items-center gap-2 text-xs" title={company}>
                <IconMdi className="float-left h-3 w-auto flex-shrink-0" path={mdiDomain} />
                <span className="max-w-full truncate"> {company}</span>
              </li>
            </Show>
            <Show when={hasTeams}>
              <li className="flex cursor-default items-center gap-2 text-xs">
                <ul className="flex max-w-full flex-col gap-2 truncate">
                  {teams.map((team) => (
                    <li key={team} title={team} className="flex items-center gap-2">
                      <UserGroupIcon className="float-left h-3 w-auto flex-shrink-0" /> {team}
                    </li>
                  ))}
                </ul>
              </li>
            </Show>
          </ul>
          <div className={`${hasTeams && 'mt-6'} text-right`}>
            <Link to="/logout" className="ml-auto">
              <button type="button" className={buttonClasses({ variant })}>
                Logout
                <LogoutIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </button>
            </Link>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};
