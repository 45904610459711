import { pagesContainerClasses, pagesContainerWrapper } from '@ContractBuilder/ContractViewClasses';
import { MentionsHandlerController } from '@ContractBuilder/modules/mentions-handler';
import { isTemplatePath } from '@root/src/utils/app-paths';
import clsx from 'clsx';

import { ContractViewContentProvider } from '../context/ContractViewProvider';
import { ContractViewContent } from '../views/ContractViewContent';
import { Navigation } from '../views/Navigation';

import '../../../containers/ContractBuilder/contract.css';
import '../../../containers/WysiwygEditor/components/Editor/table-borders.css';

export const ContractViewController = () => {
  const isTemplate = isTemplatePath();

  return (
    <ContractViewContentProvider>
      <div className="flex h-full flex-col">
        <div className={clsx(pagesContainerWrapper, isTemplate && 'page-template')} data-cypress="contract-view">
          {!isTemplate && <MentionsHandlerController />}
          <Navigation />
          <div className={clsx(pagesContainerClasses, 'relative overflow-hidden bg-info-50')}>
            <ContractViewContent />
          </div>
        </div>
      </div>
    </ContractViewContentProvider>
  );
};
