import type { ReactNode } from 'react';
import React from 'react';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { mdiChevronDown, mdiClose, mdiFilter } from '@mdi/js';
import { GlobalSearch } from '@pages/User/components/GlobalSearch';
import pluralize from 'pluralize';

import { ActionButton } from './ActionButton';

type ActionBarProps = {
  actions: any[];
  enableColumnFilters: boolean;
  entityName: string;
  shouldShowClearFilters: boolean;
  handleGlobalSearchClick: (searchTerm: string) => void;
  handleClearAllFiltersClick: () => void;
  toggleFiltersBarVisibility: () => void;
  renderCustomElements?: () => ReactNode;
};

export const ActionsBar = ({
  actions,
  entityName,
  handleGlobalSearchClick,
  shouldShowClearFilters,
  handleClearAllFiltersClick,
  enableColumnFilters,
  toggleFiltersBarVisibility,
  renderCustomElements,
}: ActionBarProps) => {
  const entitiesLabel = pluralize(entityName);
  return (
    <div className="mt-6 flex items-center justify-between gap-x-2">
      <div className="flex flex-auto shrink-0 self-start sm:mb-4 lg:mb-0">
        {actions?.map((action) => <ActionButton action={action} key={action.key} />)}
      </div>
      {renderCustomElements?.()}
      <Show when={shouldShowClearFilters}>
        <button
          onClick={handleClearAllFiltersClick}
          className="mr-4 flex h-full items-center justify-between px-2"
          data-testid="clear-all-filters"
        >
          <div className="ml-auto flex items-center gap-1">
            <IconMdi path={mdiClose} className="text-gray-500" />
            <span className="text-sm font-medium leading-5 text-gray-700">Clear all</span>
          </div>
        </button>
      </Show>
      <Show when={enableColumnFilters}>
        <button
          className="flex w-32 items-center justify-between border border-info-200 bg-info-50 p-2 py-2.5 hover:bg-opacity-75 md:rounded-lg"
          onClick={toggleFiltersBarVisibility}
          data-testid="toggle-filters-bar-visibility"
        >
          <div className="ml-auto flex items-center gap-1">
            <IconMdi path={mdiFilter} className="text-gray-400" />
            <span className="text-sm font-medium leading-5 text-gray-700">Filters</span>
          </div>
          <IconMdi className="ml-4" path={mdiChevronDown} />
        </button>
      </Show>
      <div className="ml-6 w-96">
        <GlobalSearch entitiesLabel={entitiesLabel} onSearchClick={handleGlobalSearchClick} />
      </div>
    </div>
  );
};
