import type { FC } from 'react';
import { useCallback } from 'react';
import { Link, useBeforeUnload } from 'react-router-dom';
import H3 from '@components/H3';
import Tooltip from '@components/Tooltip';
import { documentTitleClasses } from '@ContractBuilder/contract-builder-header/classes';
import { EditorToolbarController } from '@ContractBuilder/modules/editor-toolbar';
import { INLINE_BLOCK_EDITOR_NAME } from '@ContractBuilder/modules/editors-map';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useHistoryBackTrap } from '@hooks/use-history-back-trap';
import type { DynamoDBLibraryBlock } from '@root/@types/database';
import { formatDate } from '@root/helpers/date';
import sharedClasses from '@root/src/utils/shared-classes';
import { isClausesPath } from '@utils/app-paths';
import clsx from 'clsx';

import Nav from '../../../routes';
import { UNSAVED_CHANGES_PROMPT } from '../constants';

interface LibraryBlockEditHeaderProps {
  canNavigate: boolean;
  data: DynamoDBLibraryBlock;
}

export const LibraryBlockEditHeader: FC<LibraryBlockEditHeaderProps> = ({ canNavigate, data }) => {
  const isClause = isClausesPath();
  const dateInfo = `Last updated ${formatDate(data.updated_at ?? data.created_at, 'DD MMMM YYYY')} by ${
    data.last_updated_by?.name ?? data.created_by.name
  }`;
  const title = isClause ? 'Back to Clauses' : 'Back to Blocks';

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!canNavigate) {
        event.preventDefault();
        return (event.returnValue = UNSAVED_CHANGES_PROMPT);
      }
    },
    [canNavigate],
  );

  const handleTrap = useCallback(
    async (resume: () => void) => {
      if (!canNavigate) {
        return false;
      } else {
        resume();
        return true;
      }
    },

    [canNavigate],
  );

  useHistoryBackTrap(handleTrap);
  useBeforeUnload(handleUnload);

  return (
    <>
      <div className="flex flex-col border-b bg-white px-5 py-[14px]">
        <div className="flex w-full flex-nowrap items-center gap-3">
          <div className={clsx(!canNavigate && sharedClasses.disabledContainer)}>
            <Tooltip content={isClause ? 'Back to Clauses' : 'Back to Blocks'}>
              <Link
                to={isClause ? Nav.Clauses : Nav.Blocks}
                className={clsx('group', !canNavigate && sharedClasses.disabled)}
              >
                <div className={'mr-2 rounded-full border-2 border-info-600 p-1 group-hover:bg-gray-100'} title={title}>
                  <ArrowLeftIcon className="h-4 w-4 text-info-600" strokeWidth="3" />
                </div>
              </Link>
            </Tooltip>
          </div>
          <div className="flex min-w-0 items-center gap-4 2xl:flex-nowrap">
            <H3
              className={documentTitleClasses({
                isDisabled: true,
                isPlaceholder: !data?.name,
              })}
            >
              {data?.name}
            </H3>
            <p className="ml-4 cursor-default whitespace-nowrap text-sm font-light text-info-400 2xl:block">
              {dateInfo}
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute left-0 top-0 z-30 flex bg-white" style={{ width: 'calc(100% - 340px)' }}>
          <EditorToolbarController currentEditorKey={INLINE_BLOCK_EDITOR_NAME} type="contract" />
        </div>
      </div>
    </>
  );
};
