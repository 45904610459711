import type { FC } from 'react';
import { ProErrorPage } from '@features/error/views/ProErrorPage';
import Nav from '@src/routes';

import type { DocType, ErrorPageProps, ErrorType } from '../types';
import { PPLErrorPage } from '../views/PPLErrorPage';

const client: string = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;
const isPPL = client === 'ct-ppl';

const HOME = {
  branding: Nav.Branding,
  contract: Nav.Submissions,
  template: Nav.Templates,
};

const ALLOWED_ERROR_CODES: DocType[] = ['branding', 'contract', 'template'];

interface ErrorControllerProps {
  docType?: DocType;
  resetError?: () => void;
  type: ErrorType;
}

const useErrorState = ({
  docType,
  type = 'unknown',
}: Pick<ErrorControllerProps, 'docType' | 'type'>): Pick<ErrorControllerProps, 'docType' | 'type'> => {
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');

  if (code && ALLOWED_ERROR_CODES.includes(code as DocType)) {
    return { docType: code as DocType, type: 'document' };
  }

  return { docType, type };
};

export const ErrorController: FC<ErrorControllerProps> = ({ resetError, ...props }) => {
  const { docType, type } = useErrorState(props);

  const handleRefresh = () => {
    resetError?.();
    window.location.reload();
  };

  const handleNavigateHome = () => {
    const homeURL = HOME[docType ?? 'contract'];
    window.location.href = homeURL;
  };

  const errorPageProps: ErrorPageProps = {
    onNavigateHome: handleNavigateHome,
    onRefresh: handleRefresh,
    docType,
    type,
  };

  if (isPPL) {
    return <PPLErrorPage {...errorPageProps} />;
  }

  return <ProErrorPage {...errorPageProps} />;
};
