import React from 'react';
import InputMultiSelect from '@components/InputMultiSelect';
import InputSelect from '@components/InputSelect';
import { Show, ShowFirstMatching } from '@components/Show';
import Toggle from '@components/Toggle';
import { getColumnFilterOptions } from '@pages/User/helpers/filters/getColumnFilterOptions';
import type { DropdownFilter } from '@root/@types/types';
import type { Table } from '@tanstack/react-table';
import clsx from 'clsx';
import { get } from 'lodash-es';

const selectClasses = 'block rounded-md border-info-300 focus:border-primary-500 focus:ring-primary-500 sm:text-sm';

interface FiltersBarProps {
  table: Table<any>;
  wrapperClassName?: string;
}

export const FiltersBar = ({ table, wrapperClassName }: FiltersBarProps) => {
  const tableColumns = table.getAllColumns();

  return (
    <div
      data-testid="filters-bar"
      className="filters-bar mt-4 border-[1px] border-gray-200 bg-gray-50 p-4 pt-1 md:rounded-lg"
    >
      <div className="mt-4 flex flex-row items-start md:mt-0 md:flex-row xl:flex">
        <div
          key={JSON.stringify(table.getState().columnFilters ?? [])}
          className={clsx('flex flex-wrap items-start gap-5', wrapperClassName)}
        >
          {tableColumns
            .filter((column) => column.getCanFilter())
            .sort((a, b) => {
              const aOrder = get(a, 'columnDef.meta.filter.order', tableColumns.indexOf(a));
              const bOrder = get(b, 'columnDef.meta.filter.order', tableColumns.indexOf(b));

              return aOrder - bOrder;
            })
            .map((column) => {
              const { filter = {} } = column.columnDef.meta ?? {};
              const { className, label, type = 'dropdown', wrapperClassName } = filter;

              const selectCommonProps = {
                labelText: label ?? (column.columnDef.header as string),
                name: column.columnDef.id,
                placeholder: (filter as DropdownFilter).placeholder,
                options:
                  (column.columnDef.meta?.filter as DropdownFilter)?.options ??
                  (column.columnDef.meta?.filter as DropdownFilter)?.optionsMapping?.map((item) => ({
                    name: item.label,
                    value: item.value,
                  })) ??
                  getColumnFilterOptions(table, column.id),
              };

              return (
                <Show key={column.columnDef.id} when={column.getCanFilter()}>
                  <Show when={type === 'checkbox'}>
                    <div className={wrapperClassName ?? ''}>
                      <Toggle
                        value={Boolean(column.getFilterValue())}
                        className={className ?? 'mb-0 ml-2 mr-6 mt-9 xl:ml-4'}
                        labelText={label ?? (column.columnDef.header as string)}
                        onClick={() => column.setFilterValue(!column.getFilterValue())}
                      />
                    </div>
                  </Show>
                  <Show when={type === 'dropdown'}>
                    <div
                      className={clsx('mr-3 mt-3 last:mr-0', wrapperClassName)}
                      key={column.columnDef.id}
                      data-testid={label ?? (column.columnDef.header as string)}
                    >
                      <ShowFirstMatching>
                        <Show when={filter.isSingleSelection}>
                          <InputSelect
                            {...selectCommonProps}
                            className={className ?? clsx(selectClasses, 'w-[200px]')}
                            isClearable
                            inputClassName="bg-white"
                            onChange={(value) => column.setFilterValue(value)}
                            value={column.getFilterValue() as string}
                          />
                        </Show>
                        <Show when={true}>
                          <InputMultiSelect
                            {...selectCommonProps}
                            className={className ?? selectClasses}
                            dropdownWrapperClassName="bg-white"
                            dropdownInnerWrapperClassName="flex-col"
                            onChange={(selectedItems) => column.setFilterValue(selectedItems)}
                            onSelect={() => {}}
                            value={(column.getFilterValue() as string[]) ?? []}
                          />
                        </Show>
                      </ShowFirstMatching>
                    </div>
                  </Show>
                </Show>
              );
            })}
        </div>
      </div>
    </div>
  );
};
