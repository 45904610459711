import React from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Button from '@components/Button';
import Form from '@components/Form';
import H3 from '@components/H3';
import IconMdi from '@components/IconMdi';
import InputText from '@components/InputText';
import { Show } from '@components/Show';
import { parseParams } from '@helpers/parseParams';
import { useForm } from '@hooks/form';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { get } from 'lodash-es';
import * as yup from 'yup';

import { isPasswordExpired, PasswordExpiredBanner } from './components/PasswordExpiredBanner';
import { isUserNotExists, UserNotExistsBanner } from './components/UserNotExistsBanner';
import AuthLayout from './AuthLayout';

interface PasswordConditionProps {
  isValid: boolean;
  description: string;
}

type PasswordFormState = {
  newPassword: string;
  confirmNewPassword?: string;
};

const PasswordCondition: React.FC<PasswordConditionProps> = ({ isValid, description }) => (
  <div className="flex items-center gap-2">
    <IconMdi
      className={isValid ? 'text-success-500' : 'text-error-500'}
      path={isValid ? mdiCheckCircle : mdiCloseCircle}
    />
    <p>{description}</p>
  </div>
);

const checkMatch = function (this: any, value?: string): boolean {
  return !!(value && value.length >= 10 && value === this.parent.confirmNewPassword);
};

const conditions = {
  minimumCharacters: 'Minimum of 10 characters',
  uppercaseLowercase: 'Containing upper and lower case letters',
  numberMinimum: 'Contain at least one number',
  specialCharacter: 'Contain one special character',
  passwordMatch: 'Confirmation must match password',
};

const PasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isReset = useMatch('/reset-password');
  const isSetup = useMatch('/setup-password');
  const isRegister = useMatch('/register');

  const { isLoading, getFieldProps, onSubmit, validationErrors, canSubmit, formError } = useForm<PasswordFormState>({
    initialState: { newPassword: '', confirmNewPassword: '' },
    onSubmit: async (formValues) => {
      if (isReset) {
        const { email, resetCode } = parseParams(location.search);

        await Auth.forgotPasswordSubmit(email as string, resetCode as string, formValues.newPassword);

        return navigate('/login');
      }

      if (isSetup) {
        const signInFormData = get(location, 'state') as { email: string; password: string };
        const user = await Auth.signIn(signInFormData.email.trim(), signInFormData.password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return navigate('/login');
        }
      }

      if (isRegister) {
        const { inviteCode, userId } = parseParams(location.search);
        const user = await Auth.signIn(userId as string, inviteCode as string);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return navigate('/login');
        }
      }

      return false;
    },
    validationOptions: { abortEarly: false },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .min(10, conditions.minimumCharacters)
        .matches(/^(?=.*[A-Z])(?=.*[a-z])/, conditions.uppercaseLowercase)
        .matches(/^(?=.*[0-9])/, conditions.numberMinimum)
        .matches(/^(?=.*[!@#$%^&*()_+\-[\]{};':"\\|,.<>/?])/, conditions.specialCharacter)
        .test('match', conditions.passwordMatch, checkMatch)
        .required(),
      confirmNewPassword: yup.string(),
    }),
  });

  const handleSubmit = async () => {
    await onSubmit();
  };

  return (
    <AuthLayout>
      <Show when={isPasswordExpired(formError)}>
        <PasswordExpiredBanner />
      </Show>
      <Show when={isUserNotExists(formError)}>
        <UserNotExistsBanner />
      </Show>
      <Form onSubmit={handleSubmit} className="text-sm  text-info-700">
        <H3 className="mb-2 !font-['Inter'] !font-[600]">
          {isRegister ? 'Register your account' : 'Set your password'}
        </H3>
        <p className="mb-6">For security your password will need to match the following requirements:</p>
        <div className="mb-6 flex flex-col gap-3">
          {(Object.keys(conditions) as Array<keyof typeof conditions>).map((key) => {
            const condition = conditions[key];
            return (
              <PasswordCondition key={key} description={condition} isValid={!validationErrors?.includes(condition)} />
            );
          })}
        </div>
        <InputText
          className="mb-6"
          isPublic={true}
          labelText={isRegister ? 'Password' : 'New password'}
          placeholder="*********"
          type="password"
          {...getFieldProps('newPassword')}
        />
        <InputText
          className="mb-6"
          isPublic={true}
          labelText={isRegister ? 'Confirm password' : 'Confirm new password'}
          placeholder="*********"
          type="password"
          {...getFieldProps('confirmNewPassword')}
        />
        <Button kind="primary" type="submit" isDisabled={!canSubmit} loading={isLoading} className="h-10 w-full">
          {isRegister ? 'Register' : 'Set new password'}
        </Button>
      </Form>
    </AuthLayout>
  );
};

PasswordCondition.defaultProps = {
  isValid: undefined,
};

export default PasswordForm;
