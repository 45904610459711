import type { FC } from 'react';

import { CreateEntityModal } from '../views/CreateEntityModal';

interface CreateEntityModalControllerProps {
  handleClose: () => void;
}

export const CreateEntityModalController: FC<CreateEntityModalControllerProps> = ({ handleClose }) => (
  <CreateEntityModal onClose={handleClose} />
);
