import { type FC } from 'react';
import { useUserStore } from '@Auth/store';
import Button from '@components/Button';
import LoadingOverlay from '@components/LoadingOverlay';
import MODALS from '@constants/modals';
import { useEditorsMap } from '@ContractBuilder/modules/editors-map';
import type { Images } from '@domain/schemas/images.schema';
import { CloudUploadIcon } from '@heroicons/react/outline';
import { useModalDispatch } from '@hooks/useModalProvider';
import { isSuperadmin } from '@root/helpers/permissions';
import { isTemplateManager } from '@root/helpers/permissions/utils';
import { imageProcessor } from '@root/src/utils/image-processor';
import { useEffectOnce } from '@src/hooks';

import { useBrandingStore } from '../store/branding.store';
import { useImagesStore } from '../store/images.store';

import { BrandingImage } from './BrandingImage';
import { BrandingImageMenu } from './BrandingImageMenu';

const PRESIGNED_URL_TTL_MS = 300 * 1000;

export const BrandingImagesListing: FC = () => {
  const { showModal } = useModalDispatch();
  const { getEditor } = useEditorsMap();
  const {
    form: { editorKey },
    hasOperationsDisabled,
  } = useBrandingStore(({ form, hasOperationsDisabled }) => ({ form, hasOperationsDisabled }));
  const { images, remove, refetchImageList, isLoading } = useImagesStore(
    ({ images, remove, refetchImageList, isLoading }) => ({
      images,
      remove,
      refetchImageList,
      isLoading,
    }),
  );

  useEffectOnce(() => {
    refetchImageList();
  });

  useEffectOnce(() => {
    // Refetch images list before the pre-signed URL expires
    const intervalId = setInterval(refetchImageList, PRESIGNED_URL_TTL_MS);
    return () => clearInterval(intervalId);
  });

  const user = useUserStore((s) => s.user);
  const canRemoveImages = isSuperadmin(user) || isTemplateManager(user);

  const handleFileUploadRequested = () => {
    showModal(MODALS.UPLOAD_IMAGE, { refetchImageList });
  };

  const handleImageRemove = (imageId: string) => async () => {
    if (!canRemoveImages) {
      return;
    }

    remove(imageId).then(() => refetchImageList());
  };

  const handleImageInsert = (image: Images) => () => {
    if (!editorKey) return;
    const editor = getEditor(editorKey) as any;

    // we could skip this fn if backend keeps image WxH
    if (image.url) {
      imageProcessor(image.url, image.path, image.name).then((img) =>
        editor.commands.setResizableImage({
          ...img,
          'data-keep-ratio': true,
          className: 'not-prose',
        }),
      );
    }
  };

  return (
    <>
      <div className="relative flex max-h-[50vh] min-h-[100px] w-full flex-col gap-3 overflow-y-auto rounded-lg border text-xs font-light">
        <LoadingOverlay active={isLoading} unmount />
        {images?.map((image) => (
          <BrandingImage
            key={image.id}
            image={image}
            handleImageInsert={hasOperationsDisabled ? undefined : handleImageInsert(image)}
            contextMenu={
              <BrandingImageMenu
                canRemoveImages={canRemoveImages}
                handleImageRemove={handleImageRemove(image.id)}
                key={image.id}
              />
            }
          />
        ))}
      </div>
      <Button
        kind="secondary"
        className="h-9 max-w-min whitespace-nowrap"
        onClick={handleFileUploadRequested}
        isDisabled={hasOperationsDisabled}
      >
        <CloudUploadIcon className="h-4 w-4" />
        Upload image
      </Button>
    </>
  );
};
