import type { ResourceBlock } from '@root/@types/base';
import type { BlockType, BlockVariation, ClauseType } from '@root/@types/types';
import * as yup from 'yup';

import type { CallbackState } from './types';

export const formValidationSchema = yup.object().shape({
  blockLibraryId: yup.string().optional(),
  usedInTemplates: yup.array().optional(),
  content: yup.string(),
  id: yup.string(),
  name: yup.string().required(),
  order: yup.number().optional(),
  section_id: yup.string().required(),
  title: yup.string().optional(),
  type: yup.mixed<BlockType>().required(),
  deleted_at: yup.string().nullable().optional(),
  clause_type: yup.mixed<ClauseType>().optional(),
  variations: yup.mixed<BlockVariation[]>().optional(),
  class_of_business: yup.array().optional(),
  teams: yup.array().optional(),
});

export const saveAsNewFormValidationSchema = yup.object().shape({
  blockLibraryId: yup.string().required(),
  usedInTemplates: yup.array().optional(),
  content: yup.string(),
  id: yup.string().required(),
  name: yup.string().required(),
  order: yup.number().optional(),
  section_id: yup.string().required(),
  title: yup.string().optional(),
  type: yup.mixed<BlockType>().required(),
  deleted_at: yup.string().optional(),
  clause_type: yup.mixed<ClauseType>().optional(),
  variations: yup.mixed<BlockVariation[]>().optional(),
  class_of_business: yup.array().optional(),
  teams: yup.array().optional(),
});

export const editBlockLibrarySchema = yup.object().shape({
  blockLibraryId: yup.string(),
  usedInTemplates: yup.array().optional(),
  content: yup.string(),
  id: yup.string(),
  name: yup.string().required(),
  order: yup.number(),
  section_id: yup.string(),
  title: yup.string().optional(),
  type: yup.mixed<BlockType>().required(),
  deleted_at: yup.string().nullable().optional(),
  clause_type: yup.mixed<ClauseType>().optional(),
  variations: yup.mixed<BlockVariation[]>().optional(),
  class_of_business: yup.array().optional(),
  teams: yup.array().optional(),
});

export const createBlockLibrarySchema = yup.object().shape({
  blockLibraryId: yup.string(),
  usedInTemplates: yup.array().optional(),
  content: yup.string(),
  id: yup.string(),
  name: yup.string().required(),
  order: yup.number(),
  section_id: yup.string(),
  title: yup.string().optional(),
  type: yup.mixed<BlockType>().required(),
  deleted_at: yup.string().optional(),
  clause_type: yup.mixed<ClauseType>().optional(),
  variations: yup.mixed<BlockVariation[]>().optional(),
  class_of_business: yup.array().optional(),
  teams: yup.array().optional(),
});

export const initialCallbackState: CallbackState = { fn: undefined };

type BlockKey = keyof ResourceBlock;

export const AS_NEW_BLOCK_PAYLOAD_KEYS: BlockKey[] = [
  'canDelete',
  'canEdit',
  'canEditOnTemplate',
  'class_of_business',
  'classification',
  'clause_reference',
  'clause_type',
  'content',
  'helperText',
  'linkedDatapoints',
  'repeaterIds',
  'shouldInsertPageBreak',
  'teams',
  'title',
  'type',
  'variations',
];

export const CREATE_LIBRARY_BLOCK_PAYLOAD_KEYS: BlockKey[] = [
  'canDelete',
  'canEdit',
  'canEditOnTemplate',
  'clause_reference',
  'clause_type',
  'content',
  'helperText',
  'linkedDatapoints',
  'name',
  'repeaterIds',
  'shouldInsertPageBreak',
  'title',
  'type',
  'variations',
  'class_of_business',
  'teams',
];

export const CREATE_RESOURCE_BLOCK_PAYLOAD_KEYS: BlockKey[] = [
  ...CREATE_LIBRARY_BLOCK_PAYLOAD_KEYS,
  'blockLibraryId',
  'canDeleteOnTemplate',
  'section_id',
  'visibility',
];

export const UPDATE_TEMPLATE_BLOCK_PERMISSIONS_KEYS: BlockKey[] = ['canDelete', 'canDeleteOnTemplate', 'canEdit'];
export const UPDATE_LIBRARY_BLOCK_PERMISSIONS_KEYS: BlockKey[] = ['canDelete', 'canEdit', 'canEditOnTemplate'];

export const UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS: BlockKey[] = [
  ...UPDATE_LIBRARY_BLOCK_PERMISSIONS_KEYS,
  'class_of_business',
  'classification',
  'clause_reference',
  'clause_type',
  'content',
  'helperText',
  'linkedDatapoints',
  'name',
  'repeaterIds',
  'shouldInsertPageBreak',
  'teams',
  'title',
  'type',
  'variations',
  'inception_date',
  'expiry_date',
];

export const UPDATE_RESOURCE_BLOCK_PAYLOAD_KEYS: BlockKey[] = [
  ...UPDATE_LIBRARY_BLOCK_PAYLOAD_KEYS,
  'canDeleteOnTemplate',
  'section_id',
  'selectedVariationId',
  'visibility',
];
