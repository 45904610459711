import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useClearToastsOnNavigate = () => {
  const location = useLocation();

  useEffect(() => {
    toast.dismiss();
  }, [location.pathname]);
};
