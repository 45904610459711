import type { LeafPaths } from '@root/@types/types';
import { get } from 'lodash-es';

export const elementTestIdSelectors = {
  drawer: {
    root: 'side-drawer',
    // unique - present only in edit mode
    togglePageBreak: 'toggle-page-break',
    // unique - present only in edit mode
    toggleVariations: 'toggle-variations',
  },
  block: {
    title: 'block-title',
    addNew: 'add-block-icon',
    addCustom: 'add-custom-block',
    addBlockFromLibrary: 'add-block-from-library',
    addClauseFromLibrary: 'add-clause-from-library',
    iconIndicators: {
      // use as starting from
      global: 'block-icon-indicator:',
      variations: 'block-icon-indicator:variations',
      pageBreak: 'block-icon-indicator:page-break',
      linkedBlock: 'block-icon-indicator:linked-library-block',
      blockUsedInTemplates: 'block-icon-indicator:block-used-in-templates',
      visibility: 'block-icon-indicator:visibility',
    },
  },
  variations: {
    // unique - present only in edit mode
    addNew: 'add-variation',
    // unique - replace with block id
    tabsRow: 'variations-tabs-view-{id}',
    // unique - replace with block id
    tabsRowLite: 'variations-tabs-view-lite-{id}',
    //unique - present only when tab is currently visible
    editTabNameButton: 'edit-variation-name',
    //unique - present only when tab is currently visible
    editTabNameInput: 'variation-name-input',
    //unique - present only when tab and menu is currently visible
    tabRemoveButton: 'remove-variation',
    //unique - present only when tab is currently visible
    tabSelectButton: 'select-variation',
    //unique - present only when tab is currently visible
    tabMenu: 'variation-menu',
    //unique - present only when tab and menu is currently visible
    tabDuplicate: 'duplicate-variation',
  },
  inlineEditor: {
    confirm: 'inline-edit-confirm',
    cancel: 'inline-edit-cancel',
  },
  wysiwyg: {
    editor: '.ProseMirror',
    toolbar: {
      insertDatapointButton: 'insert-datapoint-button',
    },
  },
  modal: {
    blockUsedInTemplatesModal: 'block-used-in-templates-modal',
    blockVisibilityWarning: {
      modal: 'block-visibility-warning-modal',
      button: {
        continue: 'block-visibility-warning-modal:continue',
        cancel: 'block-visibility-warning-modal:cancel',
      },
    },
  },
  contract: {
    header: {
      publishingOptions: {
        final: 'PublishingOption:Final',
        draft: 'PublishingOption:Draft',
        archive: 'PublishingOption:Archive',
      },
    },
  },
  overlays: {
    loading: 'loading-overlay',
  },
};

export type ElementTestIdSelectorsPath = LeafPaths<typeof elementTestIdSelectors>;

export const getDataTestId = (elementPath: ElementTestIdSelectorsPath, id?: string | number) => {
  const dataTestId = get(elementTestIdSelectors, elementPath);
  const selector = dataTestId?.startsWith('.') ? 'class^' : 'data-testid';

  if (!dataTestId) {
    throw new Error(`No "data-testid" for element path ${elementPath}`);
  }

  const finalDataTestId = id ? dataTestId.replace('{id}', id.toString()) : dataTestId;

  return {
    toString: () => finalDataTestId,
    asSelector: () => `[${selector}="${finalDataTestId}"]`,
  };
};
