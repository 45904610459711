import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useUserStore } from '@Auth/store';
import { MODALS } from '@constants/index';
import { useEntityStore } from '@ContractBuilder/store';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { groupByParent } from '@helpers/groupBlocksByParent';
import { useModalDispatch } from '@hooks/useModalProvider';
import { ListTypesToggle } from '@pages/User/components/ListTypesToggle';
import type { Action, Team } from '@root/@types/types';
import { useEffectOnce } from '@src/hooks';
import { fetchAllTeams, fetchBlocks } from '@src/queries';

import withMenuLayout from '../../layout/withMenuLayout';

import { getClausesTableColumns } from './helpers/columns/getClausesTableColumns';
import { ReactTable } from './components';
import { useDataIsEmpty } from './hooks';

import './helpers/columns/clauses.css';

const initialFilters = [{ id: 'deleted_at', value: ['Active'] }];

export const Clauses: FC = () => {
  const [isGroupByParent, setIsGroupByParent] = useState(false);
  const { resetState } = useEntityStore(({ resetState }) => ({ resetState }));
  const { user } = useUserStore(({ user }) => ({ user }));
  const { showModal } = useModalDispatch();

  const { data, status } = useQuery(['clauses'], () => fetchBlocks('', { type: 'clause' }), {
    refetchOnWindowFocus: false,
  });
  const { data: teams = [], isLoading: isTeamsLoading } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
  });

  const isEmpty = useDataIsEmpty(data, status);

  const handleCreateBlockClick = () => {
    showModal(MODALS.CREATE_LIBRARY_BLOCK, {});
  };

  useEffectOnce(() => {
    resetState();
  });

  const columns = getClausesTableColumns({ user, teams });

  const actions: Action[] = [
    {
      key: 'create',
      label: 'New Clause',
      onClick: handleCreateBlockClick,
    },
  ];

  const dataGroupedByParents = useMemo(() => groupByParent(data), [data]);
  const clauses = (data?.filter((block) => block.type === 'clause') ?? []) as LibraryBlockTypeForListPage[];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        class_of_business: false,
        id: false,
        last_updated_by: false,
        teams: false,
      }}
      enableExpanding={isGroupByParent}
      entityName="clause"
      filteredData={clauses}
      expandableData={dataGroupedByParents ?? []}
      initialSorting={[{ id: 'updated_at', desc: true }]}
      initialFilters={initialFilters}
      status={status}
      renderCustomElements={() => (
        <ListTypesToggle isGroupByParent={isGroupByParent} setIsGroupByParent={setIsGroupByParent} />
      )}
      filtersBarWrapperClassName="w-full p-3 grid grid-cols-4 gap-x-4 gap-y-2"
      isEmpty={isEmpty}
      isLoading={isTeamsLoading}
    />
  );
};

export const UserClausesPage = withMenuLayout(Clauses);
