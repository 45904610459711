import React from 'react';
import ItemTeams from '@pages/User/components/ItemTeams';
import type { Team } from '@root/@types/types';
import type { CellContext, ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const teamsAccessor = <T extends { teams?: number[] }>(
  columnHelper: ColumnHelper<T>,
  teams: Team[] = [],
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.accessor((row) => row.teams ?? [], {
    id: 'teams',
    header: 'Teams',
    cell: (cellContext: CellContext<T, number[]>) => (
      <ItemTeams teams={teams} currentTeamsIds={cellContext.getValue()} />
    ),
    enableSorting: false,
    filterFn: 'arrIncludesSome',
    meta: {
      headingCellClassName: 'w-44',
      headingLinkClassName: 'pl-0',
      isStandaloneDataCell: true,
      filter: {
        type: 'dropdown',
        placeholder: 'All teams',
        optionsMapping: (Array.isArray(teams) ? teams : []).map((i) => ({
          label: i.name,
          value: i.id,
        })),
        ...filterMeta,
      },
    },
  });
