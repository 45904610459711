import type { FC } from 'react';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import { Show, ShowFirstMatching } from '@components/Show';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit/store';
import { CLAUSE_CLASSIFICATIONS, CLAUSE_TYPES } from '@root/helpers';
import { useLanguage } from '@root/src/language';

import { shouldRenderField } from '../utils/should-render-field';

import { DrawerSection } from './DrawerSection';

interface ClauseDetailsProps {
  isAdminView: boolean;
  isBlockType: boolean;
}

export const ClauseDetails: FC<ClauseDetailsProps> = ({ isAdminView, isBlockType }) => {
  const { formValues, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));

  const { getContent } = useLanguage({
    prefix: `naming.mrc.rightSidebar.${isBlockType ? 'block' : 'clause'}`,
  });

  return (
    <>
      <DrawerSection title={getContent('fields.reference.label')}>
        <InputText
          labelText=""
          placeholder={getContent('fields.reference.placeholder')}
          name="clause_reference"
          value={formValues?.clause_reference}
          onChange={(event) => setFormValues((prev) => ({ ...prev, clause_reference: event.target.value }))}
        />
      </DrawerSection>
      <DrawerSection title={getContent('fields.type.label')}>
        <InputSelect
          labelText=""
          data-testid="clause-type"
          options={CLAUSE_TYPES.map((label) => ({ name: label, value: label }))}
          name="clause_type"
          value={formValues?.clause_type}
          onChange={(value) => setFormValues((prev) => ({ ...prev, clause_type: value }))}
        />
      </DrawerSection>
      <Show when={shouldRenderField(isAdminView, formValues?.classification)}>
        <DrawerSection title={getContent('fields.classification.label')}>
          <ShowFirstMatching>
            <Show when={isAdminView}>
              <InputSelect
                isClearable
                labelText=""
                data-testid="classification"
                options={CLAUSE_CLASSIFICATIONS.map((label) => ({ name: label, value: label }))}
                name="classification"
                value={formValues?.classification}
                onChange={(value) => setFormValues((prev) => ({ ...prev, classification: value }))}
              />
            </Show>
            <Show when={!isAdminView}>
              <p className="text-sm text-info-800">{formValues?.classification}</p>
            </Show>
          </ShowFirstMatching>
        </DrawerSection>
      </Show>
    </>
  );
};
