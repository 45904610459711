import type { FC } from 'react';
import { CONTRACT_VIEW_Z_INDEX } from '@constants/z-indices';
import { EditorToolbarController } from '@ContractBuilder/modules/editor-toolbar';
import { INLINE_BLOCK_EDITOR_NAME } from '@ContractBuilder/modules/editors-map';
import { clsx } from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { animationProps } from '../constants';

interface EditorToolbarProps {
  isEditing: boolean;
  isEndorsementView: boolean;
}

export const EditorToolbar: FC<EditorToolbarProps> = ({ isEditing, isEndorsementView }) => {
  return (
    <AnimatePresence>
      {isEditing && (
        <motion.div
          {...animationProps}
          className={clsx('absolute left-0 top-0 flex w-drawer-leftover bg-white', CONTRACT_VIEW_Z_INDEX)}
        >
          <EditorToolbarController
            currentEditorKey={INLINE_BLOCK_EDITOR_NAME}
            type={isEndorsementView ? 'endorsementSummary' : 'contract'}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
