import type { FC, FormEvent } from 'react';
import { useState } from 'react';
import Button from '@components/Button';
import Form from '@components/Form';
import InputText from '@components/InputText';
import Modal from '@components/Modal';
import { formContainerClasses } from '@features/create-entity-modal/classes';
import { preventDefaultEnterSubmit } from '@helpers/preventDefaultEnterSubmit';
import clsx from 'clsx';
import { startCase } from 'lodash-es';

import type { BlockType, CreateLibraryBlockFormValues } from '../types';
import { createLibraryBlockSchema } from '../validation';

const btnClasses = 'w-full h-[38px]';

interface CreateLibraryBlockModalProps {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: CreateLibraryBlockFormValues) => void;
  type: BlockType;
}

export const CreateLibraryBlockModal: FC<CreateLibraryBlockModalProps> = ({ isLoading, onClose, onSubmit, type }) => {
  const [name, setName] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return onSubmit(createLibraryBlockSchema.parse({ name }));
  };

  return (
    <Modal
      className="w-[32rem]"
      onClose={onClose}
      open
      data-testid="create-block-modal"
      shouldShowCloseIcon={false}
      title={`Create a new ${type}`}
      titleClassName="text-lg leading-6 font-medium !justify-center"
    >
      <div className={clsx(formContainerClasses)}>
        <p className="text-sm font-normal leading-5 text-gray-500">
          Please fill in the following information to setup a {type}
        </p>
        <Form
          className="flex w-full flex-col gap-10"
          onSubmit={handleSubmit}
          aria-label={`Create ${type} form`}
          onKeyDown={preventDefaultEnterSubmit}
        >
          <InputText
            labelText={`${startCase(type)} name`}
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={`New ${type}`}
          />
          <div className="flex items-center gap-2">
            <Button className={clsx(btnClasses)} isDisabled={isLoading} onClick={onClose} kind="secondary" size="sm">
              Cancel
            </Button>
            <Button
              className={clsx(btnClasses)}
              isDisabled={name.length === 0}
              loading={isLoading}
              kind="primary"
              size="sm"
              type="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
