import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useRef } from 'react';
import { useQuery } from 'react-query';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { mdiChevronDown, mdiClose, mdiFilter } from '@mdi/js';
import { Icon } from '@mdi/react';
import { GlobalSearch } from '@pages/User/components';
import { ListTypesToggle } from '@pages/User/components/ListTypesToggle';
import { fetchAllTeams } from '@queries/fetchTeams';
import type { Team } from '@root/@types/types';
import { useEffectOnce } from '@src/hooks';
import { useLanguage } from '@src/language';
import type { ColumnFilter, Table } from '@tanstack/react-table';
import clsx from 'clsx';

import { SearchFiltersDropdown } from './SearchFiltersDropdown';

export interface BlocksLibrarySearchInputProps {
  filters: ColumnFilter[];
  onSearch: (searchInput: string) => void;
  searchText?: string;
  isFetching: boolean;
  isClause: boolean;
  table: Table<LibraryBlockTypeForListPage>;
  isGroupByParent: boolean;
  setIsGroupByParent: Dispatch<SetStateAction<boolean>>;
}

const filterPillClasses = ['flex items-center gap-1 rounded-xl border border-info-400 bg-white px-1.5 py-0.5'];
const filterPillCloseIconClasses = ['mt-0.5 cursor-pointer text-info-500 transition duration-300 hover:text-info-400'];

const allowedFilters = ['type', 'clause_type', 'class_of_business', 'classification', 'teams'];

export const BlocksLibrarySearchInput: FC<BlocksLibrarySearchInputProps> = ({
  filters,
  onSearch,
  isFetching,
  isClause,
  table,
  isGroupByParent,
  setIsGroupByParent,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });
  const { data: allTeams } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
  });

  const focusSearch = () => {
    if (ref.current) {
      ref.current?.focus();
    }
  };

  useEffectOnce(() => {
    focusSearch();
  });

  const hasFilters =
    filters.find(({ id, value }) => id !== 'deleted_at' && ((value as string[])?.length ?? 0) > 0) !== undefined;

  const handleIndividualFilterClear = (filterKey: string, valueToBeCleared: string) => {
    const currentSelectedFilterValues = filters?.find(({ id }) => id === filterKey)?.value ?? [];
    const column = table.getAllColumns().find(({ id }) => id === filterKey);
    const filtersAfterClear = (currentSelectedFilterValues as string[]).filter((value) => value !== valueToBeCleared);
    column?.setFilterValue(filtersAfterClear.length > 0 ? filtersAfterClear : undefined);
  };

  const handleSearchClick = (searchTerm: string) => onSearch(searchTerm);

  const handleClearAllFilters = () => table.setColumnFilters([]);

  return (
    <div className={clsx(DEFAULT_CREATOR_PADDING, 'flex flex-col gap-4')}>
      <div className="flex w-full place-items-center gap-2">
        <div className={'relative flex w-full items-center overflow-clip rounded-md'}>
          <ListTypesToggle
            isGroupByParent={isGroupByParent}
            setIsGroupByParent={setIsGroupByParent}
            className="flex h-10 items-center"
          />
          <GlobalSearch
            inputClassName="appearance-none rounded-md border border-info-300 py-2 text-sm text-info-700 shadow-sm focus:border-primary-500 focus:ring-primary-500 h-10 rounded-r-none bg-white px-10 pl-10"
            wrapperClassName="grow"
            entitiesLabel={isClause ? 'clauses' : 'blocks'}
            onSearchClick={handleSearchClick}
          />
          <div className="relative">
            <SearchFiltersDropdown
              isClause={isClause}
              filters={filters}
              table={table}
              renderTrigger={({ onClick }) => (
                <button
                  className="flex h-10 w-32 items-center justify-between border border-l-0 border-info-200 bg-info-50 p-2 py-3 hover:bg-opacity-75"
                  onClick={onClick}
                >
                  <div data-testid="filter-btn" className="ml-auto flex items-center gap-1">
                    <IconMdi path={mdiFilter} className="text-info-400" />
                    <span className="text-sm font-medium leading-5">Filters</span>
                  </div>
                  <IconMdi className="ml-4" path={mdiChevronDown} />
                </button>
              )}
              isFetching={isFetching}
            />
          </div>
        </div>
      </div>
      <Show when={hasFilters}>
        <div className="ml-1 flex items-start gap-3 border-b border-gray-200 pb-5 text-sm font-medium text-info-900 ">
          FILTERS:
          <div className="flex flex-wrap items-center gap-4">
            {allowedFilters.map(
              (filterKey) =>
                (filters?.find((i) => i.id === filterKey)?.value as string[])?.map((selectedValue) => (
                  <Tooltip key={selectedValue} content="Clear">
                    <button
                      className={clsx(filterPillClasses)}
                      key={`filter-${selectedValue}`}
                      onClick={() => handleIndividualFilterClear(filterKey, selectedValue)}
                    >
                      {filterKey === 'teams' ? (
                        <span>{allTeams?.find((team) => team.id === Number(selectedValue))?.name}</span>
                      ) : (
                        <span>{getContent(selectedValue)}</span>
                      )}
                      <Icon path={mdiClose} className={clsx(filterPillCloseIconClasses)} size={0.5} />
                    </button>
                  </Tooltip>
                )),
            )}
          </div>
          <Show when={hasFilters}>
            <div className="ml-auto">
              <button onClick={handleClearAllFilters}>
                <span className="inline-block w-20">
                  <Icon path={mdiClose} size={0.7} className="inline-block" /> Clear all
                </span>
              </button>
            </div>
          </Show>
        </div>
      </Show>
    </div>
  );
};
