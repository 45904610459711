import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authProvider } from '@helpers/auth';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import { useClearToastsOnNavigate, useEffectOnce } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { useUserStore } from './store';

export const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { authErrorCode, isLoggedIn, setUser } = useUserStore(({ authErrorCode, isLoggedIn, setUser }) => ({
    authErrorCode,
    isLoggedIn,
    setUser,
  }));
  useClearToastsOnNavigate();
  const removeGlobalLoader = useRemoveGlobalLoader();

  useEffectOnce(() => {
    (async () => {
      // if we're already in an error state (from interceptor), then we want to go directly to login => error
      if (authErrorCode && !isLoggedIn) {
        setIsLoading(false);
        return;
      }

      try {
        const currentUserInfo = await authProvider.attemptGetUserInfo(); // set or re-set the user info from the URL params (in case of Entra)
        const hasUserInfo = !isEmpty(currentUserInfo);

        setIsLoading(false);
        setUser(hasUserInfo ? currentUserInfo : null);
      } catch {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    })();
  });

  useEffect(() => {
    if (!isLoading) {
      removeGlobalLoader();
    }
  }, [isLoading, removeGlobalLoader]);

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
          search: new URLSearchParams(location.search).toString(),
        }}
        state={{ from: location }}
      />
    );
  }

  return <Outlet />;
};
