import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Show } from '@components/Show';
import { AddBlockIcon } from '@ContractBuilder/modules/block-creator/components/AddBlockIcon';
import { DrawerLayoutComponent } from '@ContractBuilder/modules/drawer-layout';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { useDrawerStore } from '@ContractBuilder/store/drawer.store';
import { scrollIntoView } from '@root/src/utils/scroll-into-view';
import { AnimatePresence, motion } from 'framer-motion';

import { getElementId } from '../../utils/get-element-id';
import { useBlockEdit, useBlockEditFormStore } from '../block-edit';

import { BlockCreatorView } from './BlockCreatorView';
import { blockCreatorClasses } from './classes';
import { animationProps } from './constants';
import { BlockCreatorContext } from './context';
import type { BlockCreatorStepId } from './types';

interface BlockCreatorControllerProps {
  id: string;
  order: number;
  sectionId: string;
}

export const BlockCreatorController: FC<BlockCreatorControllerProps> = ({ id, order, sectionId }) => {
  const [blockLibraryId, setBlockLibraryId] = useState<string | undefined>(undefined);
  const [isCreating, setIsCreating] = useState(false);

  const { editingBlockId, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    editingBlockId: formValues?.id,
    setFormValues,
  }));

  const { openDrawer, isDrawerOpen } = useDrawerStore(({ openDrawer, isOpen }) => ({
    openDrawer,
    isDrawerOpen: isOpen,
  }));

  const hasEditMode = editingBlockId !== undefined;

  const { onCancel } = useBlockEdit();
  const [step, setStep] = useState<BlockCreatorStepId>('start');

  const resetState = () => {
    setStep('start');
    setBlockLibraryId(undefined);
  };

  const handleAbort = () => {
    setIsCreating(false);
    return resetState();
  };

  const handleCancel = () => {
    onCancel();
    resetState();
    handleAbort();
  };

  useCustomEventListener(ArtificialCustomEvent.ExitBlockCreator, () => {
    return handleAbort();
  });

  useEffect(() => {
    if (step === 'wysiwyg' && !isDrawerOpen) {
      openDrawer({
        props: { onCancel },
        shouldCloseOnClickAway: false,
        type: DrawerLayoutComponent.BlockConfigurationPanel,
      });
    }
    // eslint-disable-next-line -- Only trigger step changes
  }, [step]);

  useEffect(() => {
    if (isCreating) {
      resetState();
      setFormValues({ section_id: sectionId, order, id: '', type: 'mrc-heading', canEdit: true, canDelete: true });
      /**
       * animation takes some time ~500ms and scrollIntoView is not aware of the final height of the element
       * thus we need to delay the scrollIntoView to element take up all the space
       */
      scrollIntoView(getElementId('block-creator', id), { block: 'center' }, 400);
    }
    // eslint-disable-next-line -- Only trigger when switching between edit/create/exit mode
  }, [isCreating]);

  const onPrevStep = () => setStep((s) => (s === 'wysiwyg' ? 'block' : 'start'));
  const handleCreate = () => setIsCreating(true);

  const context = useMemo(
    () => ({
      step,
      setStep,
      sectionId,
      resetState,
      onPrevStep,
      setBlockLibraryId,
      blockLibraryId,
      handleCancel,
      handleAbort,
    }),
    // eslint-disable-next-line -- Ignore function dependencies
    [step, blockLibraryId, sectionId],
  );

  return (
    <BlockCreatorContext.Provider value={context}>
      <AnimatePresence mode="wait">
        {isCreating && (
          <motion.div className={blockCreatorClasses({ step })} {...animationProps}>
            <BlockCreatorView id={id} />
          </motion.div>
        )}
      </AnimatePresence>
      <Show when={!isCreating && !hasEditMode}>
        <AddBlockIcon onCreate={handleCreate} />
      </Show>
    </BlockCreatorContext.Provider>
  );
};
