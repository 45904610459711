import { cva } from 'class-variance-authority';

export const tabClasses = cva(
  [
    'text-sm',
    'h-auto',
    'py-2',
    'px-3',
    'cursor-pointer',
    'border-r',
    'border-info-300',
    'whitespace-nowrap',
    'overflow-hidden',
    'hover:bg-info-100',
    'select-none',
    'flex',
    'gap-2',
    'w-fit',
    'min-w-[10%]',
    'max-w-[245px]',
  ],
  {
    variants: {
      isTabOpen: {
        true: ['bg-info-50', 'flex-shrink-0'],
        false: ['flex-shrink'],
      },
      isSelected: {
        true: [],
        false: [],
      },
    },
  },
);

export const checkMarkIconClasses = cva([], {
  variants: {
    isSelected: {
      true: ['text-primary-500'],
      false: ['text-info-400', 'hover:text-primary-500'],
    },
    isTabOpen: {
      true: ['visible'],
      false: ['hidden'],
    },
  },
});

export const variationsListClasses = cva(['flex', 'flex-col'], {
  variants: {},
});

export const autoSelectedIconClasses = cva(['text-info-400', 'cursor-help'], {
  variants: {
    tabAutoSelected: {
      false: '',
      true: 'text-primary-500',
    },
  },
});
