import { type FC, type ReactNode, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import DropdownChevron from '@components/DropdownChevron';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

interface DrawerCollapsibleGroupProps {
  children?: ReactNode;
  className?: string;
  title?: string;
  id: string;
}

const getAnimationProps = (isInitiallyOpen: boolean) => ({
  initial: {
    height: isInitiallyOpen ? 'auto' : 0,
    opacity: isInitiallyOpen ? 1 : 0,
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.3,
      },
      opacity: {
        duration: 0.3,
        delay: 0.25,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.3,
        delay: 0.25,
      },
      opacity: {
        duration: 0.3,
      },
    },
  },
  transition: {
    ease: 'easeInOut',
  },
});

export const DrawerCollapsibleGroup: FC<DrawerCollapsibleGroupProps> = ({ children, className, title, id }) => {
  const [open, setOpen] = useLocalStorage(`art#cb#${id}`, true);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to check for the **initial** open state
  const animationProps = useMemo(() => getAnimationProps(open ?? true), []);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div
        onClick={toggleOpen}
        className={clsx(
          '-mx-5 flex cursor-pointer items-center justify-between border-t bg-info-50 px-5 py-3',
          open && 'border-b',
          className,
        )}
      >
        <h4 className="text-sm font-medium uppercase leading-5 text-info-800">{title}</h4>
        <DropdownChevron className="rounded-md bg-info-200 text-info-900" open={!open} />
      </div>
      <AnimatePresence>
        {open ? (
          <motion.div {...animationProps} className="my-4 flex flex-col gap-6 text-sm">
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
