import type { FC } from 'react';
import React from 'react';
import { useQuery } from 'react-query';
import { InlineEditableText } from '@components/InlineEditableText';
import InputMultiSelect from '@components/InputMultiSelect';
import InputSelect from '@components/InputSelect';
import Tooltip from '@components/Tooltip';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import { EntityField } from '@ContractBuilder/contract-builder-header/constants';
import type { OnSaveFn } from '@ContractBuilder/contract-builder-header/modules/entity-details/hooks/use-editable-field';
import type { EntityData } from '@ContractBuilder/types';
import { UserGroupIcon } from '@heroicons/react/solid';
import type { Team } from '@root/@types/types';
import { fetchUserTeams } from '@src/queries';
import { isTemplatePath } from '@utils/app-paths';

import { useEditableField } from '../hooks';

interface EditableTeamsFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn<number[]>;
}

const getEntityTeamsDisplay = (userTeams: Team[], teams: number[]) => {
  const teamsLength = teams.length;
  if (teamsLength === 0) {
    return 'All teams';
  }
  if (teamsLength === 1) {
    return userTeams.find((team) => team.id === teams[0])?.name;
  }
  return `${teamsLength} teams`;
};

export const EditableTeamsField: FC<EditableTeamsFieldProps> = ({ entity, hasOperationsDisabled, onSave }) => {
  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField<number[]>({
    defaultValue: entity.teams ?? [],
    name: EntityField.Teams,
    onSave,
  });

  const isTemplate = isTemplatePath();
  const { data: userTeams = [] } = useQuery<Team[]>(['teams'], fetchUserTeams, {
    staleTime: 60000,
  });

  const handleChange = (value?: number | number[]) => {
    if (value) {
      onChange(Array.isArray(value) ? value : [value]);
    }
  };

  return (
    <div className="flex items-center gap-1">
      <Tooltip content={`Team${isTemplate ? 's' : ''}`}>
        <span>
          <UserGroupIcon className="h-4 w-4" />
        </span>
      </Tooltip>
      <InlineEditableText
        editor={
          isTemplate ? (
            <InputMultiSelect
              value={state?.value}
              options={userTeams.map((team) => ({ name: team.name, value: team.id }))}
              onChange={handleChange}
              onSelect={() => {}}
              name="teams"
              isDisabled={false}
              className="max-w-[15rem]"
              menuOuterClassName="min-w-fit"
              selectedItemClassName="-my-4"
              placeholder="Select teams"
              size="sm"
            />
          ) : (
            <InputSelect
              value={state?.value?.[0]}
              options={userTeams.map((team) => ({ name: team.name, value: team.id }))}
              onChange={handleChange}
              name="teams"
              isDisabled={false}
              placeholder="Select team"
              size="sm"
              menuOuterClassName="min-w-fit"
            />
          )
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={getEntityTeamsDisplay(userTeams, entity?.teams ?? [])}
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? `Edit team${isTemplate ? 's' : ''}` : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
