import type { IdentityProvider } from '@root/@types/types';

export type CanonicalUserRecord = {
  /**
   * @deprecated Use `username` instead.
   */
  id: string;
  enabled: boolean;
  /**
   * @deprecated Use `UserLifeCycleStatus` instead.
   *
   * Indicate the status of the user in Cognito.
   *
   * If user record is not from Cognito, the status will be `EXTERNAL_PROVIDER`.
   */
  status: string;
  created_at: string;
  updated_at: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  tenant_ids: string[];
  teams: number[];
  username: string;
  identityProvider?: IdentityProvider;
  /**
   * Indicate from where the user record was sourced.
   *
   * If true, the user record was sourced from DDB and not Cognito.
   */
  isDraftUserRecord: boolean;
};

export interface UserCapabilitiesManager {
  canBeEdited: boolean;
  /**
   * Whether the user could be resent an invite. E.g. if users is in the state that she could be re-invited to sign in.
   */
  canBeResentInvite: boolean;

  /**
   * Whether the user could be removed. E.g. if users is in the state that she could be removed from the system.
   */
  canBeRemoved: boolean;

  /**
   * Whether the user is in Pending state and could be updated as Pended.
   */
  canBeUpdatedAsPended: boolean;

  /**
   * Whether the user could be enabled or disabled in context of Cognito user.
   */
  canBeEnabledOrDisabled: boolean;

  userLifeCycleStatus: UserLifeCycleStatus;
}

export type CanonicalUserRecordWithCapabilities = CanonicalUserRecord & {
  capabilitiesManager: UserCapabilitiesManager;
};

/**
 * User life cycle statuses
 *
 * @description
 *  The status of user within context of the Contract builder application.
 *  Please do not confuse with Cognito user statuses. As Cognito record does not cover entire user life cycle.
 */
export enum UserLifeCycleStatus {
  /**
   * User is active and has access to the application.
   */
  Active = 'Active',
  /**
   * User is pending (e.g. has not yet accessed the application, or has not yet completed the onboarding).
   */
  Pending = 'Pending',
  /**
   * User is disabled and has no access to the application.
   */
  Disabled = 'Disabled',

  /**
   * User is pending due to requirement to change password.
   */
  ResetRequired = 'ResetRequired',
}
