import { Outlet } from 'react-router-dom';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import { useClearToastsOnNavigate, useEffectOnce } from '@src/hooks';

export const PublicRoutes = () => {
  const removeGlobalLoader = useRemoveGlobalLoader();
  useClearToastsOnNavigate();

  useEffectOnce(() => {
    removeGlobalLoader();
  });

  return <Outlet />;
};
