import type { LibraryStepId } from '@ContractBuilder/modules/block-creator/types';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { BlockLibraryCreatorListController } from '@ContractBuilder/modules/block-library-creator-list';
import { useEntityStore } from '@ContractBuilder/store';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';

import { useBlockCreator } from '../../context';
import { StepView } from '../StepView';

const getSecondHeaderTitleByStepId = (stepId: LibraryStepId) => {
  if (stepId === 'library-clause') {
    return 'Search Clause Library';
  }
  return 'Search Block Library';
};

export const StepLibrary = ({ stepId }: { stepId: LibraryStepId }) => {
  const { onPrevStep, handleCancel } = useBlockCreator();
  const { formValues } = useBlockEditFormStore(({ formValues }) => ({ formValues }));
  const { addBlockFromLibrary } = useEntityStore(({ addBlockFromLibrary }) => ({ addBlockFromLibrary }));

  const onInsertBlock = async (blockLibraryId: string, block: LibraryBlockTypeForListPage | null) => {
    await addBlockFromLibrary({
      canDelete: block?.canDelete,
      canEdit: block?.canEdit,
      blockLibraryId,
      sectionId: formValues?.section_id ?? '',
      order: (formValues?.order ?? 0) + 1,
    });

    handleCancel();
  };

  return (
    <StepView stepId={stepId} secondHeaderTitle={getSecondHeaderTitleByStepId(stepId)} onPrev={onPrevStep}>
      <BlockLibraryCreatorListController
        onInsertBlock={onInsertBlock}
        type={stepId === 'library-block' ? 'block' : 'clause'}
      />
    </StepView>
  );
};
