import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import TempContractBanner from '@features/create-entity-modal/components/TempContractBanner';
import type { Team } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';

import type { Step, StepProps } from '../../types';

interface Step1Props extends StepProps {
  currentStep: Step;
  userTeams: Team[];
  isFetchingTeams: boolean;
}

const getRequiredMessage = (message: string = '') => {
  return message === 'Required' ? 'A contract requires a name' : message;
};

export const Step1: FC<Step1Props> = ({ control, currentStep, formValues, userTeams, isFetchingTeams }) => {
  return (
    <>
      <TempContractBanner />
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputText
            {...field}
            errors={getRequiredMessage(error?.message)}
            isDisabled={field.disabled}
            labelText="Contract name"
            placeholder="New Contract"
          />
        )}
        name="name"
      />
      <Controller
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            data-testid="assign-to-team-dropdown"
            errors={error?.message}
            isDisabled={field.disabled || isFetchingTeams}
            isLoading={isFetchingTeams}
            onChange={(value) => field.onChange([value])}
            labelText="Contract team"
            options={userTeams.map(({ id, name }) => ({ name, value: id }))}
            value={field.value?.[0]}
          />
        )}
        name="team"
      />
      <Controller
        control={control}
        key={`step-1-class-of-business-select-${currentStep}`}
        render={({ field, fieldState: { error } }) => (
          <InputSelect
            {...field}
            errors={error?.message}
            isClearable
            isDisabled={field.disabled}
            labelText="Class of business"
            options={ALL_CLASSES_OF_BUSINESS}
            placeholder="Select the class of business"
            // Getting the value from `formValues` as opposed to `Controller` since it does a weird caching which breaks the `Clear selection` logic
            value={formValues?.class_of_business}
          />
        )}
        name="class_of_business"
      />
    </>
  );
};
