import { CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX } from '@constants/z-indices';
import { pagesContainerClasses, pagesSectionsContainerClasses } from '@ContractBuilder/ContractViewClasses';
import { cva } from 'class-variance-authority';

export const contractViewClasses = cva(
  [pagesContainerClasses, 'contract-view-content overflow-y-auto bg-info-50 pt-6'],
  {
    variants: {
      isEditing: { true: 'contract-view-content-shrink', false: '' },
      showHiddenFields: { true: 'show-fields', false: '' },
    },
  },
);

export const contractViewEditingOverlayClasses = cva([
  'remove-interactive-mention pointer-events-none absolute inset-0 bg-info-50 opacity-50',
  CONTRACT_VIEW_EDIT_MODE_OVERLAY_Z_INDEX,
]);

export const contractViewEditingClasses = cva([pagesSectionsContainerClasses, 'flex flex-col gap-20 pb-8 pt-20'], {
  variants: {
    isEditing: { true: 'contract-view-editing', false: '' },
  },
});
