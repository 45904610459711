import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { mdiUnfoldLessHorizontal, mdiUnfoldMoreHorizontal } from '@mdi/js';
import { Icon } from '@mdi/react';
import type { BlockType } from '@root/@types/types';
import { useLanguage } from '@root/src/language';
import sharedClasses from '@root/src/utils/shared-classes';
import { isClausesPath, isSubmissionPath } from '@utils/app-paths';
import clsx from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { Clause } from './svg/Clause';
import { OneColumn } from './svg/OneColumn';
import { TwoColumn } from './svg/TwoColumn';
import { layoutSelectorClasses } from './classes';
import { SelectorOption } from './SelectorOption';
import type { BlockLayoutOption, LayoutOptionsProps } from './types';
import { findOptionWithFallback } from './utils';

const getLayoutOptions = (
  isContractPage: boolean,
  isClausesPage: boolean,
  getContent: (key: string) => string,
  type: BlockType,
  showAllOptions: boolean,
): BlockLayoutOption[] => {
  const MRC_HEADING_BLOCK_OPTION: BlockLayoutOption = {
    type: 'mrc-heading',
    title: getContent('mrc-heading'),
    element: <TwoColumn />,
  };

  const CLAUSE_BLOCK_OPTION: BlockLayoutOption = {
    type: 'clause',
    title: getContent('clause'),
    element: <Clause />,
  };

  const GENERIC_BLOCK_OPTION: BlockLayoutOption = {
    type: 'generic',
    title: getContent('generic'),
    element: <OneColumn />,
  };

  if (isContractPage || showAllOptions) {
    return [MRC_HEADING_BLOCK_OPTION, CLAUSE_BLOCK_OPTION, GENERIC_BLOCK_OPTION];
  }

  if (isClausesPage || type === 'clause') {
    return [CLAUSE_BLOCK_OPTION];
  }

  return [MRC_HEADING_BLOCK_OPTION, GENERIC_BLOCK_OPTION];
};

const LayoutOptions: FC<LayoutOptionsProps> = ({ isDisabled, onChange, showAllOptions = false, type }) => {
  const isContractPage = isSubmissionPath();
  const isClausesPage = isClausesPath();
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const options: BlockLayoutOption[] = useMemo(
    () => getLayoutOptions(isContractPage, isClausesPage, getContent, type, showAllOptions),
    [getContent, isContractPage, isClausesPage, type, showAllOptions],
  );

  const [selectedLayout, setSelectedLayout] = useState(findOptionWithFallback(options, type));

  useDeepCompareEffect(() => {
    setSelectedLayout(findOptionWithFallback(options, type));
  }, [options, type]);

  const handleChange = (value: BlockType) => {
    const option = findOptionWithFallback(options, value);
    setSelectedLayout(option);
    onChange(option.type);
  };

  return (
    <Listbox disabled={isDisabled} value={selectedLayout.type} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change block layout</Listbox.Label>
          <div className="relative">
            <div className="inline-flex w-full rounded-md shadow-sm">
              <div
                className={clsx(
                  'relative z-0 inline-flex w-full rounded-md shadow-sm',
                  isDisabled && 'cursor-not-allowed',
                )}
              >
                <Listbox.Button
                  data-testid="block-layout-list"
                  className={clsx(layoutSelectorClasses.button, isDisabled && sharedClasses.disabled)}
                >
                  <SelectorOption option={selectedLayout} />
                  <span className="sr-only">Change block layout</span>
                  <Icon
                    path={open ? mdiUnfoldLessHorizontal : mdiUnfoldMoreHorizontal}
                    className="text-info-400"
                    size={0.8}
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={clsx(layoutSelectorClasses.optionsContainer)}>
                {options.map((option) => (
                  <Listbox.Option
                    key={option.type}
                    className={({ active }) =>
                      clsx(
                        active ? 'bg-primary-50 text-white' : 'text-info-900',
                        'relative h-fit cursor-default select-none list-none p-2 text-sm',
                      )
                    }
                    value={option.type}
                    data-testid={`LayoutSelector:${option.type}`}
                  >
                    {({ selected, active }) => <SelectorOption option={option} active={active} selected={selected} />}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LayoutOptions;
