import { createContext, useContext } from 'react';

import type { StateValue } from '../types';

interface SectionsVisibilityContextType {
  onViewChange: (inView: boolean, entry: IntersectionObserverEntry, sectionId: string) => void;
}

export const SectionsVisibilityContext = createContext<SectionsVisibilityContextType>({
  onViewChange: () => {},
});

interface ActiveSectionContextType {
  activeSectionId: string | undefined;
  sections: StateValue;
}

export const ActiveSectionContext = createContext<ActiveSectionContextType>({
  activeSectionId: undefined,
  sections: {},
});

export const useSectionsVisibility = () => useContext(SectionsVisibilityContext);

export const useActiveSection = () => useContext(ActiveSectionContext);
