import type { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBlockActions } from '@ContractBuilder/modules/block-edit/hooks/use-block-actions';
import { useEntityStore } from '@ContractBuilder/store/entity.store';
import type { UpdateBlockInLibraryPayload } from '@root/@types/types';
import Nav from '@root/src/routes';
import { isClausesPath } from '@root/src/utils/app-paths';

import type { CreateLibraryBlockFormValues } from '../types';
import { CreateLibraryBlockModal } from '../views/CreateLibraryBlockModal';

interface CreateLibraryBlockControllerProps {
  handleClose: () => void;
}

export const CreateLibraryBlockController: FC<CreateLibraryBlockControllerProps> = ({ handleClose }) => {
  const isClauses = isClausesPath();

  const isLoading = useEntityStore((state) => state.isLoading);
  const { createBlockInLibrary } = useBlockActions();
  const navigate = useNavigate();

  const handleSubmit = async (data: CreateLibraryBlockFormValues) => {
    try {
      const payload: UpdateBlockInLibraryPayload = {
        ...data,
        content: '',
        type: isClauses ? 'clause' : 'mrc-heading',
      };

      const id = await createBlockInLibrary(payload);

      const basePath = isClauses ? Nav.Clause : Nav.Block;
      return navigate(generatePath(basePath, { id }));
    } catch (error) {
      toast.error(`Unable to create ${isClauses ? 'clause' : 'block'}`);
    }
  };

  return (
    <CreateLibraryBlockModal
      isLoading={isLoading}
      onClose={handleClose}
      type={isClauses ? 'clause' : 'block'}
      onSubmit={handleSubmit}
    />
  );
};
