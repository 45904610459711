import LeftSidebar from '@ContractBuilder/components/LeftSidebar';
import { NavigationProvider } from '@ContractBuilder/modules/navigation';

import { useActiveSection } from '../context';

export const Navigation = () => {
  const { activeSectionId } = useActiveSection();

  return (
    <NavigationProvider>
      <LeftSidebar activeSectionId={activeSectionId} />
    </NavigationProvider>
  );
};
