import type { FC } from 'react';
import { Show } from '@components/Show';
import { TemplatePlaceholder } from '@ContractBuilder/components/TemplatePlaceholder';
import { NavigationProvider } from '@ContractBuilder/modules/navigation';
import type { ResourceSection } from '@root/@types/base';
import { isTemplatePath } from '@root/src/utils/app-paths';

interface EmptyTemplatePlaceholderProps {
  submissionSections: ResourceSection[];
}

export const EmptyTemplatePlaceholder: FC<EmptyTemplatePlaceholderProps> = ({ submissionSections }) => {
  const isTemplate = isTemplatePath();

  return (
    <Show when={isTemplate && !submissionSections.length}>
      <NavigationProvider>
        <TemplatePlaceholder />
      </NavigationProvider>
    </Show>
  );
};
