import type { FC } from 'react';
import Icon from '@components/Icon';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import type { SvgIconName } from '@components/Svg';
import { mdiArrowRight } from '@mdi/js';
import { twMerge } from 'tailwind-merge';

interface CardProps {
  className?: string;
  description: string;
  cta: string;
  onClick?: () => void;
  /**
   * mdi icon path
   */
  icon?: string;
  svgName?: SvgIconName;
  'data-testid'?: string;
}

export const StepperCard: FC<CardProps> = ({ cta, onClick, className, description, icon, svgName, ...props }) => {
  return (
    <div
      onClick={onClick}
      className={twMerge(
        'inline-flex h-32 w-52 cursor-pointer flex-col items-start justify-start rounded-lg bg-white shadow',
        className,
      )}
      {...props}
    >
      <div className="inline-flex items-start justify-start gap-5 self-stretch px-4 pb-6 pt-4">
        <div className="relative flex h-10 w-10 items-center justify-center rounded-md bg-primary-600">
          <Show when={!!icon}>
            <IconMdi path={icon!} className="relative h-6 w-6 text-white" />
          </Show>
          <Show when={!!svgName}>
            <div>
              <Icon name={svgName!} className="text-white" />
            </div>
          </Show>
        </div>
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start">
          <div className="self-stretch text-sm leading-tight text-info-900">{description}</div>
        </div>
      </div>
      <div className="flex h-12 grow  flex-col items-start justify-center self-stretch bg-info-50 px-4 py-3">
        <div className="inline-flex items-center justify-start">
          <div className="flex items-center gap-1 text-sm font-medium leading-normal text-primary-700 transition-all ease-in-out hover:gap-2">
            {cta} <IconMdi path={mdiArrowRight} />
          </div>
        </div>
      </div>
    </div>
  );
};
