import { useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useClickAway, useUnmount } from 'react-use';
import { Show, ShowFirstMatching } from '@components/Show';
import { BlockEditProvider, useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { getUpdateRelevantFields } from '@ContractBuilder/modules/block-edit/utils/get-update-relevant-fields';
import { EditorsMapProvider } from '@ContractBuilder/modules/editors-map';
import { VariationsTabsProvider } from '@ContractBuilder/modules/variations-tabs';
import { fetchBlock } from '@queries/fetchBlock';
import { isBlocksPath } from '@root/src/utils/app-paths';
import { isEqual } from 'lodash';

import { UNSAVED_CHANGES_PROMPT } from '../constants';
import { LibraryBlockEditLayout } from '../views/LibraryBlockEditLayout';
import { LoadingSkeleton } from '../views/LoadingSkeleton';

const isHTMLAnchorElement = (element: HTMLElement): element is HTMLAnchorElement => {
  return element.tagName === 'A';
};

export const LibraryBlockEditController = () => {
  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isBlock = isBlocksPath();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['library-block', id],
    queryFn: () => fetchBlock(id as string),
    onSuccess: (data) => {
      setEditingBlock(data.data);
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const {
    formValues: state,
    canNavigate,
    setEditingBlock,
    reset,
  } = useBlockEditFormStore(({ formValues, setEditingBlock, reset }) => {
    const original = getUpdateRelevantFields(data?.data);
    const next = getUpdateRelevantFields(formValues);

    return {
      formValues,
      setEditingBlock,
      reset,
      canNavigate: isEqual(original, next),
    };
  });

  useUnmount(() => {
    reset();
  });

  useClickAway(ref, (event) => {
    if (!canNavigate) {
      const target = event.target as HTMLElement;

      if (isHTMLAnchorElement(target)) {
        const userConfirmed = window.confirm(UNSAVED_CHANGES_PROMPT);

        if (userConfirmed) {
          const href = target.getAttribute('href');

          if (href) {
            navigate(href);
          }
        }
      }
    }
  });

  const handleUpdate = async () => {
    await refetch();
    toast.success(`${isBlock ? 'Block' : 'Clause'} updated successfully`, { autoClose: 3000 });
  };

  return (
    <EditorsMapProvider>
      <BlockEditProvider hideUsedInTemplatesModal>
        <VariationsTabsProvider>
          <ShowFirstMatching>
            <Show when={isLoading || !data?.data || !state?.id}>
              <LoadingSkeleton />
            </Show>
            <Show when={true}>
              <div className="h-full w-full" ref={ref}>
                <LibraryBlockEditLayout canNavigate={canNavigate} data={data?.data} onUpdate={handleUpdate} />
              </div>
            </Show>
          </ShowFirstMatching>
        </VariationsTabsProvider>
      </BlockEditProvider>
    </EditorsMapProvider>
  );
};
