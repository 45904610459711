import type { EntityData } from '@ContractBuilder/types';

import type { StateValue } from '../types';

export const getInitialState = (submission?: EntityData): StateValue => {
  if (!submission?.sections) {
    return {};
  }

  return submission.sections.map(({ id }) => id).reduce((acc, id) => ({ ...acc, [id]: false }), {});
};
