import { CognitoUserStatus } from '@root/@types/cognito';

import type { CanonicalUserRecord, UserCapabilitiesManager } from './types';
import { UserLifeCycleStatus } from './types';
import { getUserLifeCycleStatus } from './user-life-cycle';

export class UserCapabilityManager implements UserCapabilitiesManager {
  constructor(private readonly user: CanonicalUserRecord) {}

  get canBeEdited() {
    return this.user.status !== CognitoUserStatus.ForceChangePassword;
  }

  get canBeResentInvite() {
    return this.user.status === CognitoUserStatus.ForceChangePassword;
  }

  get canBeRemoved() {
    return getUserLifeCycleStatus(this.user) === UserLifeCycleStatus.Pending;
  }

  get canBeUpdatedAsPended() {
    return this.canBeResentInvite || this.canBeRemoved;
  }

  get canBeEnabledOrDisabled() {
    return getUserLifeCycleStatus(this.user) !== UserLifeCycleStatus.Pending;
  }

  get userLifeCycleStatus() {
    return getUserLifeCycleStatus(this.user);
  }
}
