export enum ArtificialCustomEvent {
  CreateNewCommentsThread = 'create_new_comments_thread',
  ToggleCommentsVisibility = 'toggle_comments_visibility',
  ToggleExpandSections = 'toggle_expand_sections',
  ExitBlockCreator = 'exit_block_creator',
  SubmitInlineBlockEdit = 'submit_inline_block_edit',
  SubmitAsNewInlineBlockEdit = 'submit_as_new_inline_block_edit',
  SetDocumentFocussedCandidate = 'set_document_focussed_candidate',
  SetFocussedBlockId = 'set_focussed_block_id',
}

export type BaseEvent<Name extends string, Payload> = CustomEvent<{ operation: Name; value: Payload }>;

export type CreateNewCommentsThreadEvent = BaseEvent<ArtificialCustomEvent.CreateNewCommentsThread, string>;
export type ExitBlockCreatorEvent = BaseEvent<ArtificialCustomEvent.ExitBlockCreator, undefined>;
export type SetDocumentFocussedCandidate = BaseEvent<
  ArtificialCustomEvent.SetDocumentFocussedCandidate,
  string | undefined
>;
export type SubmitAsNewInlineBlockEditEvent = BaseEvent<
  ArtificialCustomEvent.SubmitAsNewInlineBlockEdit,
  { name: string }
>;
export type SubmitInlineBlockEditEvent = BaseEvent<ArtificialCustomEvent.SubmitInlineBlockEdit, never>;
export type ToggleCommentsVisibilityEvent = BaseEvent<
  ArtificialCustomEvent.ToggleCommentsVisibility,
  { isOpen: boolean; blockId?: string }
>;
export type ToggleExpandSectionsEvent = BaseEvent<ArtificialCustomEvent.ToggleExpandSections, boolean>;
export type SetFocussedBlockEvent = BaseEvent<ArtificialCustomEvent.SetFocussedBlockId, string>;

// Union for all custom Artificial events
export type ArtificialCustomEventUnion =
  | CreateNewCommentsThreadEvent
  | ExitBlockCreatorEvent
  | SetDocumentFocussedCandidate
  | SetFocussedBlockEvent
  | SubmitAsNewInlineBlockEditEvent
  | SubmitInlineBlockEditEvent
  | ToggleCommentsVisibilityEvent
  | ToggleExpandSectionsEvent;

export type ArtificialOperation = ArtificialCustomEventUnion['detail']['operation'];
export type ArtificialEventDetail = ArtificialCustomEventUnion['detail'];

export type EventOfType<T extends ArtificialOperation> = Extract<
  ArtificialCustomEventUnion,
  { detail: { operation: T } }
>;
export type GetEventValue<E extends ArtificialCustomEventUnion> = E['detail']['value'];
