import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { useCurrentRevision } from '@ContractBuilder/modules/revision-history';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { useModalDispatch } from '@hooks/useModalProvider';
import { mdiCardsOutline } from '@mdi/js';
import { MODALS } from '@src/constants';

interface RevisionBannerProps {
  canSaveOrRestoreRevision: boolean;
  onConfirmRestoreRevision: () => Promise<void>;
}

export const RevisionBanner: FC<RevisionBannerProps> = ({ canSaveOrRestoreRevision, onConfirmRestoreRevision }) => {
  const { showModal } = useModalDispatch();
  const { isCurrentRevision, revisionName } = useCurrentRevision();

  const toggleRestoreRevisionModalOpen = () => {
    showModal(MODALS.RESTORE_REVISION, {
      handleConfirm: onConfirmRestoreRevision,
    });
  };

  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex w-full items-center justify-center">
        <div className="flex-shrink-0">
          <IconMdi path={mdiCardsOutline} data-testid="select-revision" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-info-600">{`You are viewing: ${revisionName}`}</p>
        </div>
        {canSaveOrRestoreRevision && !isCurrentRevision && (
          <>
            <div className="flex-shrink-0">
              <ArrowRightIcon className="mx-2 h-4 w-4 bg-blue-50 text-info-600 transition-colors focus:outline-none" />
            </div>
            <div className="text-sm font-medium text-info-900">
              <button className="underline" onClick={toggleRestoreRevisionModalOpen}>
                Restore this version
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
