import React, { useEffect, useMemo, useState } from 'react';
import { InlineEditableText } from '@components/InlineEditableText';
import { Show, ShowFirstMatching } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiAlertCircleOutline } from '@mdi/js';
import InputText from '@root/src/components/InputText';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { TOOLBAR_HEIGHT } from '../../../settings';
import { useBlockEditFormStore } from '../../block-edit';

interface DrawerBlockNameProps {
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  className?: string;
}

interface DrawerBlockNameState {
  edit: boolean;
  value?: string;
}

const NOT_EMPTY = `Name cannot be empty`;

export const DrawerBlockName = ({ className, isDisabled }: DrawerBlockNameProps) => {
  const { formValues, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    formValues,
    setFormValues,
  }));
  const name = formValues?.name;

  const [state, setState] = useState<DrawerBlockNameState>({ edit: false, value: name });

  useEffect(() => {
    if (formValues) {
      setState({ edit: false, value: formValues.name });
    }
    // eslint-disable-next-line -- We only care about id of the block changing i.e. swapping blocks in edit mode
  }, [formValues?.id]);

  const handleClick = () => {
    setState((s) => ({ ...s, edit: true }));
  };

  const handleCancel = () => {
    setState({ edit: false, value: name });
  };

  const handleConfirm = () => {
    setFormValues((prev) => ({ ...prev, name: state.value ?? '' }));
    setState((s) => ({ ...s, edit: false }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState((s) => ({ ...s, value }));
  };

  const { error } = useMemo(() => {
    if (!state.value?.trim()) {
      return { error: NOT_EMPTY };
    }

    return {
      error: null,
    };
  }, [state.value]);

  return (
    <div
      className={twMerge(
        '-mx-5',
        '-mt-[21px]',
        'px-5',
        'flex',
        'items-center',
        'justify-between',
        TOOLBAR_HEIGHT,
        className,
      )}
    >
      <div className="w-full">
        <InlineEditableText
          buttonDataTestId="block-name-inline-edit-icon"
          editor={
            <InputText
              value={state.value}
              onChange={handleChange}
              name="name"
              isDisabled={false}
              inputClassName={clsx('pl-6', error && '!border-error-500 focus:outline-error-500')}
              placeholder="Block name"
              startAdornment={
                <Show when={!!error}>
                  <div className="absolute left-1.5 top-3 text-error-500">
                    <TooltipedIcon
                      content={NOT_EMPTY}
                      path={mdiAlertCircleOutline}
                      className=" text-error-500"
                      placement="left"
                    />
                  </div>
                </Show>
              }
            />
          }
          editMode={state.edit}
          disabled={isDisabled}
          display={
            <span
              className="relative w-11/12 overflow-hidden text-ellipsis whitespace-nowrap"
              data-testid="block-name-display-value"
            >
              <ShowFirstMatching>
                <Show when={!!state.value}>{state.value}</Show>
                <Show when={!state.value}>
                  <div className="flex items-center gap-1">
                    <div className="text-error-500">
                      <TooltipedIcon
                        content={NOT_EMPTY}
                        path={mdiAlertCircleOutline}
                        className="text-error-500"
                        placement="left"
                      />
                    </div>
                    <span className="text-info-300">Block name</span>
                  </div>
                </Show>
              </ShowFirstMatching>
            </span>
          }
          onClick={handleClick}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          confirmDisabled={!!error}
        />
      </div>
    </div>
  );
};
