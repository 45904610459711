import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useUserStore } from '@Auth/store';
import { useEntityStore } from '@ContractBuilder/store';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { groupByParent } from '@helpers/groupBlocksByParent';
import { useModalDispatch } from '@hooks/useModalProvider';
import type { Action, Team } from '@root/@types/types';
import MODALS from '@root/src/constants/modals';
import { useEffectOnce } from '@src/hooks';
import { fetchAllTeams, fetchBlocks } from '@src/queries';

import withMenuLayout from '../../layout/withMenuLayout';

import { ListTypesToggle } from './components/ListTypesToggle';
import { getBlocksTableColumns } from './helpers/columns/getBlocksTableColumns';
import { ReactTable } from './components';
import { useDataIsEmpty } from './hooks';

const initialFilters = [{ id: 'deleted_at', value: ['Active'] }];

export const Blocks: React.FC = () => {
  const [isGroupByParent, setIsGroupByParent] = useState(false);
  const { resetState } = useEntityStore(({ resetState }) => ({ resetState }));
  const { user } = useUserStore(({ user }) => ({ user }));
  const { showModal } = useModalDispatch();

  const { data, status } = useQuery(['blocks'], () => fetchBlocks(''), {
    refetchOnWindowFocus: false,
  });
  const { data: teams = [], isLoading: isTeamsLoading } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
    staleTime: 60000,
  });

  const isEmpty = useDataIsEmpty(data, status);

  const handleCreateBlockClick = () => {
    showModal(MODALS.CREATE_LIBRARY_BLOCK, {});
  };

  useEffectOnce(() => {
    resetState();
  });

  const columns = getBlocksTableColumns({ user, teams });

  const actions: Action[] = [
    {
      key: 'create',
      label: 'New Block',
      onClick: handleCreateBlockClick,
    },
  ];

  const dataGroupedByParents = useMemo(() => groupByParent(data), [data]);

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        class_of_business: false,
        id: false,
        last_updated_by: false,
        teams: false,
        title: false,
      }}
      enableExpanding={isGroupByParent}
      entityName="block"
      filteredData={data as LibraryBlockTypeForListPage[]}
      expandableData={dataGroupedByParents ?? []}
      initialFilters={initialFilters}
      initialSorting={[{ id: 'updated_at', desc: true }]}
      status={status}
      filtersBarWrapperClassName="w-full p-3 grid grid-cols-3 gap-x-4 gap-y-2"
      renderCustomElements={() => (
        <ListTypesToggle isGroupByParent={isGroupByParent} setIsGroupByParent={setIsGroupByParent} />
      )}
      isEmpty={isEmpty}
      isLoading={isTeamsLoading}
    />
  );
};

export const UserBlocksPage = withMenuLayout(Blocks);
