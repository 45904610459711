import type { ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

interface StatusPillProps {
  content: string | ReactNode;
  className?: string;
  maxWidth?: string | number;
  variant?: 'active' | 'inactive' | 'archived';
}

const classes = {
  base: [
    'text-xs',
    'inline-flex',
    'gap-1.5',
    'items-center',
    'px-3',
    'py-1',
    'h-5',
    'bg-white',
    'text-info-900',
    'border',
    'border-info-200',
    'rounded-full',
    'text-xs',
    'leading-4',
    'font-medium',
  ],
};

const statusClasses = cva('h-1.5 w-1.5 rounded-full', {
  variants: {
    variant: {
      active: 'bg-emerald-500',
      inactive: 'bg-info-300',
      archived: 'bg-info-300',
    },
  },
});
export const StatusPill = ({ content, className, maxWidth = 100, variant = 'active' }: StatusPillProps) => (
  <div className={clsx(classes.base, className)}>
    <div className={statusClasses({ variant })} />
    <span style={{ maxWidth }}>{content}</span>
  </div>
);
