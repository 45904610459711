import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import ItemUsedWithin from '@pages/User/components/ItemUsedWithin';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';

export const usedInTemplatesAccessor = <T extends LibraryBlockTypeForListPage>(columnHelper: ColumnHelper<T>) =>
  columnHelper.accessor((row) => row.usedInTemplates, {
    id: 'usedInTemplates',
    enableColumnFilter: false,
    enableSorting: false,
    header: 'Used within',
    cell: (cellContext: CellContext<T, unknown>) => <ItemUsedWithin item={cellContext.row.original} />,
    meta: {
      dataCellClassName: 'group',
      headingCellClassName: 'w-32',
    },
  });
