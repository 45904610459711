import type { EntityData } from '@ContractBuilder/types';
import type { ClauseTypes } from '@root/@types/resources';
import type { BlockMentionValues, ClauseListKey, GroupedClauses, ResourceBlock } from '@root/@types/types';
import { CLAUSE_TYPES } from '@root/helpers';
import { getAllBlocks } from '@root/helpers/blocks';
import { filter, flow, groupBy, isEmpty, map, partialRight, reduce } from 'lodash-es';

export const keyMap: Record<ClauseTypes, ClauseListKey> = {
  Condition: 'list_of_condition',
  Endorsement: 'list_of_endorsement',
  Exclusion: 'list_of_exclusion',
  Wording: 'list_of_wording',
  'Express Warranties': 'list_of_express_warranties',
  'Standard Warranties': 'list_of_standard_warranties',
};

const CLAUSE_NAME_FALLBACK = 'Untitled clause';

export const getClauseListEntryContent = ({ clause_reference, name }: ResourceBlock) => {
  return clause_reference ? `${clause_reference} - ${name || CLAUSE_NAME_FALLBACK}` : name || CLAUSE_NAME_FALLBACK;
};

export const getBlockDeletedCopy = (name: string) => `<p>${name}&nbsp;has hereby been removed</p>`;

export const getClauseTypeForClauseListKey = (value: ClauseListKey): ClauseTypes | undefined => {
  const keys = Object.keys(keyMap) as ClauseTypes[];
  return keys.find((key) => keyMap[key] === value);
};

export const getClauseList = (
  blocks: ResourceBlock[] = [],
  type: ClauseTypes,
  fallback = `No ${type.toLocaleLowerCase()} specified`,
): string => {
  if (blocks.length === 0) return fallback;

  return [
    '<ul>',
    ...blocks.map((block) => [`<li>`, getClauseListEntryContent(block), ', as attached.', '</li>'].join('')),
    '</ul>',
  ].join('');
};

export const getClausesAsMentions = (clauses: GroupedClauses | {}): BlockMentionValues => {
  if (isEmpty(clauses)) return {};

  const lists: Record<ClauseListKey, string> = flow(
    partialRight(map, (type: ClauseTypes) => ({
      // @ts-expect-error
      [keyMap[type]]: getClauseList(clauses[type], type),
    })),
    partialRight(reduce, (acc: any, next: any) => ({ ...acc, ...next }), {}),
  )(CLAUSE_TYPES);

  return lists;
};

export const getGroupedClauses = (entity?: EntityData): GroupedClauses => {
  if (!entity?.sections) {
    return {} as GroupedClauses;
  }

  return flow(
    partialRight(getAllBlocks),
    partialRight(filter, (block: ResourceBlock) => !block.deleted_at && block.type === 'clause'),
    partialRight(groupBy, 'clause_type'),
  )(entity);
};
