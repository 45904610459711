import { Show } from '@components/Show';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import type { CellContext, ColumnHelper, ColumnMeta } from '@tanstack/react-table';

export const toggleSubRowsDisplay = <T extends { last_updated_by?: string }>(
  columnHelper: ColumnHelper<T>,
  filterMeta: ColumnMeta<T, string>['filter'] = {},
) =>
  columnHelper.display({
    id: 'toggleSubRows',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<T, unknown>) => {
      if (cellContext.row.getCanExpand()) {
        return (
          <div className="flex-shrink-0 cursor-pointer px-2 py-4" data-testid="toggleExpandChildrenBlocks">
            <Show when={cellContext.row.getIsExpanded()}>
              <ChevronDownIcon
                onClick={cellContext.row.getToggleExpandedHandler()}
                className="mx-2 h-4 w-4 text-info-600 transition-colors focus:outline-none"
              />
            </Show>
            <Show when={!cellContext.row.getIsExpanded()}>
              <ChevronRightIcon
                onClick={cellContext.row.getToggleExpandedHandler()}
                className="mx-2 h-4 w-4 text-info-600 transition-colors focus:outline-none"
              />
            </Show>
          </div>
        );
      }
      return null;
    },
    meta: {
      dataCellClassName:
        'relative !w-10 truncate whitespace-nowrap !pl-0 !pr-0 !py-0 text-right align-top text-sm font-medium',
      headingCellClassName: 'relative !w-10 py-3.5 !pl-0 !pr-0',
      filter: filterMeta,
    },
  });
