import type { FC, MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import type { DraggableProvidedDragHandleProps, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import Icon from '@components/Icon';
import { useMenu } from '@components/menu';
import { Show } from '@components/Show';
import StrictModeDroppable from '@components/StrictModeDroppable';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { ArtificialCustomEvent, useCustomEventListener } from '@ContractBuilder/modules/events';
import { DatapointsProgressIndicator } from '@ContractBuilder/modules/navigation/modules/nav-section/views/DatapointsProgressIndicator';
import { NavSectionBlocks } from '@ContractBuilder/modules/navigation/modules/nav-section/views/NavSectionBlocks';
import { SectionMenu } from '@ContractBuilder/modules/navigation/modules/nav-section/views/SectionMenu';
import { SectionTitle } from '@ContractBuilder/modules/navigation/modules/nav-section/views/SectionTitle';
import type { Section } from '@ContractBuilder/types';
import { areSectionBlocksValid } from '@ContractBuilder/utils/block-validation';
import { useBlockHighlight } from '@ContractBuilder/utils/use-block-highlight';
import { ChevronDownIcon, ChevronRightIcon, SwitchVerticalIcon as DragAndDropIcon } from '@heroicons/react/outline';
import { getSortedEntries, isNonNullish } from '@root/helpers';
import { scrollIntoView } from '@root/src/utils/scroll-into-view';
import { isEndorsementViewPath } from '@utils/app-paths';
import clsx from 'clsx';

const visibleOnHoverClasses = ['group-hover:opacity-100', 'opacity-0'];

interface NavSectionProps {
  activeSection?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isDragDisabled: boolean;
  section: Section;
  shouldDisableOperations: boolean;
}

export const NavSectionController: FC<NavSectionProps> = ({
  activeSection,
  dragHandleProps,
  isDragDisabled,
  section,
  shouldDisableOperations,
}) => {
  const { blocks, id: sectionId } = section;

  const [open, setOpen] = useState(false);
  const editingBlockId = useBlockEditFormStore((state) => state.formValues?.id);
  const isEndorsementView = isEndorsementViewPath();

  const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useMenu();

  useCustomEventListener(ArtificialCustomEvent.ToggleExpandSections, (value) => setOpen(value));

  useEffect(() => {
    if (editingBlockId) {
      const belongsToSection = section.blocks.some((block) => block.id === editingBlockId);

      if (belongsToSection) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line -- We only want to open the section when the editingBlockId changes, not when the blocks get updated
  }, [editingBlockId]);

  const blocksLength = blocks?.length ?? 0;
  const areBlocksEmpty = blocksLength === 0;

  useEffect(() => {
    if (blocksLength === 0) {
      setOpen(false);
    }
  }, [blocksLength]);

  const closeList: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();
    return setOpen(false);
  };

  const openList: MouseEventHandler<SVGSVGElement> = (event) => {
    event.stopPropagation();

    if (blocksLength) {
      setOpen(true);
    }
  };

  const { onClick, onMouseEnter, onMouseLeave } = useBlockHighlight();
  const IconComponent = open ? ChevronDownIcon : ChevronRightIcon;

  return (
    <StrictModeDroppable droppableId={sectionId}>
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
        return (
          <div {...provided.droppableProps} className="mx-auto w-full select-none pl-2 text-sm" ref={provided.innerRef}>
            <div
              className={clsx(
                'group flex w-full place-items-center gap-x-2 px-1 py-2',
                'rounded',
                !areBlocksEmpty && 'cursor-pointer hover:bg-info-50',
              )}
              onClick={() => scrollIntoView(sectionId)}
              title={
                areBlocksEmpty ? 'Section is empty. First create block in this section' : 'Click to scroll to section'
              }
            >
              <IconComponent
                className={clsx(
                  'h-5 w-3 shrink-0 text-info-800',
                  !areBlocksEmpty && 'cursor-pointer',
                  areBlocksEmpty && 'pointer-events-none opacity-25',
                )}
                onClick={open ? closeList : openList}
              />
              <div className="relative">
                <Icon
                  name="layout"
                  className={clsx(
                    'align-center inline-flex h-6 w-6 items-center rounded p-1',
                    activeSection === sectionId ? 'text-primary-600' : 'text-info-500',
                    areBlocksEmpty && 'opacity-25',
                  )}
                />
                <Show when={!isEndorsementView}>
                  <DatapointsProgressIndicator isValid={areSectionBlocksValid(section)} />
                </Show>
              </div>
              <SectionTitle title={section.label} areBlocksEmpty={areBlocksEmpty} />
              <Show when={!shouldDisableOperations}>
                <div className="relative inline-flex items-center">
                  <Show when={!isNonNullish(dragHandleProps) && !isDragDisabled}>
                    <div
                      className={clsx('h-6 w-6 group-hover:text-info-800', visibleOnHoverClasses)}
                      {...dragHandleProps}
                    >
                      <DragAndDropIcon className="h-6 w-6" />
                    </div>
                  </Show>
                  <SectionMenu
                    isOpen={isMenuOpen}
                    onClose={onMenuClose}
                    onOpen={onMenuOpen}
                    section_id={sectionId}
                    className={clsx(
                      visibleOnHoverClasses,
                      'cursor-pointer group-hover:text-info-800',
                      isMenuOpen ? 'text-info-800 opacity-100' : 'text-info-400',
                    )}
                  />
                </div>
              </Show>
            </div>
            {open && (
              <NavSectionBlocks
                blocks={getSortedEntries(section.layout, section.blocks)}
                isDragDisabled={shouldDisableOperations || isDragDisabled}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                provided={provided}
                shouldDisableOperations={shouldDisableOperations}
                snapshot={snapshot}
              />
            )}
          </div>
        );
      }}
    </StrictModeDroppable>
  );
};
