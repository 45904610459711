import type { ChangeEvent, FC, ReactNode } from 'react';
import { useQuery } from 'react-query';
import InputCheckbox from '@components/InputCheckbox';
import InputMultiSelect from '@components/InputMultiSelect';
import type { PopoverProps } from '@components/Popover';
import { Popover } from '@components/Popover';
import { Show } from '@components/Show';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { fetchAllTeams } from '@queries/fetchTeams';
import type { BlockType, ClauseType, Team } from '@root/@types/types';
import { ALL_CLASSES_OF_BUSINESS } from '@root/helpers/constants';
import { useLanguage } from '@src/language';
import type { ColumnFilter, Table } from '@tanstack/react-table';
import { BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING } from '@User/Blocks/constants';

interface SearchFiltersDropdownProps {
  filters: ColumnFilter[];
  table: Table<LibraryBlockTypeForListPage>;
  renderTrigger: PopoverProps['renderTrigger'];
  isFetching: boolean;
  isClause: boolean;
}

const FilterTitle = ({ children }: { children: ReactNode }) => (
  <p className=" mb-0 mt-3 font-semibold uppercase leading-[18px] text-info-500 first:mt-1">{children}</p>
);

export const SearchFiltersDropdown: FC<SearchFiltersDropdownProps> = ({
  filters: currentFiltersState,
  renderTrigger,
  isClause,
  table,
}) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });
  const { data: teams = [] } = useQuery<Team[]>(['allTeams'], fetchAllTeams, {
    refetchOnWindowFocus: false,
  });

  const handleBlockTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = 'type';
    const isChecked = event.target.checked;
    const name = BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === event.target.name)?.label ?? '';
    const blockTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === type);
    const currentFilter = currentFiltersState?.find(({ id }) => id === type) ?? { id: type, value: [] };
    blockTypeColumn?.setFilterValue(
      isChecked
        ? [...(currentFilter.value as string[]), name]
        : (currentFilter.value as string[]).filter((i) => i !== name),
    );
  };

  const handleClauseTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = 'clause_type';
    const isChecked = event.target.checked;
    const name = event.target.name ?? '';
    const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === type);
    const currentFilter = currentFiltersState?.find(({ id }) => id === type) ?? { id: type, value: [] };
    clauseTypeColumn?.setFilterValue(
      isChecked
        ? [...(currentFilter.value as string[]), name]
        : (currentFilter.value as string[]).filter((i) => i !== name),
    );
  };

  const handleClassificationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = 'classification';
    const isChecked = event.target.checked;
    const name = event.target.name ?? '';
    const column = table.getAllColumns().find(({ id }: { id: string }) => id === type);
    const currentFilter = currentFiltersState?.find(({ id }) => id === type) ?? { id: type, value: [] };
    column?.setFilterValue(
      isChecked
        ? [...(currentFilter.value as string[]), name]
        : (currentFilter.value as string[]).filter((i) => i !== name),
    );
  };

  const handleDropdownFilterChange = (key: string) => (selectedValue?: string[] | number[]) => {
    const column = table.getColumn(key);
    column?.setFilterValue(selectedValue);
  };

  const getBlockTypeInputProps = (value: BlockType) => {
    const label = BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === value)?.label ?? '';
    return {
      isChecked: (currentFiltersState?.find(({ id }) => id === 'type')?.value as string[])?.includes(label),
      name: value,
      onChange: handleBlockTypeChange,
    };
  };

  const getClauseTypeInputProps = (value: ClauseType) => {
    return {
      isChecked: (currentFiltersState?.find(({ id }) => id === 'clause_type')?.value as string[])?.includes(value),
      name: value,
      onChange: handleClauseTypeChange,
    };
  };

  const getClassificationInputProps = (value: string) => {
    return {
      isChecked: (currentFiltersState?.find(({ id }) => id === 'classification')?.value as string[])?.includes(value),
      name: value,
      onChange: handleClassificationChange,
    };
  };

  return (
    <Popover
      containerClassName="relative flex flex-col bg-white grow w-[270px] rounded-lg"
      isMinimal
      middleware={[]}
      offset={2}
      renderTrigger={renderTrigger}
      shouldRenderInsidePortal
      wrapperClassName="ring-1 ring-black ring-opacity-5 bg-white shadow-lg w-[270px] flex rounded-lg"
    >
      {() => (
        <>
          <div className="flex flex-col gap-1.5 rounded-lg rounded-b-none bg-white p-4 text-sm">
            <Show when={!isClause}>
              <FilterTitle>Block layout</FilterTitle>
              <InputCheckbox {...getBlockTypeInputProps('generic')} labelText={getContent('generic')} />
              <InputCheckbox
                {...getBlockTypeInputProps('mrc-heading')}
                id="generic"
                labelText={getContent('mrc-heading')}
              />
            </Show>
            <Show when={isClause}>
              <FilterTitle>Clause type</FilterTitle>
              <InputCheckbox {...getClauseTypeInputProps('Condition')} labelText="Condition" />
              <InputCheckbox {...getClauseTypeInputProps('Endorsement')} labelText="Endorsement" />
              <InputCheckbox {...getClauseTypeInputProps('Exclusion')} labelText="Exclusion" />
              <InputCheckbox {...getClauseTypeInputProps('Standard Warranties')} labelText="Standard Warranties" />
              <InputCheckbox {...getClauseTypeInputProps('Express Warranties')} labelText="Express Warranties" />
              <InputCheckbox {...getClauseTypeInputProps('Wording')} labelText="Wording" />
              <FilterTitle>Clause classification</FilterTitle>
              <InputCheckbox {...getClassificationInputProps('Standard')} labelText="Standard" />
              <InputCheckbox {...getClassificationInputProps('Non-Standard')} labelText="Non-Standard" />
              <InputCheckbox {...getClassificationInputProps('LMW')} labelText="LMW" />
            </Show>
            <FilterTitle>Class of business</FilterTitle>
            <InputMultiSelect
              onChange={handleDropdownFilterChange('class_of_business')}
              options={ALL_CLASSES_OF_BUSINESS}
              value={table.getColumn('class_of_business')?.getFilterValue() as string[]}
            />
            <FilterTitle>Team</FilterTitle>
            <InputMultiSelect<number>
              onChange={handleDropdownFilterChange('teams')}
              options={teams.map((team) => ({ name: team.name, value: team.id }))}
              value={table.getColumn('teams')?.getFilterValue() as number[]}
            />
          </div>
        </>
      )}
    </Popover>
  );
};
