import IconMdi from '@components/IconMdi';
import { mdiAlert } from '@mdi/js';

export const isUserNotExists = (message?: string) => message === 'User does not exist.';

export const UserNotExistsBanner = () => (
  <div className="mb-3 flex w-full items-start gap-4 rounded-md bg-error-50 p-4">
    <IconMdi centerVertical={false} path={mdiAlert} className={'text-error-500'} />
    <div className="flex flex-col gap-2 text-error-800">
      <div className="flex items-center gap-3  text-sm font-medium leading-tight ">
        User account does not exist. Please contact an administrator.
      </div>
    </div>
  </div>
);
