import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import type { BaseUser, Team } from '@root/@types/types';
import Nav from '@root/src/routes';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING } from '@User/Blocks/constants';

import {
  classOfBusinessAccessor,
  editDisplay,
  expiryDateAccessor,
  inceptionDateAccessor,
  lastUpdatedByAccessor,
  nameAccessor,
  statusAccessor,
  teamsAccessor,
  toggleSubRowsDisplay,
  updatedAtAccessor,
  usedInTemplatesAccessor,
} from './shared';
const columnHelper = createColumnHelper<LibraryBlockTypeForListPage>();

const wrapperClassName = '!mr-0';

export const getBlocksTableColumns = ({ user, teams }: { user?: BaseUser | null; teams?: Team[] } = {}) => [
  toggleSubRowsDisplay(columnHelper),
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  inceptionDateAccessor(columnHelper),
  expiryDateAccessor(columnHelper),
  nameAccessor(columnHelper, 'Block Name', { order: 0, wrapperClassName, placeholder: 'All blocks' }),
  columnHelper.accessor('title', {
    id: 'title',
    filterFn: 'arrIncludesSome',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: 'Layout',
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, string>) =>
      BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === cellContext.row.original.type)?.label ?? '',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: (row, _, selectedFilters) => {
      if ((selectedFilters?.length ?? 0) === 0) {
        return true;
      }
      return selectedFilters.includes(
        BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === row.original.type)?.label ?? '',
      );
    },
    meta: {
      filter: {
        options: BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.filter(({ value }) => value !== 'clause').map(
          ({ label }) => label,
        ),
        order: 1,
        wrapperClassName,
        placeholder: 'All layouts',
      },
      tooltip: true,
      headingCellClassName: 'w-40',
    },
  }),
  classOfBusinessAccessor(columnHelper, {
    order: 2,
    wrapperClassName,
  }),
  teamsAccessor(columnHelper, teams, { order: 3, wrapperClassName }),
  lastUpdatedByAccessor(columnHelper, { order: 5, wrapperClassName }),
  statusAccessor(columnHelper, { order: 4, wrapperClassName }),
  usedInTemplatesAccessor(columnHelper),
  updatedAtAccessor(columnHelper),
  editDisplay(columnHelper, Nav.Block, user),
];
