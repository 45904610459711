import type { FC } from 'react';
import { CONTRACT_BUILDER_FOOTER_Z_INDEX } from '@constants/z-indices';
import { ContractBuilderFooter } from '@ContractBuilder/contract-builder-footer';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { animationProps } from '../constants';

interface EditorFooterProps {
  isEditing: boolean;
}

export const EditorFooter: FC<EditorFooterProps> = ({ isEditing }) => {
  return (
    <AnimatePresence initial={false}>
      {isEditing && (
        <motion.div
          {...animationProps}
          className={clsx('sticky bottom-0 w-drawer-leftover bg-white', CONTRACT_BUILDER_FOOTER_Z_INDEX)}
        >
          <ContractBuilderFooter />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
