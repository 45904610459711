import type { FC } from 'react';
import { Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import { Show } from '@components/Show';
import Section from '@ContractBuilder/components/SectionView/Section/Section';
import { useEntityStore } from '@ContractBuilder/store';
import { getDocumentContext } from '@ContractBuilder/utils/get-document-context';
import { EndorsementChangesBannerController } from '@features/endorsement-changes-banner';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { useDocumentLocation } from '@hooks/useDocumentLocation';
import type { ResourceSection } from '@root/@types/base';
import { isSummarySection } from '@root/helpers';
import { clsx } from 'clsx';
import { findLastIndex } from 'lodash-es';

import { useSectionsVisibility } from '../context';

interface ContractViewSectionsProps {
  sections: ResourceSection[];
}

export const ContractViewSections: FC<ContractViewSectionsProps> = ({ sections }) => {
  const { endorsementParent, submission } = useEntityStore(({ endorsementParent, submission }) => ({
    endorsementParent,
    submission,
  }));

  const { isEndorsementView } = useDocumentLocation();
  const isViewingRevisionHistory = useIsRevisionHistory();

  const context = getDocumentContext();
  const { onViewChange } = useSectionsVisibility();

  const disableControls = !canChange(submission?.status) || isEndorsementView || isViewingRevisionHistory;
  const shouldShowCreator = !disableControls && !isViewingRevisionHistory;

  const lastStartSummarySectionIdx = findLastIndex(
    sections,
    (section) => isSummarySection(section) && section.position === 'start',
  );

  return (
    <>
      {sections.map((section, idx) => {
        const isCurrentSectionSummarySection = isSummarySection(section);
        const shouldDisplayChangesBanner = isEndorsementView && idx === lastStartSummarySectionIdx + 1;

        const shouldShowBlocksCreator = isEndorsementView
          ? isCurrentSectionSummarySection && canChange(submission?.status)
          : shouldShowCreator;

        return (
          <Fragment key={`contract-section-${section.id}`}>
            <Show when={shouldDisplayChangesBanner}>
              <div className="-mb-8 -mt-8">
                <EndorsementChangesBannerController />
              </div>
            </Show>
            <InView
              delay={50}
              key={`section-in-view-${section.id}`}
              onChange={(inView, entry) => onViewChange(inView, entry, section.id)}
            >
              {({ ref }) => (
                <div className={clsx('bg-white shadow-lg')} ref={ref}>
                  <Section
                    context={context}
                    disableControls={!canChange(submission?.status) || isViewingRevisionHistory}
                    endorsementParent={endorsementParent}
                    key={`${section.id}-${section.blocks.length}`}
                    section={section}
                    shouldShowCreator={shouldShowBlocksCreator}
                    submission={submission}
                  />
                </div>
              )}
            </InView>
          </Fragment>
        );
      })}
    </>
  );
};
