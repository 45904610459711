import { useMemo } from 'react';
import type { QueryFunction } from 'react-query';
import { useUserStore } from '@Auth/store';
import type { EntityModalType } from '@features/create-entity-modal/types';
import type { Team } from '@root/@types/types';
import { fetchAllTeams, fetchUserTeams } from '@src/queries';
import { isSubmissionPath } from '@utils/app-paths';

export const useTeamsQueryParams = () => {
  const isSubmission = isSubmissionPath();
  const user = useUserStore(({ user }) => user);

  return useMemo(() => {
    let type: EntityModalType;
    let queryKey: string;
    let queryFn: QueryFunction<Team[]>;
    if (isSubmission) {
      type = 'contract';
      queryKey = 'userTeams';
      const showAllIfUserNotAssignedToAny = (user?.tenantIds?.length ?? 0) === 0;
      queryFn = showAllIfUserNotAssignedToAny ? fetchAllTeams : fetchUserTeams;
    } else {
      type = 'template';
      queryKey = 'allTeams';
      queryFn = fetchAllTeams;
    }
    return { type, queryKey, queryFn };
  }, [isSubmission, user]);
};
