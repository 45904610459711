import type { FC } from 'react';
import { isNonNullish } from '@root/helpers';
interface ContractValuePillsProps {
  data?: Array<string | number | undefined>;
  getLabel?: <T>(value: T) => string;
}

export const ContractValuePills: FC<ContractValuePillsProps> = ({ data = [], getLabel }) => {
  return (
    <div className="flex flex-col flex-wrap items-start gap-1.5">
      {data.filter(isNonNullish).map((value) => {
        const formattedValue = getLabel?.(value) ?? value;
        return (
          <span className="rounded-lg bg-info-100 px-2 py-0.5 text-center text-sm" key={formattedValue}>
            {formattedValue}
          </span>
        );
      })}
    </div>
  );
};
