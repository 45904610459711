import InputTextarea from '@components/InputTextarea';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import type { BlockType } from '@root/@types/types';
import LayoutOptions from '@src/components/Sidebar/BlockLayoutSelector/LayoutSelector';
import Toggle from '@src/components/Toggle';
import { useLanguage } from '@src/language';
import { isBlocksPath, isClausesPath, isTemplatePath } from '@utils/app-paths';
import { get, isEmpty } from 'lodash-es';

import { DrawerCollapsibleGroup } from '../components/DrawerCollapsibleGroup';
import { DrawerSection } from '../components/DrawerSection';
import { VariationToggle } from '../components/VariationToggle';

export const DrawerPanelSettings = () => {
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isClause = isClausesPath();

  const isAdminView = isTemplate || isBlock || isClause;

  const { formValues, setFormValues } = useBlockEditFormStore(({ currentBlock, formValues, setFormValues }) => ({
    currentBlock,
    formValues,
    setFormValues,
  }));

  const { getContent } = useLanguage({
    prefix: `naming.mrc.rightSidebar.${formValues?.type !== 'clause' ? 'block' : 'clause'}`,
  });

  const handleTypeChange = (value: BlockType) => {
    const isClauseValue = value === 'clause';
    const clauseResetPayload = isClauseValue ? {} : { clause_type: undefined, clause_reference: undefined };

    setFormValues((prev) => ({ ...prev, ...clauseResetPayload, type: value }));
  };

  const toggleField = (field: keyof BlockEditFormState, fallback?: boolean) => () => {
    setFormValues((prev) => {
      const currentValue = get(prev, field, fallback);
      return { ...prev, [field]: !currentValue };
    });
  };

  const hasVariations = !isEmpty(formValues?.variations);
  const isCreate = !formValues?.id;
  const isLayoutDisabled = !isCreate && hasVariations;

  return (
    <DrawerCollapsibleGroup title={getContent('groups.settings')} id="settings">
      <div className="flex flex-col gap-3">
        {isAdminView && <VariationToggle label={getContent('fields.settings.variations.label')} />}
        <Toggle
          labelText={getContent('fields.settings.pageBreak.label')}
          value={Boolean(formValues?.shouldInsertPageBreak)}
          onClick={toggleField('shouldInsertPageBreak')}
          data-testid="toggle-page-break"
        />
      </div>
      <DrawerSection title={getContent('fields.layout.label')}>
        <LayoutOptions
          onChange={handleTypeChange}
          type={formValues?.type ?? 'mrc-heading'}
          isDisabled={isLayoutDisabled}
        />
      </DrawerSection>
      {isAdminView && (
        <DrawerSection title={getContent('fields.instructions.label')}>
          <InputTextarea
            placeholder={getContent('fields.instructions.placeholder')}
            name="helperText"
            onChange={(event) => setFormValues((prev) => ({ ...prev, helperText: event.target.value }))}
            value={formValues?.helperText}
          />
        </DrawerSection>
      )}
    </DrawerCollapsibleGroup>
  );
};
