import axios from 'axios';

import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const deleteUser = async (id: string): Promise<{ message: 'success' }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'delete',
    url: `users-spa-al/${id}`,
  });

  const response = await axios(networkPayload);
  return response.data;
};
