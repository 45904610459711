import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { CanonicalUserRecord } from '@root/helpers/user-management/types';
import axios from 'axios';

export const createUser = async (
  data: Pick<CanonicalUserRecord, 'firstName' | 'lastName' | 'email' | 'role' | 'teams'>,
): Promise<Pick<CanonicalUserRecord, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: `users-spa-al`,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
