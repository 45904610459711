import type { FC } from 'react';

import { BlockConfigurationPanel } from '../views/BlockConfigurationPanel';

interface BlockConfigurationPanelControllerProps {
  onCancel?: () => void;
}

export const BlockConfigurationPanelController: FC<BlockConfigurationPanelControllerProps> = ({ onCancel }) => {
  return <BlockConfigurationPanel onCancel={onCancel} />;
};
