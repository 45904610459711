import { formatDate } from '@root/helpers';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';

export const updatedAtAccessor = <T extends { updated_at?: string }>(columnHelper: ColumnHelper<T>) =>
  columnHelper.accessor((row) => row.updated_at, {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<T, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  });
