import type { FC } from 'react';
import { BlockController } from '@ContractBuilder/modules/block/controller/BlockController';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit/store';
import { BlockUsedWithin } from '@ContractBuilder/modules/block-edit/views/BlockUsedInTemplatesModal';
import { useOnBlockEditSubmit } from '@ContractBuilder/modules/inline-block-edit/hooks/use-on-block-edit-submit';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { DocumentContext } from '@ContractBuilder/utils';
import type { DynamoDBLibraryBlock } from '@root/@types/types';
interface LibraryBlockViewProps {
  block: DynamoDBLibraryBlock;
  onUpdate: () => Promise<any> | void;
}

export const LibraryBlockView: FC<LibraryBlockViewProps> = ({ block, onUpdate }) => {
  const state = useBlockEditFormStore(({ formValues }) => formValues);
  const isClause = state?.type === 'clause';
  const { isBlockUsedInTemplatesModalOpen, setBlockUsedInTemplatesModalVisibility } = useUIStore(
    ({ isBlockUsedInTemplatesModalOpen, setBlockUsedInTemplatesModalVisibility }) => ({
      isBlockUsedInTemplatesModalOpen,
      setBlockUsedInTemplatesModalVisibility,
    }),
  );

  const { onSubmit } = useOnBlockEditSubmit();

  const handleConfirmSubmit = async () => {
    if (state) {
      await onSubmit(state);
      await onUpdate();
    }

    setBlockUsedInTemplatesModalVisibility(false);
  };

  return (
    <>
      <BlockUsedWithin
        message={`This ${isClause ? 'clause' : 'block'} will also be updated on these templates`}
        isOpen={isBlockUsedInTemplatesModalOpen}
        onCancel={() => setBlockUsedInTemplatesModalVisibility(false)}
        onSubmit={handleConfirmSubmit}
      />
      <div className="relative mt-10 overflow-auto border border-info-300 bg-white shadow-md">
        <BlockController block={{ ...block, commentsCount: 0 }} context={DocumentContext.Blocks} />
      </div>
    </>
  );
};
