import type { FC, MouseEventHandler } from 'react';
import { generatePath, Link } from 'react-router-dom';
import type { PopoverClickEventHandler } from '@components/Popover';
import { Show } from '@components/Show';
import { TooltipedIcon } from '@components/TooltipedIcon';
import MODALS from '@constants/modals';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import { ctxIsTemplate } from '@ContractBuilder/rules/block/is-ctx';
import { useModalDispatch } from '@hooks/useModalProvider';
import {
  mdiCog,
  mdiCommentPlusOutline,
  mdiCommentProcessingOutline,
  mdiOpenInNew,
  mdiPencil,
  mdiTrashCanOutline,
} from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import { useFeatureFlags } from '@src/hooks';
import Nav from '@src/routes';
import clsx from 'clsx';

import { iconClasses } from '../classes';
import { PreviewInPDFTooltipedIcon } from '../PreviewInPDFTooltipedIcon';
import { getId } from '../utils';

import { Container } from './Container';

interface ContractBlockActionsProps {
  block: ResourceBlock;
  handleDelete: MouseEventHandler;
  handleEdit: MouseEventHandler<HTMLDivElement>;
  onCommentClick: MouseEventHandler;
  onShowComments: PopoverClickEventHandler;
}

export const ContractBlockActions: FC<ContractBlockActionsProps> = ({
  block,
  handleDelete,
  handleEdit,
  onCommentClick,
  onShowComments,
}) => {
  const { commentsCount = 0, id, name, blockLibraryId, type } = block;
  const isClause = type === 'clause';

  const hasCommentsFeature = useFeatureFlags()('Comments');
  const {
    context,
    details: { isDeletable, isEditable },
  } = useBlockFlags();
  const { showModal } = useModalDispatch();

  const openVisibilityModal = () => showModal(MODALS.BLOCK_VISIBILITY_MODAL, { blockId: id });
  const isTemplate = ctxIsTemplate(context);
  const hasComments = commentsCount > 0 && hasCommentsFeature;

  const hasAtLeastOneActionEnabled = isEditable || hasCommentsFeature || isDeletable;

  if (!hasAtLeastOneActionEnabled) {
    return null;
  }

  return (
    <Container>
      <Show when={hasComments}>
        <div
          className={clsx([
            'absolute',
            '-right-1.5',
            'top-0',
            'rounded-full',
            'p-1',
            'bg-primary-600',
            'text-white',
            'inline-flex',
            'items-center',
            'justify-center',
            'text-xs font-medium leading-5',
            'w-5',
            'h-5',
          ])}
        >
          {commentsCount}
        </div>
      </Show>
      <div className={'flex h-fit flex-col gap-3'}>
        <Show when={hasComments}>
          <TooltipedIcon
            size={0.9}
            className={iconClasses({ comments: hasComments })}
            data-testid={getId('show-comments', name)}
            onClick={onShowComments}
            content="Block comments"
            path={mdiCommentProcessingOutline}
          />
        </Show>
        <Show when={isEditable}>
          <TooltipedIcon
            size={0.9}
            className={iconClasses()}
            data-testid={getId('edit', name)}
            path={mdiPencil}
            onClick={handleEdit}
            content="Edit block"
          />
        </Show>
        <Show when={hasCommentsFeature}>
          <TooltipedIcon
            size={0.9}
            className={iconClasses()}
            data-testid={getId('comment', name)}
            path={mdiCommentPlusOutline}
            onClick={onCommentClick}
            content="Start new comment thread"
          />
        </Show>
        <Show when={isEditable && isTemplate}>
          <TooltipedIcon
            size={0.9}
            className={iconClasses()}
            data-testid={getId('visibility', name)}
            path={mdiCog}
            onClick={openVisibilityModal}
            content="Configure visibility"
          />
        </Show>
        <Show when={isTemplate && !!blockLibraryId}>
          <Link target="_blank" to={generatePath(isClause ? Nav.Clause : Nav.Block, { id: blockLibraryId ?? '' })}>
            <TooltipedIcon
              size={0.9}
              className={iconClasses()}
              data-testid={getId(`view-block-in-library`, name)}
              path={mdiOpenInNew}
              content={`View block in library`}
            />
          </Link>
        </Show>
        {/** Temporarily disable `Jump to PDF` button **/}
        <Show when={false}>
          <PreviewInPDFTooltipedIcon block={block} />
        </Show>
        <Show when={isDeletable}>
          <TooltipedIcon
            size={0.9}
            className={iconClasses()}
            data-testid={getId('delete', name)}
            path={mdiTrashCanOutline}
            onClick={handleDelete}
            content="Remove block"
          />
        </Show>
      </div>
    </Container>
  );
};
