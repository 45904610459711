import type { FC, ReactNode } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';

import { shouldRenderField } from '../utils/should-render-field';
import { ContractValuePills } from '../views/ContractValuePills';

import { DrawerSection } from './DrawerSection';

type DatapointDisplayKey = keyof Pick<
  BlockEditFormState,
  'teams' | 'class_of_business' | 'inception_date' | 'expiry_date'
>;

interface InputRenderProps {
  value: any;
  onChange: (value: any) => void;
}

interface DatapointDisplayProps {
  isAdminView: boolean;
  getContractValuePillsLabel?: (value: any) => string;
  inputKey: DatapointDisplayKey;
  renderInput: (props: InputRenderProps) => ReactNode;
  title: string;
}

export const DatapointDisplay: FC<DatapointDisplayProps> = ({
  getContractValuePillsLabel,
  isAdminView,
  inputKey,
  renderInput,
  title,
}) => {
  const { fieldValue, setFormValues } = useBlockEditFormStore(({ formValues, setFormValues }) => ({
    fieldValue: formValues?.[inputKey],
    setFormValues,
  }));
  const shouldRender = shouldRenderField(isAdminView, fieldValue);

  if (!shouldRender) {
    return null;
  }

  return (
    <DrawerSection title={title}>
      <ShowFirstMatching>
        <Show when={isAdminView}>
          {renderInput({
            value: fieldValue,
            onChange: (value: any) => setFormValues((prev) => ({ ...prev, [inputKey]: value })),
          })}
        </Show>
        <Show when={!isAdminView}>
          <ContractValuePills
            data={Array.isArray(fieldValue) ? fieldValue : [fieldValue]}
            getLabel={getContractValuePillsLabel}
          />
        </Show>
      </ShowFirstMatching>
    </DrawerSection>
  );
};
