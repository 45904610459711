import React, { createContext, Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { BlockVisibilityWarningsModal } from '@ContractBuilder/components/Modal/BlockVisibilityWarnings';
import ConfirmDeleteModal from '@ContractBuilder/components/Modal/ConfirmDeleteModal';
import ContactSupportModal from '@ContractBuilder/components/Modal/ContactSupportModal';
import { EndorsementModal } from '@ContractBuilder/components/Modal/EndorsementModal';
import AdminOpenPdfModal from '@ContractBuilder/components/Modal/PdfModal';
import { AttachmentRenameModal } from '@ContractBuilder/modules/attachment/components/AttachmentRenameModal';
import { AttachmentUploadModal } from '@ContractBuilder/modules/attachment/components/AttachmentUploadModal';
import { BlockVisibilityModal } from '@ContractBuilder/modules/block-visibility-modal';
import { RestoreRevisionModal } from '@ContractBuilder/modules/revision-history';
import { CreateEntityModalController } from '@features/create-entity-modal';
import { CreateLibraryBlockController } from '@features/create-library-block-modal';
import { CreateRenewalModalController } from '@features/create-renewal-modal';
import { ReplaceBaseTemplateModalController } from '@features/replace-base-template-modal';
import { ConfirmStatusModal } from '@root/src/containers/ContractBuilder/components/Modal/ConfirmStatusModal';
import TagModal from '@User/Tags/modals/TagModal';
import UserModal from '@User/Users/modals/UserModal';
import UserResendInviteModal from '@User/Users/modals/UserResendInviteModal';

import { MODALS } from '../constants';
import { CreateBrandingModal } from '../features/branding-edit/components/create-branding/CreateBrandingModal';
import { ImageUploadController } from '../features/branding-edit/components/image-upload/ImageUploadController';
import ConfirmBrandingStatusModal from '../features/branding-edit/modals/ConfirmBrandingStatusModal';

import type { ModalContextState } from './useModal';

// Create a separate context for modal state management
interface ModalDispatchContextType {
  showModal: (modalName: ModalContextState, props: any) => void;
  closeModal: () => void;
}

const ModalDispatchContext = createContext<ModalDispatchContextType | undefined>(undefined);

// Create a separate context for the current modal component
interface ModalComponentContextType {
  modal: ModalContextState;
  props: any;
}

const ModalComponentContext = createContext<ModalComponentContextType | undefined>(undefined);

// Create a map of modal components
const modalsMap = new Map();

modalsMap.set(MODALS.ADMIN_CREATE_TEMPLATE, CreateEntityModalController);
modalsMap.set(MODALS.ATTACHMENT_UPLOAD, AttachmentUploadModal);
modalsMap.set(MODALS.ATTACHMENT_RENAME, AttachmentRenameModal);
modalsMap.set(MODALS.ADMIN_OPEN_PDF_MODAL, AdminOpenPdfModal);
modalsMap.set(MODALS.ADMIN_TAG, TagModal);
modalsMap.set(MODALS.ADMIN_USER, UserModal);
modalsMap.set(MODALS.ADMIN_USER_RESEND_INVITE, UserResendInviteModal);
modalsMap.set(MODALS.CREATE_ENDORSEMENT, EndorsementModal);
modalsMap.set(MODALS.CONFIRM_DELETE, ConfirmDeleteModal);
modalsMap.set(MODALS.CONFIRM_STATUS_FINAL, ConfirmStatusModal);
modalsMap.set(MODALS.CONFIRM_STATUS_FINAL_BRANDING, ConfirmBrandingStatusModal);
modalsMap.set(MODALS.RESTORE_REVISION, RestoreRevisionModal);
modalsMap.set(MODALS.BLOCK_VISIBILITY_MODAL, BlockVisibilityModal);
modalsMap.set(MODALS.BLOCK_VISIBILITY_WARNING_MODAL, BlockVisibilityWarningsModal);
modalsMap.set(MODALS.UPLOAD_IMAGE, ImageUploadController);
modalsMap.set(MODALS.CREATE_BRANDING, CreateBrandingModal);
modalsMap.set(MODALS.CONTACT_SUPPORT, ContactSupportModal);
modalsMap.set(MODALS.CREATE_RENEWAL, CreateRenewalModalController);
modalsMap.set(MODALS.REPLACE_BASE_TEMPLATE, ReplaceBaseTemplateModalController);
modalsMap.set(MODALS.CREATE_LIBRARY_BLOCK, CreateLibraryBlockController);

// Custom hooks to access the contexts
// eslint-disable-next-line react-refresh/only-export-components
export function useModalDispatch() {
  const context = useContext(ModalDispatchContext);
  if (context === undefined) {
    throw new Error('useModalDispatch must be used within a ModalContextProvider');
  }
  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useModalComponent() {
  const context = useContext(ModalComponentContext);
  if (context === undefined) {
    throw new Error('useModalComponent must be used within a ModalContextProvider');
  }
  return context;
}

export const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [modal, setVisibleModal] = useState<ModalContextState>(undefined);
  const [props, setProps] = useState(null);

  // Memoize the dispatch context value to prevent unnecessary re-renders
  const showModal = useCallback((modalName: ModalContextState, nextProps: any) => {
    setVisibleModal(modalName);
    setProps(nextProps);
  }, []);

  const closeModal = useCallback(() => setVisibleModal(undefined), []);

  const providerValue = useMemo(() => ({ closeModal, showModal }), [closeModal, showModal]);

  // Memoize the component context value
  const componentValue = useMemo(() => {
    return { modal, props };
  }, [modal, props]);

  return (
    <ModalDispatchContext.Provider value={providerValue}>
      <ModalComponentContext.Provider value={componentValue}>{children}</ModalComponentContext.Provider>
    </ModalDispatchContext.Provider>
  );
};

export const ModalSwitch = () => {
  const { modal, props } = useModalComponent();
  const { closeModal } = useModalDispatch();

  const Component = modal ? modalsMap.get(modal) : null;

  if (!Component) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component handleClose={closeModal} {...props} />
    </Suspense>
  );
};
