import type { FC } from 'react';
import { InlineEditableText } from '@components/InlineEditableText';
import InputSelect from '@components/InputSelect';
import Tooltip from '@components/Tooltip';
import { responsiveTruncateClasses } from '@ContractBuilder/contract-builder-header/classes';
import type { EntityData } from '@ContractBuilder/types';
import { useBrandingOptions } from '@features/create-entity-modal';
import { BriefcaseIcon } from '@heroicons/react/solid';
import { getDefaultPdfBranding } from '@pdf/branding/config-default';
import type { UIInputValue } from '@root/@types/types';
import { isString } from 'lodash-es';

import { useEditableField } from '../hooks';
import type { OnSaveFn } from '../hooks/use-editable-field';

interface EditableBrandingFieldProps {
  entity: EntityData;
  hasOperationsDisabled: boolean;
  onSave: OnSaveFn<string | undefined>;
}

export const EditableBrandingField: FC<EditableBrandingFieldProps> = ({ entity, hasOperationsDisabled, onSave }) => {
  const { brandingFieldName, data, isLoading } = useBrandingOptions();

  const selectedBranding = data.find((item) =>
    isString(item) ? item === entity?.[brandingFieldName] : item.value === entity?.[brandingFieldName],
  );

  const { onCancel, onChange, onClick, onUpdate, state } = useEditableField<string | undefined>({
    defaultValue: isString(selectedBranding) ? selectedBranding : selectedBranding?.value?.toString(),
    name: brandingFieldName,
    onSave,
  });

  if (isLoading) {
    return <div className="flex h-4 w-[120px] animate-pulse rounded-xl bg-slate-200" />;
  }

  return (
    <div className="flex items-center gap-1">
      <Tooltip content="Branding">
        <span>
          <BriefcaseIcon className="h-4 w-4" />
        </span>
      </Tooltip>
      <InlineEditableText
        editor={
          <InputSelect
            isDisabled={false}
            isFetchingOptions={isLoading}
            menuOuterClassName="min-w-[7rem]"
            name={brandingFieldName}
            onChange={(value) => onChange(value as string)}
            options={data}
            size="sm"
            value={state.value as UIInputValue}
          />
        }
        editMode={state.isEditing}
        disabled={hasOperationsDisabled}
        display={
          isString(selectedBranding)
            ? selectedBranding ?? getDefaultPdfBranding()
            : selectedBranding?.name ?? 'No branding set'
        }
        onClick={onClick}
        onCancel={onCancel}
        onConfirm={onUpdate}
        tooltipContent={!hasOperationsDisabled ? 'Edit branding' : undefined}
        withEditIcon={false}
        className={responsiveTruncateClasses([
          'rounded border border-transparent px-1 py-0.5 ',
          !hasOperationsDisabled ? 'hover:border-gray-300' : '',
        ])}
      />
    </div>
  );
};
