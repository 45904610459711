import type { KeyboardEventHandler } from 'react';
import React, { useEffect, useState } from 'react';
import type { DayPickerProps } from 'react-day-picker';
import { DayPicker } from 'react-day-picker';
import Icon from '@components/Icon';
import Input from '@components/Input';
import { InputCalendarDropdownComponent } from '@components/InputCalendarDropdownComponent';
import { InputField, type InputFieldProps } from '@components/InputField';
import { Popover } from '@components/Popover';
import { isNonNullish } from '@root/helpers';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { get } from 'lodash-es';

import type { UIOnChangeFn } from '../../../@types/types';
import { CloseIconButton } from '../atoms/ClearInputButton';
import InputReadOnly from '../InputReadOnly';

import { getNewInputValueFromEvent } from './utils/get-new-input-value-from-event';

import 'react-day-picker/dist/style.css';
import '../InputCalendar.css';

const getFromAndToYear = (date: Date | undefined) => {
  const parsedDate = date ?? new Date();

  return {
    fromYear: parsedDate.getFullYear() - 20,
    toYear: parsedDate.getFullYear() + 20,
  };
};

const ALLOWED_DATE_FORMATS = [
  'D MMM YYYY',
  'D MMMM YYYY',
  'D/M/YYYY',
  'D/MM/YYYY',
  'DD MMM YYYY',
  'DD MMMM YYYY',
  'DD/M/YYYY',
  'DD/MM/YYYY',
];

export interface InputCalendarProps extends InputFieldProps {
  dateFormat?: string;
  dayPickerProps?: DayPickerProps;
  descriptionText?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: string;
  onChange?: UIOnChangeFn;
  placeholder?: string;
  value?: string | null;
  wrapperClassName?: string;
}

const InputCalendar: React.FC<InputCalendarProps> = ({
  cdrId,
  cdrName,
  className,
  wrapperClassName,
  dateFormat = 'D MMMM YYYY',
  dayPickerProps,
  descriptionText,
  errors,
  helperText,
  id,
  isDisabled = false,
  isReadOnly,
  isRequired,
  labelIcon,
  labelText,
  mrcId,
  name = 'calendar',
  onChange,
  placeholder = 'dd/mm/yyyy',
  shortName,
  size: _size = 'md',
  value,
  isIncomplete,
  ...props
}) => {
  const parsedDate = dayjs(value);
  const isValidDate = Boolean(value && parsedDate.isValid());
  const formattedDate = isValidDate ? parsedDate?.toDate() : undefined;
  const formattedValue = formattedDate ? parsedDate.format(dateFormat) : undefined;
  const hasValue = isNonNullish(value);

  const [inputValue, setInputValue] = useState<string | undefined>(formattedValue);

  useEffect(() => {
    setInputValue(formattedValue);
  }, [formattedValue]);

  const handleClearDate = () => {
    setInputValue(undefined);
    return onChange?.(undefined, name);
  };

  const handleChange = (date: Date | string | undefined) => {
    if (date && onChange) {
      const parsedDayJsInput = typeof date === 'string' ? dayjs(date, ALLOWED_DATE_FORMATS) : dayjs(date);
      return onChange(parsedDayJsInput.format('YYYY-MM-DDTHH:mm:ss.SSS'), name);
    }

    return handleClearDate();
  };

  const handleInputChange: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Backspace') {
      return handleClearDate();
    }

    // Allow only letters, digits and space. Return if special keys are pressed.
    if (!/[\dA-Za-z\s/]+/.test(event.key) || event.key.length > 1) {
      return;
    }

    const newValue = getNewInputValueFromEvent(event);
    const date = dayjs(newValue, ALLOWED_DATE_FORMATS);
    const disabledBefore = get(dayPickerProps, 'disabled.before', undefined);
    const disabledAfter = get(dayPickerProps, 'disabled.after', undefined);

    const isValidBefore = disabledBefore
      ? date.isAfter(disabledBefore, 'day') || date.isSame(disabledBefore, 'day')
      : true;
    const isValidAfter = disabledAfter
      ? date.isBefore(disabledAfter, 'day') || date.isSame(disabledAfter, 'day')
      : true;

    if (date.isValid() && isValidBefore && isValidAfter) {
      setInputValue(undefined);
      return handleChange(newValue);
    }

    return setInputValue(newValue);
  };

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      helperText={helperText}
      id={id}
      isRequired={isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      shortName={shortName}
      isIncomplete={isIncomplete}
      hideErrors={props.hideErrors}
    >
      {!isReadOnly && (
        <Popover
          containerClassName="rdp-months"
          wrapperClassName={clsx('rdp !min-w-[300px]', wrapperClassName)}
          renderTrigger={({ onClick }) => (
            <div className="relative flex items-center" key={value ?? name}>
              <Input
                isDisabled={isDisabled}
                isIncomplete={isIncomplete}
                onChange={() => {}}
                onFocus={onClick}
                onKeyDown={handleInputChange}
                placeholder={placeholder}
                type="text"
                value={inputValue ?? ''}
                id={name}
              />
              <div className="absolute right-2 top-0 flex h-10 w-fit items-center">
                {hasValue ? (
                  <CloseIconButton onClick={handleClearDate} data-testid={`Clear-${name}`} />
                ) : (
                  <Icon name="calendar" className="mr-0.5 w-3 fill-current text-info-800" />
                )}
              </div>
            </div>
          )}
        >
          {({ onClose }) => (
            <div tabIndex={-1} role="dialog" aria-label="DayPicker calendar">
              <DayPicker
                {...dayPickerProps}
                {...getFromAndToYear(formattedDate)}
                key={formattedValue}
                captionLayout="dropdown-buttons"
                className="text-info-500"
                components={{
                  Dropdown: InputCalendarDropdownComponent,
                }}
                defaultMonth={formattedDate}
                mode="single"
                onSelect={(date) => {
                  handleChange(date);
                  return onClose();
                }}
                selected={formattedDate}
              />
            </div>
          )}
        </Popover>
      )}

      {isReadOnly && <InputReadOnly {...props} key={value} defaultValue={formattedValue} />}
    </InputField>
  );
};

export default InputCalendar;
