import type { FC } from 'react';
import React from 'react';
import type { ContextOption } from '@components/menu';
import { ContextMenu } from '@components/menu';
import { useNavigation } from '@ContractBuilder/modules/navigation';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { useModalDispatch } from '@hooks/useModalProvider';
import { useLanguage } from '@root/src/language';
import { MODALS } from '@src/constants';
import { isEndorsementViewPath } from '@utils/app-paths';
import pluralize from 'pluralize';

import { useEntityStore } from '../../../../../store';

interface SectionMenuProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  section_id: string;
}

export const SectionMenu: FC<SectionMenuProps> = ({ className, isOpen, onClose, onOpen, section_id }) => {
  const { submission } = useEntityStore(({ submission }) => ({
    submission,
  }));
  const { openSectionModal } = useNavigation();
  const { showModal } = useModalDispatch();
  const isViewingRevisionHistory = useIsRevisionHistory();
  const isEndorsementView = isEndorsementViewPath();

  const { getContent } = useLanguage({ prefix: 'naming.mrc' });

  const eventPropagationTrap = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const section = submission?.sections.find((section) => section.id === section_id);
  const blocksLength = section?.blocks.length ?? 0;

  const editAction = {
    title: getContent('contextMenus.section.edit'),
    onClick: () => openSectionModal(section_id),
    icon: 'edit',
  };

  const removeAction = {
    title: getContent('contextMenus.section.remove'),
    onClick: () =>
      showModal(MODALS.CONFIRM_DELETE, {
        id: section_id,
        title: section?.label,
        type: getContent('section').toLowerCase(),
        helperText: section && blocksLength > 0 && (
          <>
            This will also remove{' '}
            <b>
              {blocksLength} associated {pluralize('block', blocksLength)}
            </b>
          </>
        ),
      }),
    icon: 'trashcan',
  };

  const options = (isEndorsementView ? [removeAction] : [editAction, removeAction]) as ContextOption[];

  return (
    <div data-testid={`section-menu:${section?.label}`} className={className} onClick={eventPropagationTrap}>
      <ContextMenu
        disabled={!canChange(submission?.status) || isViewingRevisionHistory}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        options={options}
      />
    </div>
  );
};
