import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { InputField } from '@components/InputField';
import Tooltip from '@components/Tooltip';
import type { EntityData } from '@ContractBuilder/types';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';

import type { StepProps } from '../types';
import { isTemplateResource } from '../utils/isTemplateResource';

import { checkboxDotClasses, descriptionTextClasses, radioOptionClasses, titleTextClasses } from './classes';

const getTooltipContent = (submission?: EntityData) => {
  if (isTemplateResource(submission?.sourceResourceName)) {
    return 'Current template is not published';
  }

  return 'Original contract is based on a contract';
};

export const Step2: FC<StepProps> = ({ control, canRefreshCurrentTemplate, submission }) => {
  return (
    <Controller
      control={control}
      name="type"
      render={({ field }) => {
        const isReplicate = field.value === 'replicate';
        const isRefresh = field.value === 'refresh';
        const isReplace = field.value === 'replace';

        return (
          <InputField labelText="Select renewal type">
            <RadioGroup {...field} aria-label="Select renewal type" className="rounded-lg shadow-sm transition">
              <Tooltip disabled={canRefreshCurrentTemplate} content={getTooltipContent(submission)}>
                <RadioGroup.Option
                  className={clsx('cursor-pointer', !canRefreshCurrentTemplate && '!cursor-not-allowed opacity-50')}
                  disabled={!canRefreshCurrentTemplate}
                  value="refresh"
                  data-testid="renewal-type-refresh"
                >
                  {({ checked: isChecked }) => (
                    <div className={clsx(radioOptionClasses({ isChecked }), 'rounded-t-lg border-x border-t')}>
                      <div className={checkboxDotClasses({ isChecked })} />
                      <div className="flex flex-col gap-1">
                        <span className={titleTextClasses({ isChecked })}>Refresh current template</span>
                        <span className={descriptionTextClasses({ isChecked })}>
                          Update the current template, refreshing all blocks
                        </span>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              </Tooltip>

              <div className={clsx('h-[1px] w-full', isRefresh || isReplace ? 'bg-blue-200' : 'bg-info-200')} />

              <RadioGroup.Option value="replace" className="cursor-pointer" data-testid="renewal-type-replace">
                {({ checked: isChecked }) => (
                  <div className={clsx(radioOptionClasses({ isChecked }), 'border-x')}>
                    <div className={checkboxDotClasses({ isChecked })} />
                    <div className="flex flex-col gap-1">
                      <span className={titleTextClasses({ isChecked })}>Replace with a new template</span>
                      <span className={descriptionTextClasses({ isChecked })}>
                        Swap out the template, replacing all blocks
                      </span>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>

              <div className={clsx('h-[1px] w-full', isReplicate || isReplace ? 'bg-blue-200' : 'bg-info-200')} />

              <RadioGroup.Option value="replicate" className="cursor-pointer" data-testid="renewal-type-replicate">
                {({ checked: isChecked }) => (
                  <div className={clsx(radioOptionClasses({ isChecked }), 'rounded-b-lg border-x border-b')}>
                    <div className={checkboxDotClasses({ isChecked })} />
                    <div className="flex flex-col gap-1">
                      <span className={titleTextClasses({ isChecked })}>Replicate contract</span>
                      <span className={descriptionTextClasses({ isChecked })}>
                        Copy the original contract, no template updates
                      </span>
                    </div>
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </InputField>
        );
      }}
    />
  );
};
