import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import type { BaseUser, Team } from '@root/@types/types';
import Nav from '@root/src/routes';
import { createColumnHelper } from '@tanstack/react-table';

import {
  classOfBusinessAccessor,
  editDisplay,
  expiryDateAccessor,
  inceptionDateAccessor,
  lastUpdatedByAccessor,
  nameAccessor,
  statusAccessor,
  teamsAccessor,
  toggleSubRowsDisplay,
  updatedAtAccessor,
  usedInTemplatesAccessor,
} from './shared';

const columnHelper = createColumnHelper<LibraryBlockTypeForListPage>();

const selectClassName = '[&>*:nth-child(2)>*:first-child]:max-w-[100%]';
const wrapperClassName = '!mr-0';

export const getClausesTableColumns = ({ user, teams }: { user?: BaseUser | null; teams?: Team[] }) => [
  toggleSubRowsDisplay(columnHelper),
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  inceptionDateAccessor(columnHelper),
  expiryDateAccessor(columnHelper),
  nameAccessor(columnHelper, 'Clause name', { order: 1, wrapperClassName, placeholder: 'All clauses' }),
  columnHelper.accessor('clause_reference', {
    id: 'clause_reference',
    header: 'Reference',
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        order: 2,
        className: selectClassName,
        label: 'Clause reference',
        placeholder: 'All references',
        wrapperClassName,
      },
    },
  }),
  columnHelper.accessor('clause_type', {
    id: 'clause_type',
    header: 'Type',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: (row, _, filterValue) => {
      if (!filterValue?.length) {
        return true;
      }
      return row.original.type === 'clause' && filterValue.includes(row.original.clause_type);
    },
    meta: {
      filter: {
        label: 'Clause type',
        placeholder: 'All types',
        order: 3,
        className: selectClassName,
        wrapperClassName,
      },
      tooltip: true,
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('classification', {
    id: 'classification',
    header: 'Classification',
    meta: {
      filter: {
        label: 'Clause classification',
        placeholder: 'All classifications',
        order: 4,
        className: selectClassName,
        wrapperClassName,
      },
      dataCellClassName: 'group',
      headingCellClassName: 'w-40',
    },
    filterFn: (row, _, filterValue) => {
      if (!filterValue?.length) {
        return true;
      }
      return filterValue.includes(row.original.classification);
    },
  }),
  classOfBusinessAccessor(columnHelper, {
    order: 5,
    wrapperClassName,
  }),
  teamsAccessor(columnHelper, teams, { order: 6, wrapperClassName }),
  statusAccessor(columnHelper, { order: 7, wrapperClassName }),
  lastUpdatedByAccessor(columnHelper, { order: 8 }),
  usedInTemplatesAccessor(columnHelper),
  updatedAtAccessor(columnHelper),
  editDisplay(columnHelper, Nav.Clause, user),
];
