import axios from 'axios';

import type { Team } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

const fetchTeams = async (shouldShowAllTeams = false): Promise<Team[]> => {
  const params = shouldShowAllTeams === true ? { type: 'template' } : null;
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/teams',
    params,
  });

  const response = await axios(networkPayload);
  return response.data;
};

export const fetchAllTeams = async () => await fetchTeams(true);
export const fetchUserTeams = async () => await fetchTeams(false);
