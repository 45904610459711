import { formatDate } from '@root/helpers';
import { isBlockExpired } from '@root/helpers/blocks';

export const getBlockExpiryDateTooltip = (expiryDate: string, isBlock: boolean) => {
  const isExpired = isBlockExpired(expiryDate);

  return {
    isExpired,
    tooltip: isExpired
      ? `This ${isBlock ? 'block' : 'clause'} has been archived`
      : `Expires on: ${formatDate(expiryDate)}`,
  };
};
